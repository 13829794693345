
import {user} from '@/assets/icons/user';
import {defineComponent} from "vue";

export default defineComponent({
  name: 'UserMenu',

  setup() {
    return {
      user,
      itemsCount: 42,
    };
  },

  computed: {
    sessionUser() {
      return this.$store.getters['session/sessionUser'];
    }
  },

  methods: {
    logout() {
      this.$store.dispatch('session/logout');
      this.$router.push({path: '/login'});
    }
  }
})
