import {ActionContext} from "vuex";
import {State} from "@/store";
import {MerchantStoredModel} from "@/store/models/stored/MerchantStoredModel";
import axios from "@/http-common";
import {AxiosError, AxiosResponse} from "axios";
import {MerchantFormModel} from "@/store/models/forms/MerchantFormModel";
import {MerchantRequestModel} from "@/store/models/request/MerchantRequestModel";
import {TableFilter} from "@/logic/table/TableFilter";
import {MerchantTable} from "@/logic/table/MerchantTable";
import {SelectOption} from "@/logic/select/SelectOption";
import router from "@/router";

export class MerchantState {
    constructor(
        private _merchantList: Array<MerchantStoredModel> = [],
        private _filteredMerchantList: MerchantTable = new MerchantTable([], TableFilter.getFilterObject('merchant')),
        private _merchantListIsLoading: boolean = true
    ) {}

    public get merchantList(): Array<MerchantStoredModel> {
        return this._merchantList;
    }

    public set merchantList(merchantList: Array<MerchantStoredModel>) {
        this._merchantList = merchantList;
    }

    public get filteredMerchantList(): MerchantTable {
        return this._filteredMerchantList;
    }

    public set filteredMerchantList(merchantList: MerchantTable) {
        this._filteredMerchantList = merchantList;
    }

    public get merchantListIsLoading(): boolean {
        return this._merchantListIsLoading;
    }

    public set merchantListIsLoading(loadingStatus: boolean) {
        this._merchantListIsLoading = loadingStatus;
    }
}

type Context = ActionContext<MerchantState, State>;

export default {
    namespaced: true,

    state: new MerchantState(),

    mutations: {
        list(state: MerchantState, merchantList: Array<MerchantStoredModel>): void {
            state.merchantList = merchantList
                .map((merchant) => new MerchantStoredModel(merchant))
                .sort((merchantA, merchantB) => merchantA.id > merchantB.id ? -1 : 1);
        },

        filteredList(state: MerchantState): void {
            state.filteredMerchantList = new MerchantTable(
                state.merchantList,
                TableFilter.getFilterObject('merchant')
            );
        },

        filterOptions(state: MerchantState, filterOptions: TableFilter): void {
            state.filteredMerchantList.filterOptions = new TableFilter(filterOptions);
            TableFilter.setFilterObject('merchant', filterOptions);
        },

        add(state: MerchantState, merchant: MerchantStoredModel): void {
            state.merchantList.push(new MerchantStoredModel(merchant));
        },

        update(state: MerchantState, updateMerchant: MerchantStoredModel): void {
            state.merchantList = state.merchantList
                .map(merchant => merchant.id === updateMerchant.id
                    ? new MerchantStoredModel(updateMerchant)
                    : merchant
                );

            state.filteredMerchantList.dataList = state.merchantList;
        },

        delete(state: MerchantState, deletedId: number): void {
            state.merchantList = state.merchantList.filter(merchant => merchant.id !== deletedId);
            state.filteredMerchantList.dataList = state.merchantList;
        }
    },

    actions: {
        list(context: Context): Promise<void> {
            return axios.get('/api/merchant/list')
                .then((response: AxiosResponse) => {
                    context.commit('list', response.data);
                    context.commit('filteredList', TableFilter.getFilterObject('merchant'));
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}))
                .finally(() => context.state.merchantListIsLoading = false);
        },

        getById(context: Context, id: number): Promise<MerchantStoredModel> {
            return axios.get(`/api/merchant/${id}`)
                .then((response: AxiosResponse) => new MerchantStoredModel(response.data))
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        },

        add(context: Context, merchantForm: MerchantFormModel): Promise<void> {
            return axios.post('/api/merchant/', new MerchantRequestModel(merchantForm))
                .then((response: AxiosResponse) => {
                    context.commit('add', response.data);
                    context.dispatch('alert/success', 'Merchant created', {root: true});
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        },

        update(context: Context, merchantForm: MerchantFormModel): Promise<void> {
            return axios.put('/api/merchant/', new MerchantRequestModel(merchantForm))
                .then((response: AxiosResponse) => {
                    context.commit('update', response.data);
                    context.dispatch('alert/success', 'Merchant updated', {root: true});
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        },

        delete(context: Context, id: number): Promise<void> {
            return axios.delete(`/api/merchant/${id}`)
                .then(() => {
                    context.commit('delete', id);
                    context.dispatch('alert/success', 'Merchant deleted', {root: true});
                    router.push({path: `/merchant/list`});
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        }
    },

    getters: {
        list: (state: MerchantState): Array<MerchantStoredModel> => {
            return state.merchantList.sort((merchantA, merchantB) => merchantA.id > merchantB.id ? 1 : -1);
        },

        byId: (state: MerchantState) => (id: number): MerchantStoredModel | undefined => {
            return state.merchantList.find((merchant: MerchantStoredModel) => id === merchant.id);
        },

        filteredList: (state: MerchantState): MerchantTable => {
            return state.filteredMerchantList;
        },

        merchantListIsLoading: (state: MerchantState): boolean => {
            return state.merchantListIsLoading;
        },

        selectOptionList: (state: MerchantState): Array<SelectOption<number>> => {
            return state.merchantList.map((merchant: MerchantStoredModel) =>
                new SelectOption<number>(new MerchantStoredModel(merchant).getSelectName(), merchant.id)
            )
        }
    },
}
