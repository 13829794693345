import {FinancialInstituteFormModel} from "@/store/models/forms/FinancialInstituteFormModel";

export class FinancialInstituteRequestModel {
    private id: number | undefined;
    private name: string;
    private hostType: string;
    private hostPort: number | undefined;
    private macEnabled: boolean;
    private hostAddress: string;
    private realAddress: string;
    private legalAddress: string;
    private connectionMode: string;
    private keysUpdatingScheme: string;
    private encryptionEnabled: boolean;
    private responseCheckingEnabled: boolean;
    private reconciliationAdviceEnabled: boolean;
    private macBinMode: boolean;
    private keysScheme: number;
    private reversalSendAllEmvTags: boolean;

    constructor(financialInstitute: FinancialInstituteFormModel) {
        this.name = financialInstitute.name;
        this.hostType = financialInstitute.hostType;
        this.hostPort = financialInstitute.hostPort;
        this.macEnabled = financialInstitute.macEnabled;
        this.legalAddress = financialInstitute.legalAddress;
        this.connectionMode = financialInstitute.connectionMode;
        this.encryptionEnabled = financialInstitute.encryptionEnabled;
        this.hostAddress = financialInstitute.hostAddress;
        this.realAddress = financialInstitute.realAddress;
        this.keysUpdatingScheme = financialInstitute.keysUpdatingScheme;
        this.responseCheckingEnabled = financialInstitute.responseCheckingEnabled;
        this.reconciliationAdviceEnabled = financialInstitute.reconciliationAdviceEnabled;
        this.id = financialInstitute.id;
        this.macBinMode = financialInstitute.macBinMode;
        this.keysScheme = financialInstitute.keysScheme;
        this.reversalSendAllEmvTags = financialInstitute.reversalSendAllEmvTags;
    }
}
