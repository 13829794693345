import {
    cibFacebook,
    cibTwitter,
    cibLinkedin,
    cibFlickr,
    cibTumblr,
    cibXing,
    cibGithub,
    cibGoogle,
    cibStackoverflow,
    cibYoutube,
    cibDribbble,
    cibInstagram,
    cibPinterest,
    cibVk,
    cibYahoo,
    cibBehance,
    cibReddit,
    cibVimeo,
    cibCcMastercard,
    cibCcVisa,
    cibCcStripe,
    cibCcPaypal,
    cibCcApplePay,
    cibCcAmex,
    cilSwapVertical,
    cilSearch,
    cilCreditCard,
    cilAudio,
    cilRss,
    cilGraph,
    cilX,
    cilChart,
    cilWarning,
    cilFullscreen,
    cilBank,
    cilAvTimer,
    cilIndustry
} from '@coreui/icons'
import { cifUs, cifBr, cifIn, cifFr, cifEs, cifPl } from '@coreui/icons'
import {
    cilArrowBottom,
    cilArrowRight,
    cilArrowTop,
    cilBan,
    cilBasket,
    cilBell,
    cilCalculator,
    cilCalendar,
    cilCloudDownload,
    cilChartPie,
    cilCheck,
    cilChevronBottom,
    cilChevronTop,
    cilCheckCircle,
    cilCode,
    cilCommentSquare,
    cilCursor,
    cilDrop,
    cilDollar,
    cilEnvelopeClosed,
    cilEnvelopeOpen,
    cilEuro,
    cilGlobeAlt,
    cilGrid,
    cilFile,
    cilJustifyCenter,
    cilLaptop,
    cilLayers,
    cilLightbulb,
    cilList,
    cilLocationPin,
    cilLockLocked,
    cilMagnifyingGlass,
    cilMediaPlay,
    cilMenu,
    cilMoon,
    cilNotes,
    cilOptions,
    cilPencil,
    cilPeople,
    cilPuzzle,
    cilSettings,
    cilShieldAlt,
    cilSpeech,
    cilSpeedometer,
    cilStar,
    cilTask,
    cilUser,
    cilUserFemale,
    cilUserFollow,
    cilXCircle,
    cilRouter,
    cilScreenSmartphone,
    cilSpreadsheet,
    cilPlus,
    cilTablet,
    cilCash,
    cilTrash,
    cilHighlighter,
    cilChartLine,
    cilEqualizer,
    cilInfo,
    cilCommand
} from '@coreui/icons'

export const iconsSet = Object.assign(
    {},
    {
        cilTablet,
        cilCash,
        cilPlus,
        cilSpreadsheet,
        cilScreenSmartphone,
        cilArrowBottom,
        cilArrowRight,
        cilArrowTop,
        cilBan,
        cilBasket,
        cilBell,
        cilCalculator,
        cilCalendar,
        cilCloudDownload,
        cilChartPie,
        cilCheck,
        cilChevronBottom,
        cilChevronTop,
        cilCheckCircle,
        cilCode,
        cilCommentSquare,
        cilCursor,
        cilDrop,
        cilDollar,
        cilEnvelopeClosed,
        cilEnvelopeOpen,
        cilEuro,
        cilGlobeAlt,
        cilGrid,
        cilFile,
        cilJustifyCenter,
        cilLaptop,
        cilLayers,
        cilLightbulb,
        cilList,
        cilLocationPin,
        cilLockLocked,
        cilMagnifyingGlass,
        cilMediaPlay,
        cilMenu,
        cilMoon,
        cilNotes,
        cilOptions,
        cilPencil,
        cilPeople,
        cilPuzzle,
        cilSettings,
        cilShieldAlt,
        cilSpeech,
        cilSpeedometer,
        cilStar,
        cilTask,
        cilUser,
        cilUserFemale,
        cilUserFollow,
        cilXCircle,
        cilRouter,
        cilWarning,
        cilSwapVertical,
        cilSearch,
        cilX,
        cilTrash,
        cilHighlighter,
        cilCreditCard,
        cilAudio,
        cilRss,
        cilGraph,
        cilChartLine,
        cilChart,
        cilFullscreen,
        cilBank,
        cilAvTimer,
        cilIndustry,
        cilEqualizer,
        cilInfo,
        cilCommand
    },
    {
        cifUs,
        cifBr,
        cifIn,
        cifFr,
        cifEs,
        cifPl,
    },
    {
        cibFacebook,
        cibTwitter,
        cibLinkedin,
        cibFlickr,
        cibTumblr,
        cibXing,
        cibGithub,
        cibGoogle,
        cibStackoverflow,
        cibYoutube,
        cibDribbble,
        cibInstagram,
        cibPinterest,
        cibVk,
        cibYahoo,
        cibBehance,
        cibReddit,
        cibVimeo,
        cibCcMastercard,
        cibCcVisa,
        cibCcStripe,
        cibCcPaypal,
        cibCcApplePay,
        cibCcAmex,
    },
)
