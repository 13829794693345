import {ActionContext} from "vuex";
import {State} from "@/store";
import {AidLimitStoredModel} from "@/store/models/stored/AidLimitStoredModel";
import axios from "@/http-common";
import {AxiosError, AxiosResponse} from "axios";
import {AidLimitFormModel} from "@/store/models/forms/AidLimitFormModel";
import {AidLimitRequestModel} from "@/store/models/request/AidLimitRequestModel";
import {TableFilter} from "@/logic/table/TableFilter";
import {AidLimitTable} from "@/logic/table/AidLimitTable";
import router from "@/router";
import {AidKeyStoredModel} from "@/store/models/stored/AidKeyStoredModel";

export class AidLimitState {
    constructor(
        private _aidLimitList: Array<AidLimitStoredModel> = [],
        private _filteredAidLimitList: AidLimitTable = new AidLimitTable([], TableFilter.getFilterObject('aidLimit')),
        private _aidLimitListIsLoading: boolean = true
    ) {
    }

    public get aidLimitList(): Array<AidLimitStoredModel> {
        return this._aidLimitList;
    }

    public set aidLimitList(aidLimitList: Array<AidLimitStoredModel>) {
        this._aidLimitList = aidLimitList;
    }

    public get filteredAidLimitList(): AidLimitTable {
        return this._filteredAidLimitList;
    }

    public set filteredAidLimitList(aidLimitList: AidLimitTable) {
        this._filteredAidLimitList = aidLimitList;
    }

    public get aidLimitListIsLoading(): boolean {
        return this._aidLimitListIsLoading;
    }

    public set aidLimitListIsLoading(loadingStatus: boolean) {
        this._aidLimitListIsLoading = loadingStatus;
    }
}

type Context = ActionContext<AidLimitState, State>;

export default {
    namespaced: true,

    state: new AidLimitState(),

    mutations: {
        list(state: AidLimitState, aidLimitList: Array<AidLimitStoredModel>): void {
            state.aidLimitList = aidLimitList
                .map((aidLimit: AidLimitStoredModel) => new AidLimitStoredModel(aidLimit))
                .sort((aidLimitA, aidLimitB) => aidLimitA.id > aidLimitB.id ? -1 : 1);
        },

        filteredList(state: AidLimitState): void {
            state.filteredAidLimitList = new AidLimitTable(state.aidLimitList, TableFilter.getFilterObject('aidLimit'));
        },

        filterOptions(state: AidLimitState, filterOptions: TableFilter): void {
            state.filteredAidLimitList.filterOptions = new TableFilter(filterOptions);
            TableFilter.setFilterObject('aidLimit', filterOptions);
        },

        add(state: AidLimitState, aidLimit: AidLimitStoredModel): void {
            state.aidLimitList.push(aidLimit);
        },

        update(state: AidLimitState, updateAidLimit: AidLimitStoredModel): void {
            state.aidLimitList = state.aidLimitList
                .map(aidLimit => aidLimit.id === updateAidLimit.id ? updateAidLimit : aidLimit);

            state.filteredAidLimitList.dataList = state.aidLimitList;
        },

        delete(state: AidLimitState, deletedId: number): void {
            state.aidLimitList = state.aidLimitList.filter(aidLimit => aidLimit.id !== deletedId);
            state.filteredAidLimitList.dataList = state.aidLimitList;
        }
    },

    actions: {
        list(context: Context): Promise<void> {
            return axios.get('/api/aid-limit/list')
                .then((response: AxiosResponse) => {
                    context.commit('list', response.data);
                    context.commit('filteredList', TableFilter.getFilterObject('aidLimit'));
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}))
                .finally(() => context.state.aidLimitListIsLoading = false);
        },

        getById(context: Context, id: number): Promise<AidLimitStoredModel> {
            return axios.get(`/api/aid-limit/${id}`)
                .then((response: AxiosResponse) => new AidLimitStoredModel(response.data))
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        },

        add(context: Context, aidLimitForm: AidLimitFormModel): Promise<void> {
            return axios.post('/api/aid-limit/', new AidLimitRequestModel(aidLimitForm))
                .then((response: AxiosResponse) => {
                    context.commit('add', new AidLimitStoredModel(response.data));
                    context.dispatch('alert/success', 'AID limit created', {root: true});
                    router.push('/aid-limit/list');
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        },

        update(context: Context, aidLimitForm: AidLimitFormModel): Promise<void> {
            return axios.put('/api/aid-limit/', new AidLimitRequestModel(aidLimitForm))
                .then((response: AxiosResponse) => {
                    context.commit('update', new AidKeyStoredModel(response.data));
                    context.dispatch('alert/success', 'AID limit updated', {root: true});
                    router.push('/aid-limit/list');
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        },

        delete(context: Context, id: number): Promise<void> {
            return axios.delete(`/api/aid-limit/${id}`)
                .then(() => {
                    context.commit('delete', id);
                    context.dispatch('alert/success', 'AID limit deleted', {root: true});
                    router.push({path: `/aid-limit/list/`});
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        },
    },

    getters: {
        list: (state: AidLimitState): Array<AidLimitStoredModel> => {
            return state.aidLimitList
                .sort((aidLimitA, aidLimitB) => aidLimitA.id > aidLimitB.id ? 1 : -1);
        },

        byId: (state: AidLimitState) => (id: number): AidLimitStoredModel | undefined => {
            return state.aidLimitList.find((aidLimit: AidLimitStoredModel) => id === aidLimit.id);
        },

        filteredList: (state: AidLimitState): AidLimitTable => {
            return state.filteredAidLimitList;
        },

        aidLimitListIsLoading: (state: AidLimitState): boolean => {
            return state.aidLimitListIsLoading;
        }
    },
}
