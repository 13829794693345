import {ForgotPasswordForm} from "@/store/models/forms/ForgotPasswordForm";

export class ForgotPasswordRequestModel {
    
    private login: string;

    constructor(forgotPasswordForm: ForgotPasswordForm) {
        this.login = forgotPasswordForm.login;
    }
}
