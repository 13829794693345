import sessionModule, {SessionState} from "@/store/modules/session";
import sidebarModule, {SidebarState} from "@/store/modules/sidebar";
import settingsModule, {SettingsState} from "@/store/modules/settings";
import userModule, {UserState} from "@/store/modules/user";
import terminalTypeModule, {TerminalTypeState} from "@/store/modules/terminalType";
import terminalModule, {TerminalState} from "@/store/modules/terminal";
import transactionModule, {TransactionState} from "@/store/modules/transaction";
import currencyModule, {CurrencyState} from "@/store/modules/currency";
import alertModule, {AlertState} from "@/store/modules/alert";
import binRangeModule, {BinRangeState} from "@/store/modules/binRange";
import aidModule, {AidState} from "@/store/modules/aid";
import aidKeyModule, {AidKeyState} from "@/store/modules/aidKey";
import configurationLimitModule, {ConfigurationLimitState} from "@/store/modules/configurationLimit";
import configurationRangeModule, {ConfigurationRangeState} from "@/store/modules/configurationRange";
import aidLimitModule, {AidLimitState} from "@/store/modules/aidLimit";
import configurationModule, {ConfigurationState} from "@/store/modules/configuration";
import statisticModule, {StatisticState} from "@/store/modules/statistic";
import financialInstituteModule, {FinancialInstituteState} from "@/store/modules/financialInstitute";
import autoReconciliationGroupModule, {AutoReconciliationGroupState} from "@/store/modules/autoReconciliationGroup";
import merchantModule, {MerchantState} from "@/store/modules/merchant";
import paymentInstrumentModule, {PaymentInstrumentState} from "@/store/modules/paymentInstrument";
import operationModule, {OperationState} from "@/store/modules/operation";
import terminalPermissionModule, {TerminalPermissionState} from "@/store/modules/terminalPermission";
import Vuex from 'vuex';


export interface State {
  session: SessionState;
  sidebar: SidebarState;
  settings: SettingsState;
  user: UserState;
  terminalType: TerminalTypeState;
  terminal: TerminalState;
  transaction: TransactionState;
  currency: CurrencyState;
  alert: AlertState;
  binRange: BinRangeState;
  aid: AidState;
  aidKey: AidKeyState;
  configurationLimit: ConfigurationLimitState;
  configurationRange: ConfigurationRangeState;
  aidLimit: AidLimitState;
  configuration: ConfigurationState;
  statistic: StatisticState;
  financialInstitute: FinancialInstituteState;
  autoReconciliationGroup: AutoReconciliationGroupState;
  merchant: MerchantState;
  paymentInstrument: PaymentInstrumentState;
  operation: OperationState;
  terminalPermission: TerminalPermissionState;
}

export default new Vuex.Store<State>({
  modules: {
    session: sessionModule,
    sidebar: sidebarModule,
    settings: settingsModule,
    user: userModule,
    terminalType: terminalTypeModule,
    terminal: terminalModule,
    transaction: transactionModule,
    alert: alertModule,
    binRange: binRangeModule,
    aid: aidModule,
    aidKey: aidKeyModule,
    currency: currencyModule,
    configurationLimit: configurationLimitModule,
    configurationRange: configurationRangeModule,
    aidLimit: aidLimitModule,
    configuration: configurationModule,
    statistic: statisticModule,
    financialInstitute: financialInstituteModule,
    autoReconciliationGroup: autoReconciliationGroupModule,
    merchant: merchantModule,
    paymentInstrument: paymentInstrumentModule,
    operation: operationModule,
    terminalPermission: terminalPermissionModule
  }
});
