import {ActionContext} from "vuex";
import {State} from "@/store";
import {PaymentInstrumentStoredModel} from "@/store/models/stored/PaymentInstrumentStoredModel";
import axios from "@/http-common";
import {AxiosError, AxiosResponse} from "axios";
import {PaymentInstrumentFormModel} from "@/store/models/forms/PaymentInstrumentFormModel";
import {PaymentInstrumentRequestModel} from "@/store/models/request/PaymentInstrumentRequestModel";
import {PaymentInstrumentTable} from "@/logic/table/PaymentInstrumentTable";
import {TableFilter} from "@/logic/table/TableFilter";
import router from "@/router";

export class PaymentInstrumentState {
    constructor(
        private _paymentInstrumentList: Array<PaymentInstrumentStoredModel> = [],

        private _filteredPaymentInstrumentList: PaymentInstrumentTable =
            new PaymentInstrumentTable([], TableFilter.getFilterObject('paymentInstrument')),

        private _paymentInstrumentListIsLoading: boolean = true
    ) {}

    public get paymentInstrumentList(): Array<PaymentInstrumentStoredModel> {
        return this._paymentInstrumentList;
    }

    public set paymentInstrumentList(paymentInstrumentList: Array<PaymentInstrumentStoredModel>) {
        this._paymentInstrumentList = paymentInstrumentList;
    }

    get filteredPaymentInstrumentList(): PaymentInstrumentTable {
        return this._filteredPaymentInstrumentList;
    }

    set filteredPaymentInstrumentList(paymentInstrumentList: PaymentInstrumentTable) {
        this._filteredPaymentInstrumentList = paymentInstrumentList;
    }

    get paymentInstrumentListIsLoading(): boolean {
        return this._paymentInstrumentListIsLoading;
    }

    set paymentInstrumentListIsLoading(loadingStatus: boolean) {
        this._paymentInstrumentListIsLoading = loadingStatus;
    }
}

type Context = ActionContext<PaymentInstrumentState, State>;

export default {
    namespaced: true,

    state: new PaymentInstrumentState(),

    mutations: {
        list(state: PaymentInstrumentState, paymentInstrumentList: Array<PaymentInstrumentStoredModel>): void {
            state.paymentInstrumentList = paymentInstrumentList
                .map((paymentInstrument: PaymentInstrumentStoredModel) =>
                    new PaymentInstrumentStoredModel(paymentInstrument))
                .sort((paymentInstrumentA, paymentInstrumentB) =>
                    paymentInstrumentA.id > paymentInstrumentB.id ? -1 : 1);
        },

        filteredList(state: PaymentInstrumentState): void {
            state.filteredPaymentInstrumentList = new PaymentInstrumentTable(
                state.paymentInstrumentList,
                TableFilter.getFilterObject('paymentInstrument')
            );
        },

        filterOptions(state: PaymentInstrumentState, filterOptions: TableFilter): void {
            state.filteredPaymentInstrumentList.filterOptions = new TableFilter(filterOptions);
            TableFilter.setFilterObject('paymentInstrument', filterOptions);
        },

        update(state: PaymentInstrumentState, updatePaymentInstrument: PaymentInstrumentStoredModel): void {
            state.paymentInstrumentList = state.paymentInstrumentList
                .map(
                    paymentInstrument => paymentInstrument.id === updatePaymentInstrument.id
                        ? new PaymentInstrumentStoredModel(updatePaymentInstrument)
                        : paymentInstrument
                );

            state.filteredPaymentInstrumentList.dataList = state.paymentInstrumentList;
        }
    },

    actions: {
        list(context: Context): Promise<void> {
            return axios.get('/api/payment-instrument/list')
                .then((response: AxiosResponse) => {
                    context.commit('list', response.data);
                    context.commit('filteredList', TableFilter.getFilterObject('paymentInstrument'));
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}))
                .finally(() => context.state.paymentInstrumentListIsLoading = false);
        },

        getById(context: Context, id: number): Promise<PaymentInstrumentStoredModel> {
            return axios.get(`/api/payment-instrument/${id}`)
                .then((response: AxiosResponse) => new PaymentInstrumentStoredModel(response.data))
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}))
        },

        update(context: Context, paymentInstrumentForm: PaymentInstrumentFormModel): Promise<void> {
            return axios.put('/api/payment-instrument/', new PaymentInstrumentRequestModel(paymentInstrumentForm))
                .then((response: AxiosResponse) => {
                    context.commit('update', new PaymentInstrumentStoredModel(response.data));

                    context.dispatch(
                        'alert/success',
                        `Payment instrument ${paymentInstrumentForm.name} updated`,
                        {root: true}
                    );

                    router.push({path: `/payment-instrument/list`});
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        }
    },

    getters: {
        list: (state: PaymentInstrumentState): Array<PaymentInstrumentStoredModel> => {
            return state.paymentInstrumentList;
        },

        byId: (state: PaymentInstrumentState): (type: number) => PaymentInstrumentStoredModel | undefined => {
            return (id: number) => state.paymentInstrumentList
                .find((paymentInstrument: PaymentInstrumentStoredModel) => id === paymentInstrument.id);
        },

        filteredList: (state: PaymentInstrumentState): PaymentInstrumentTable => {
            return state.filteredPaymentInstrumentList;
        },

        paymentInstrumentListIsLoading: (state: PaymentInstrumentState): boolean => {
            return state.paymentInstrumentListIsLoading;
        }
    },
}
