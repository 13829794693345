import {ActionContext} from "vuex";
import {State} from "@/store";
import axios from "@/http-common";
import {AxiosError, AxiosResponse} from "axios";
import {SettingStoredModel} from "@/store/models/stored/SettingStoredModel";
import {SettingFormModel} from "@/store/models/forms/SettingFormModel";
import {SettingRequestModel} from "@/store/models/request/SettingRequestModel";

export class SettingsState {
    constructor(private _settings: Array<SettingStoredModel> = []) {
    }

    public get settings(): Array<SettingStoredModel> {
        return this._settings;
    }

    public set settings(settings: Array<SettingStoredModel>) {
        this._settings = settings;
    }
}

type Context = ActionContext<SettingsState, State>;

export default {
    namespaced: true,

    state: new SettingsState(),

    mutations: {
        list(state: SettingsState, settings: Array<SettingStoredModel>): void {
            state.settings = settings
                .map((setting) => new SettingStoredModel(setting))
                .sort((settingA, settingB) =>
                    settingA.name.localeCompare(settingB.name) || settingA.id > settingB.id ? 1 : -1);

            localStorage.setItem('settings', JSON.stringify(settings));
        },

        updateSetting(state: SettingsState, updateSetting: SettingStoredModel): void {
            state.settings = state.settings
                .map(setting => setting.name === updateSetting.name ? new SettingStoredModel(updateSetting) : setting);
        }
    },

    actions: {
        list(context: Context, financialInstituteId: number | undefined): Promise<void> {
            return axios.get(`/api/settings/list/${financialInstituteId ? financialInstituteId : ''}`)
                .then((response: AxiosResponse) => context.commit('list', response.data))
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        },

        updateSetting(context: Context, setting: SettingFormModel): Promise<void> {
            return axios.put(
                `/api/settings/${setting.financialInstituteId ? '' : 'general'}`,
                new SettingRequestModel(setting))
                .then((response: AxiosResponse) => {
                    context.commit('updateSetting', response.data);
                    context.dispatch('alert/success', 'Setting updated', {root: true});
                });
        }
    },

    getters: {
        list: (state: SettingsState): Array<SettingStoredModel> => {
            return state.settings;
        },
    },
}
