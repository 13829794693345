
import { defineComponent } from "vue";

export default defineComponent({
  name: 'AppFooter',

  computed: {
    footerTitle() {
      return 'Center of Corporate Technologies';
    }
  }
})
