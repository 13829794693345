import {CurrencyStoredModel} from "@/store/models/stored/CurrencyStoredModel";

export function calculateWithExponent(value: number, exponent: number): number {
    /** js operations with numbers. 2.32 * 10^2 = 231.99999999999997 */
    return Math.round(parseFloat(`${value}`.replace(',', '.')) * 10 ** exponent);
}

export function calculateWithoutExponent(value: number, exponent: number): string | number {
    return value ? (value / 10 ** exponent).toFixed(exponent) : 0;
}

export function currencySymbolShownLeft(currency: CurrencyStoredModel): boolean {
    return !!currency.symbolPosition && currency.symbolPosition === 1;
}

export function formattedAmountWithCurrencySymbol(
    amount: number,
    currency: CurrencyStoredModel,
    ignoreExponent = false): string {

    const amountWithoutExponent = ignoreExponent ? amount : calculateWithoutExponent(amount, currency.fraction);

    return currencySymbolShownLeft(currency)
        ? currency.symbol + amountWithoutExponent
        : amountWithoutExponent + "\u00A0" + currency.symbol;
}



