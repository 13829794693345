import {BaseStoredModel} from "@/store/models/stored/BaseStoredModel";

export class AutoReconciliationGroupStoredModel extends BaseStoredModel {
    private readonly _id: number;
    private readonly _name: string;
    private readonly _time: string;

    constructor(autoReconciliationGroup: AutoReconciliationGroupStoredModel) {
        super(autoReconciliationGroup);

        this._id = autoReconciliationGroup.id;
        this._name = autoReconciliationGroup.name;
        this._time = autoReconciliationGroup.time;
    }

    get id(): number {
        return this._id;
    }

    get name(): string {
        return this._name;
    }

    get time(): string {
        return this._time;
    }

    public getName(): string {
        return `${this.name} (${this.time})`;
    }
}
