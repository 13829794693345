import {TurnoverByPeriod} from "@/store/models/statistic/TurnoverByPeriod";
import {CurrencyStoredModel} from "@/store/models/stored/CurrencyStoredModel";
import {BasePeriodModel} from "@/store/models/statistic/BasePeriodModel";

export class TransactionsTurnoverStatistic extends BasePeriodModel {
    readonly currency: CurrencyStoredModel | null;
    readonly turnoversByPeriod: Array<TurnoverByPeriod>;
    readonly startPeriod = "";
    readonly endPeriod = "";

    constructor(statistic?: TransactionsTurnoverStatistic) {
        super(statistic?.startPeriod ? statistic.startPeriod : "", statistic?.endPeriod ? statistic.endPeriod : "");
        this.currency = statistic?.currency ? statistic.currency : null;
        this.turnoversByPeriod = statistic?.turnoversByPeriod ? statistic.turnoversByPeriod : [];
    }
}
