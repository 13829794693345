export class SidebarState {
    constructor(private _isVisible: boolean = false, private _isUnfoldable: boolean = false) {}

    public set isVisible(status: boolean) {
        this._isVisible = status;
    }

    public get isVisible(): boolean {
        return this._isVisible;
    }

    public set isUnfoldable(status: boolean) {
        this._isUnfoldable = status;
    }

    public get isUnfoldable(): boolean {
        return this._isUnfoldable;
    }
}

export default {
    namespaced: true,

    state: new SidebarState(),

    mutations: {
        toggleSidebar(state: SidebarState): void {
            state.isVisible = !state.isVisible;
        },

        toggleUnfoldable(state: SidebarState): void {
            state.isUnfoldable = !state.isUnfoldable;
        },

        updateSidebarVisible(state: SidebarState, status: boolean): void {
            state.isVisible = status;
        },
    },

    getters: {
        sidebarVisible: (state: SidebarState): boolean => {
            return state.isVisible;
        },

        sidebarUnfoldable: (state: SidebarState): boolean => {
            return state.isUnfoldable;
        }
    }
}
