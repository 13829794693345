import {BaseStoredModel} from "@/store/models/stored/BaseStoredModel";

export class AidLimitStoredModel extends BaseStoredModel {
    private readonly _id: number;
    private readonly _aidId: number;
    private readonly _aidPix: string;
    private readonly _aidRid: string;
    private readonly _aidType: number;
    private readonly _cvmLimit: number;
    private readonly _contactlessFloor: number;
    private readonly _contactlessLimit: number;
    private readonly _currencyEnabled: boolean;
    private readonly _terminalFloorLimit: number;
    private readonly _currencyNumberCode: string;
    private readonly _currencyLetterCode: string;
    private readonly _currencyId: number | undefined;
    private readonly _cardProfileDefault: boolean;
    private readonly _odcvmTransactionLimit: number;


    constructor(aidLimit: AidLimitStoredModel) {
        super(aidLimit);

        this._id = aidLimit.id;
        this._aidId = aidLimit.aidId;
        this._aidPix = aidLimit.aidPix;
        this._aidRid = aidLimit.aidRid;
        this._aidType = aidLimit.aidType;
        this._cvmLimit = aidLimit.cvmLimit;
        this._contactlessFloor = aidLimit.contactlessFloor;
        this._contactlessLimit = aidLimit.contactlessLimit;
        this._currencyEnabled = aidLimit.currencyEnabled;
        this._terminalFloorLimit = aidLimit.terminalFloorLimit;
        this._currencyNumberCode = aidLimit.currencyNumberCode;
        this._currencyLetterCode = aidLimit.currencyLetterCode;
        this._cardProfileDefault = aidLimit.cardProfileDefault;
        this._odcvmTransactionLimit = aidLimit.odcvmTransactionLimit;
        this._currencyId = aidLimit.currencyId;
    }

    get id(): number {
        return this._id;
    }

    get aidId(): number {
        return this._aidId;
    }

    get aidPix(): string {
        return this._aidPix;
    }

    get aidRid(): string {
        return this._aidRid;
    }

    get aidType(): number {
        return this._aidType;
    }

    get cvmLimit(): number {
        return this._cvmLimit;
    }

    get contactlessFloor(): number {
        return this._contactlessFloor;
    }

    get contactlessLimit(): number {
        return this._contactlessLimit;
    }

    get currencyEnabled(): boolean {
        return this._currencyEnabled;
    }

    get terminalFloorLimit(): number {
        return this._terminalFloorLimit;
    }

    get currencyNumberCode(): string {
        return this._currencyNumberCode;
    }

    get currencyId(): number | undefined {
        return this._currencyId;
    }

    get currencyLetterCode(): string {
        return this._currencyLetterCode;
    }

    get cardProfileDefault(): boolean {
        return this._cardProfileDefault;
    }

    get odcvmTransactionLimit(): number {
        return this._odcvmTransactionLimit;
    }
}
