import {TotalTurnover} from "@/store/models/statistic/TotalTurnover";

export class TotalTransactionsStatistic {
    readonly totalTurnover: TotalTurnover | undefined;
    readonly totalTransactions :number;
    readonly totalTerminals : number;

    constructor(statistic?: TotalTransactionsStatistic) {
        this.totalTurnover = statistic?.totalTurnover ? statistic.totalTurnover : undefined;
        this.totalTerminals = statistic?.totalTerminals ? statistic.totalTerminals : 0;
        this.totalTransactions = statistic?.totalTransactions ? statistic.totalTransactions : 0;
    }
}
