import {ActionContext} from "vuex";
import {State} from "@/store";
import {ConfigurationStoredModel} from "@/store/models/stored/ConfigurationStoredModel";
import axios from "@/http-common";
import {AxiosError, AxiosResponse} from "axios";
import {ConfigurationFormModel} from "@/store/models/forms/ConfigurationFormModel";
import {ConfigurationRequestModel} from "@/store/models/request/ConfigurationRequestModel";
import {TableFilter} from "@/logic/table/TableFilter";
import {ConfigurationTable} from "@/logic/table/ConfigurationTable";
import {SelectOption} from "@/logic/select/SelectOption";
import router from "@/router";

export class ConfigurationState {
    constructor(
        private _configurationList: Array<ConfigurationStoredModel> = [],

        private _filteredConfigurationList: ConfigurationTable = new ConfigurationTable(
            [],
            TableFilter.getFilterObject('configuration')
        ),

        private _configurationListIsLoading: boolean = true
    ) {
    }

    public get configurationList(): Array<ConfigurationStoredModel> {
        return this._configurationList;
    }

    public set configurationList(configurationList: Array<ConfigurationStoredModel>) {
        this._configurationList = configurationList;
    }

    public get filteredConfigurationList(): ConfigurationTable {
        return this._filteredConfigurationList;
    }

    public set filteredConfigurationList(configurationList: ConfigurationTable) {
        this._filteredConfigurationList = configurationList;
    }

    public get configurationListIsLoading(): boolean {
        return this._configurationListIsLoading;
    }

    public set configurationListIsLoading(loadingStatus: boolean) {
        this._configurationListIsLoading = loadingStatus;
    }
}

type Context = ActionContext<ConfigurationState, State>;

export default {
    namespaced: true,

    state: new ConfigurationState(),

    mutations: {
        list(state: ConfigurationState, configurationList: Array<ConfigurationStoredModel>): void {
            state.configurationList = configurationList
                .map((configuration: ConfigurationStoredModel) => new ConfigurationStoredModel(configuration))
                .sort((configurationA, configurationB) => configurationA.id > configurationB.id ? -1 : 1);
        },

        filteredList(state: ConfigurationState): void {
            state.filteredConfigurationList = new ConfigurationTable(
                state.configurationList,
                TableFilter.getFilterObject('configuration')
            );
        },

        filterOptions(state: ConfigurationState, filterOptions: TableFilter): void {
            state.filteredConfigurationList.filterOptions = new TableFilter(filterOptions);
            TableFilter.setFilterObject('configuration', filterOptions);
        },

        add(state: ConfigurationState, configuration: ConfigurationStoredModel): void {
            state.configurationList.push(configuration);
        },

        update(state: ConfigurationState, updateConfiguration: ConfigurationStoredModel): void {
            state.configurationList = state.configurationList
                .map(configuration => configuration.id === updateConfiguration.id
                    ? updateConfiguration
                    : configuration
                );

            state.filteredConfigurationList.dataList = state.configurationList;
        },

        delete(state: ConfigurationState, deletedId: number): void {
            state.configurationList = state.configurationList.filter(configuration => configuration.id !== deletedId);
            state.filteredConfigurationList.dataList = state.configurationList;
        }
    },

    actions: {
        list(context: Context): Promise<void> {
            return axios.get('/api/configuration/list')
                .then((response: AxiosResponse) => {
                    context.commit('list', response.data);
                    context.commit('filteredList', TableFilter.getFilterObject('configuration'));
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}))
                .finally(() => context.state.configurationListIsLoading = false);
        },

        getById(context: Context, id: number): Promise<ConfigurationStoredModel> {
            return axios.get(`/api/configuration/${id}`)
                .then((response: AxiosResponse) => new ConfigurationStoredModel(response.data))
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        },

        add(context: Context, configurationForm: ConfigurationFormModel): Promise<void> {
            return axios.post('/api/configuration/', new ConfigurationRequestModel(configurationForm))
                .then((response: AxiosResponse) => {
                    context.commit('add', new ConfigurationStoredModel(response.data));
                    context.dispatch('alert/success', 'Configuration created', {root: true});
					router.push({path: `/configuration/list`});
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        },

        update(context: Context, configurationForm: ConfigurationFormModel): Promise<void> {
            return axios.put('/api/configuration/', new ConfigurationRequestModel(configurationForm))
                .then((response: AxiosResponse) => {
                    context.commit('update', new ConfigurationStoredModel(response.data));
                    context.dispatch('alert/success', 'Configuration updated', {root: true});
					router.push({path: `/configuration/list`});
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        },

        changeConfigurationStatus(context: Context, configuration: ConfigurationFormModel): Promise<void> {
            const status = configuration.status === ConfigurationStoredModel.ACTIVE
                ? ConfigurationStoredModel.BLOCKED
                : ConfigurationStoredModel.ACTIVE;

            return axios.put(`/api/configuration/update-status/${configuration.id}/${status}`)
                .then(() => {
                    configuration.status = status;
                    context.commit('update', configuration);
                    context.dispatch('alert/success', 'Configuration updated', {root: true});
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        },

        delete(context: Context, id: number): Promise<void> {
            return axios.delete(`/api/configuration/${id}`)
                .then(() => {
                    context.commit('delete', id);
                    context.dispatch('alert/success', 'Configuration deleted', {root: true});
                    router.push({path: `/configuration/list/`})
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        }
    },

    getters: {
        list: (state: ConfigurationState): Array<ConfigurationStoredModel> => {
            return state.configurationList
                .sort((configurationA, configurationB) => configurationA.id > configurationB.id ? 1 : -1);
        },

        byId: (state: ConfigurationState) => (id: number): ConfigurationStoredModel | undefined => {
            return state.configurationList.find((configuration: ConfigurationStoredModel) => id === configuration.id);
        },

        filteredList: (state: ConfigurationState): ConfigurationTable => {
            return state.filteredConfigurationList;
        },

        configurationListIsLoading: (state: ConfigurationState): boolean => {
            return state.configurationListIsLoading;
        },

        selectOptionList: (state: ConfigurationState): Array<SelectOption<number>> => {
            return state.configurationList.map((configuration: ConfigurationStoredModel) =>
                new SelectOption<number>(new ConfigurationStoredModel(configuration).getSelectName(), configuration.id)
            )
        }
    },
}
