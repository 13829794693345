import {BaseStoredModel} from "@/store/models/stored/BaseStoredModel";

export class MerchantStoredModel extends BaseStoredModel {
    private readonly _id: number;
    private readonly _mcc: string;
    private readonly _inn: string;
    private readonly _name: string;
    private readonly _externalId: string;
    private readonly _nameAndLocation: string;
    private readonly _address: string;

    constructor(merchant: MerchantStoredModel) {
        super(merchant);

        this._id = merchant.id;
        this._mcc = merchant.mcc;
        this._inn = merchant.inn;
        this._name = merchant.name;
        this._externalId = merchant.externalId;
        this._nameAndLocation = merchant.nameAndLocation;
        this._address = merchant.address;
    }

    get id(): number {
        return this._id;
    }

    get mcc(): string {
        return this._mcc;
    }

    get inn(): string {
        return this._inn;
    }

    get name(): string {
        return this._name;
    }

    get externalId(): string {
        return this._externalId;
    }

    get nameAndLocation(): string {
        return this._nameAndLocation;
    }

    get address(): string {
        return this._address;
    }

    public getName(): string {
        return `${this._name} (${this._externalId})`;
    }
}
