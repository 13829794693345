import {ActionContext} from "vuex";
import {State} from "@/store";
import {AidStoredModel} from "@/store/models/stored/AidStoredModel";
import axios from "@/http-common";
import {AxiosError, AxiosResponse} from "axios";
import {AidFormModel} from "@/store/models/forms/AidFormModel";
import {AidRequestModel} from "@/store/models/request/AidRequestModel";
import {TableFilter} from "@/logic/table/TableFilter";
import {AidTable} from "@/logic/table/AidTable";
import router from "@/router";
import {SelectOption} from "@/logic/select/SelectOption";

export class AidState {
    constructor(
        private _aidList: Array<AidStoredModel> = [],
        private _filteredAidList: AidTable = new AidTable([], TableFilter.getFilterObject('aid')),
        private _aidListIsLoading: boolean = true
    ) {}

    get aidList(): Array<AidStoredModel> {
        return this._aidList;
    }

    set aidList(value: Array<AidStoredModel>) {
        this._aidList = value;
    }

    get filteredAidList(): AidTable {
        return this._filteredAidList;
    }

    set filteredAidList(value: AidTable) {
        this._filteredAidList = value;
    }

    get aidListIsLoading(): boolean {
        return this._aidListIsLoading;
    }

    set aidListIsLoading(value: boolean) {
        this._aidListIsLoading = value;
    }
}

type Context = ActionContext<AidState, State>;

export default {
    namespaced: true,

    state: new AidState(),

    mutations: {
        list(state: AidState, aidList: Array<AidStoredModel> = []): void {
            state.aidList = aidList
                .map((aid: AidStoredModel) => new AidStoredModel(aid))
                .sort((aidA, aidB) => aidA.id > aidB.id ? -1 : 1);
        },

        filteredList(state: AidState): void {
            state.filteredAidList = new AidTable(state.aidList, TableFilter.getFilterObject('aid'));
        },

        filterOptions(state: AidState, filterOptions: TableFilter): void {
            state.filteredAidList.filterOptions = new TableFilter(filterOptions);
            TableFilter.setFilterObject('aid', filterOptions);
        },

        add(state: AidState, aid: AidStoredModel): void {
            state.aidList.push(aid);
        },

        update(state: AidState, updatedAid: AidStoredModel): void {
            state.aidList = state.aidList.map(aid => aid.id === updatedAid.id ? updatedAid : aid);
            state.filteredAidList.dataList = state.aidList;
        },

        delete(state: AidState, deletedId: number): void {
            state.aidList = state.aidList.filter(aid => aid.id !== deletedId);
            state.filteredAidList.dataList = state.aidList;
        }
    },

    actions: {
        list(context: Context): Promise<void> {
            return axios.get('/api/aid/list')
                .then((response: AxiosResponse) => {
                    context.commit('list', response.data);
                    context.commit('filteredList', TableFilter.getFilterObject('aid'));
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}))
                .finally(() => context.state.aidListIsLoading = false);
        },

        getById(context: Context, id: number): Promise<AidStoredModel> {
            return axios.get(`/api/aid/${id}`)
                .then((response: AxiosResponse) => new AidStoredModel(response.data))
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        },

        add(context: Context, aidForm: AidFormModel): Promise<void> {
            return axios.post('/api/aid/', new AidRequestModel(aidForm))
                .then((response: AxiosResponse) => {
                    context.commit('add', new AidStoredModel(response.data));
                    context.dispatch('alert/success', 'AID created', {root: true});
                    router.push({ path: `/aid/list` });
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        },

        update(context: Context, aidForm: AidFormModel): Promise<void> {
            return axios.put('/api/aid/', new AidRequestModel(aidForm))
                .then((response: AxiosResponse) => {
                    context.commit('update', new AidStoredModel(response.data));
                    context.dispatch('alert/success', 'AID updated', {root: true});
                    router.push({ path: `/aid/list` });
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        },

        delete(context: Context, id: number): Promise<void> {
            return axios.delete(`/api/aid/${id}`)
                .then(() => {
                    context.commit('delete', id);
                    context.dispatch('alert/success', 'AID deleted', {root: true});
                    router.push({path: `/aid/list/`});
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        }
    },

    getters: {
        list: (state: AidState): Array<AidStoredModel> => {
            return state.aidList
                .sort((aidA, aidB) => aidA.id > aidB.id ? 1 : -1);
        },

        byId: (state: AidState) => (id: number): AidStoredModel | undefined => {
            return state.aidList.find((aid: AidStoredModel) => id === aid.id);
        },

        filteredList: (state: AidState): AidTable => {
            return state.filteredAidList;
        },

        aidListIsLoading: (state: AidState): boolean => {
            return state.aidListIsLoading;
        },

        selectOptionList: (state: AidState): Array<SelectOption<number>> => {
            return state.aidList
                .map((aid: AidStoredModel) =>
                    new SelectOption<number>(new AidStoredModel(aid).getSelectName(), aid.id))
        },

        selectedAidList: (state: AidState) => (selectedList: Array<SelectOption<number>>) => {
            return state.aidList
                .filter((aid: AidStoredModel) =>
                    selectedList.some((option: SelectOption<number>) => aid.id === option.value))
        }
    },
}
