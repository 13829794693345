import {ActionContext} from "vuex";
import {State} from "@/store";
import {AutoReconciliationGroupStoredModel} from "@/store/models/stored/AutoReconciliationGroupStoredModel";
import axios from "@/http-common";
import {AxiosError, AxiosResponse} from "axios";
import {AutoReconciliationGroupFormModel} from "@/store/models/forms/AutoReconciliationGroupFormModel";
import {AutoReconciliationGroupRequestModel} from "@/store/models/request/AutoReconciliationGroupRequestModel";
import {TableFilter} from "@/logic/table/TableFilter";
import {AutoReconciliationGroupTable} from "@/logic/table/AutoReconciliationGroupTable";
import {SelectOption} from "@/logic/select/SelectOption";
import router from "@/router";

export class AutoReconciliationGroupState {
    constructor(
        private _autoReconciliationGroupList: Array<AutoReconciliationGroupStoredModel> = [],

        private _filteredAutoReconciliationGroupList: AutoReconciliationGroupTable = new AutoReconciliationGroupTable(
            [],
            TableFilter.getFilterObject('autoReconciliationGroup')
        ),

        private _autoReconciliationGroupListIsLoading: boolean = true
    ) {
    }

    public get autoReconciliationGroupList(): Array<AutoReconciliationGroupStoredModel> {
        return this._autoReconciliationGroupList;
    }

    public set autoReconciliationGroupList(autoReconciliationGroupList: Array<AutoReconciliationGroupStoredModel>) {
        this._autoReconciliationGroupList = autoReconciliationGroupList;
    }

    public get filteredAutoReconciliationGroupList(): AutoReconciliationGroupTable {
        return this._filteredAutoReconciliationGroupList;
    }

    public set filteredAutoReconciliationGroupList(autoReconciliationGroupList: AutoReconciliationGroupTable) {
        this._filteredAutoReconciliationGroupList = autoReconciliationGroupList;
    }

    public get autoReconciliationGroupListIsLoading(): boolean {
        return this._autoReconciliationGroupListIsLoading;
    }

    public set autoReconciliationGroupListIsLoading(loadingStatus: boolean) {
        this._autoReconciliationGroupListIsLoading = loadingStatus;
    }
}

type Context = ActionContext<AutoReconciliationGroupState, State>;

export default {
    namespaced: true,

    state: new AutoReconciliationGroupState(),

    mutations: {
        list(
            state: AutoReconciliationGroupState,
            autoReconciliationGroupList: Array<AutoReconciliationGroupStoredModel>
        ): void {

            state.autoReconciliationGroupList = autoReconciliationGroupList
                .map(autoReconciliationGroup => new AutoReconciliationGroupStoredModel(autoReconciliationGroup))
                .sort((autoReconciliationGroupA, autoReconciliationGroupB) =>
                    autoReconciliationGroupA.id > autoReconciliationGroupB.id ? -1 : 1);
        },

        filteredList(state: AutoReconciliationGroupState): void {
            state.filteredAutoReconciliationGroupList = new AutoReconciliationGroupTable(
                state.autoReconciliationGroupList,
                TableFilter.getFilterObject('autoReconciliationGroup')
            );
        },

        filterOptions(state: AutoReconciliationGroupState, filterOptions: TableFilter): void {
            state.filteredAutoReconciliationGroupList.filterOptions = new TableFilter(filterOptions);
            TableFilter.setFilterObject('autoReconciliationGroup', filterOptions);
        },

        add(state: AutoReconciliationGroupState, autoReconciliationGroup: AutoReconciliationGroupStoredModel): void {
            state.autoReconciliationGroupList.push(new AutoReconciliationGroupStoredModel(autoReconciliationGroup));
        },

        update(
            state: AutoReconciliationGroupState,
            updateAutoReconciliationGroup: AutoReconciliationGroupStoredModel
        ): void {

            state.autoReconciliationGroupList = state.autoReconciliationGroupList
                .map(autoReconciliationGroup => autoReconciliationGroup.id === updateAutoReconciliationGroup.id
                    ? new AutoReconciliationGroupStoredModel(updateAutoReconciliationGroup)
                    : autoReconciliationGroup);

            state.filteredAutoReconciliationGroupList.dataList = state.autoReconciliationGroupList;
        },

        delete(state: AutoReconciliationGroupState, deletedId: number): void {
            state.autoReconciliationGroupList = state.autoReconciliationGroupList
                .filter(autoReconciliationGroup => autoReconciliationGroup.id !== deletedId);

            state.filteredAutoReconciliationGroupList.dataList = state.autoReconciliationGroupList;
        }
    },

    actions: {
        list(context: Context): Promise<void> {
            return axios.get('/api/auto-reconciliation-group/list')
                .then((response: AxiosResponse) => {
                    context.commit('list', response.data);
                    context.commit('filteredList', TableFilter.getFilterObject('autoReconciliationGroup'));
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}))
                .finally(() => context.state.autoReconciliationGroupListIsLoading = false);
        },

        getById(context: Context, id: number): Promise<AutoReconciliationGroupStoredModel> {
            return axios.get(`/api/auto-reconciliation-group/${id}`)
                .then((response: AxiosResponse) => new AutoReconciliationGroupStoredModel(response.data))
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        },

        add(context: Context, autoReconciliationGroupForm: AutoReconciliationGroupFormModel): Promise<void> {
            return axios.post(
                '/api/auto-reconciliation-group/',
                new AutoReconciliationGroupRequestModel(autoReconciliationGroupForm)
            )
                .then((response: AxiosResponse) => {
                    context.commit('add', response.data);
                    context.dispatch('alert/success', 'Auto reconciliation group created', {root: true});
                    router.push('/auto-reconciliation-group/list');
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        },

        update(context: Context, autoReconciliationGroupForm: AutoReconciliationGroupFormModel): Promise<void> {
            return axios.put(
                '/api/auto-reconciliation-group/',
                new AutoReconciliationGroupRequestModel(autoReconciliationGroupForm)
            )
                .then((response: AxiosResponse) => {
                    context.commit('update', response.data);
                    context.dispatch('alert/success', 'Auto reconciliation group updated', {root: true});
                    router.push('/auto-reconciliation-group/list');
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        },

        delete(context: Context, id: number): Promise<void> {
            return axios.delete(`/api/auto-reconciliation-group/${id}`)
                .then(() => {
                    context.commit('delete', id);
                    context.dispatch('alert/success', 'Auto reconciliation group deleted', {root: true});
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        }
    },

    getters: {
        list: (state: AutoReconciliationGroupState): Array<AutoReconciliationGroupStoredModel> => {
            return state.autoReconciliationGroupList
                .sort((autoReconciliationGroupA, autoReconciliationGroupB) =>
                    autoReconciliationGroupA.id > autoReconciliationGroupB.id ? 1 : -1);
        },

        byId: (state: AutoReconciliationGroupState) => (id: number): AutoReconciliationGroupStoredModel | undefined => {
            return state.autoReconciliationGroupList
                .find((autoReconciliationGroup: AutoReconciliationGroupStoredModel) =>
                    id === autoReconciliationGroup.id);
        },

        filteredList: (state: AutoReconciliationGroupState): AutoReconciliationGroupTable => {
            return state.filteredAutoReconciliationGroupList;
        },

        autoReconciliationGroupListIsLoading: (state: AutoReconciliationGroupState): boolean => {
            return state.autoReconciliationGroupListIsLoading;
        },

        selectOptionList: (state: AutoReconciliationGroupState): Array<SelectOption<number | null>> => {
            const optionList = [new SelectOption<number | null>('Global group', null)];

            state.autoReconciliationGroupList.forEach((autoReconciliationGroup: AutoReconciliationGroupStoredModel) =>
                optionList.push(
                    new SelectOption<number>(
                        new AutoReconciliationGroupStoredModel(autoReconciliationGroup).getSelectName(),
                        autoReconciliationGroup.id
                    )
                )
            );

            return optionList;
        }
    },
}
