import {Table} from "@/logic/table/Table";
import {ConfigurationStoredModel} from "@/store/models/stored/ConfigurationStoredModel";
import {SearchConditionMultiple} from "filter-data/lib/lib/types";
import {HeaderCell} from "@/logic/table/HeaderCell";
import {SearchCondition} from "@/logic/table/SearchCondition";
import {SearchType} from "filter-data";
import {TableFilter} from "@/logic/table/TableFilter";
import {Utils} from "@/logic/Utils";

export class ConfigurationTable extends Table<ConfigurationStoredModel> {
    filterConfigurationList(): Array<SearchConditionMultiple> {
        return [
            new SearchCondition(
                [
                    'id',
                    'name',
                    'status',
                    'tc',
                    'countryCode',
                    'tcForRefund',
                    'currencyNumberCode',
                    'financialInstitute'
                ],
                SearchType.LK,
                this.filterOptions.searchValue
            )
        ];
    }

    get headers(): Array<HeaderCell> {
        return HeaderCell.changeSortTypeInList(
            TableFilter.getFilterObject('configuration'),
            [
                new HeaderCell('Id', 'id', HeaderCell.DESC),
                new HeaderCell('Name', 'name'),
                new HeaderCell('Status', 'status'),
                new HeaderCell('TC All', 'tc', HeaderCell.SORTABLE, true),
                new HeaderCell('TC Refund', 'tcForRefund', HeaderCell.SORTABLE, true),
                new HeaderCell('Country code', 'countryCode'),
                new HeaderCell('Currency', 'currencyNumberCode', HeaderCell.SORTABLE, true),
                new HeaderCell('Default', 'defaultConfiguration'),
                new HeaderCell(
                    'Financial institute',
                    'financialInstitute',
                    HeaderCell.SORTABLE,
                    false,
                    true,
                    '200px',
                    () => Utils.isSuperAdmin()
                ),
                new HeaderCell('', 'actions', HeaderCell.UNSORTABLE),
            ]
        );
    }
}
