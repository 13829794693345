export class TmpDictionary {
    public static filedName = {
        'statistic.address': 'Address',
        'statistic.transactions.batch_size': 'Transactions batch size',
        'statistic.transactions.checking_delay': 'Transaction checking delay',
        'monitoring.smartpos.url': 'Monitoring address',
        'mail.smtp.tls': 'Enable TLS / StartTLS',
        'mail.smtp.host': 'smtp host',
        'mail.smtp.user': 'Mail smtp user',
        'mail.smtp.from': 'Mail smtp from',
        'mail.smtp.password': 'smtp password',
        'mail.enableShielding': 'Enable shielding',
        'mail.smtp.protocol': 'Mail smtp protocol',
        'mail.smtp.limit.numberRepeats': 'The number of retries to send letters before blocking',
        'mail.smtp.limit.repeatsPeriod': 'The minimum retry period for sending emails (in seconds)',
        'mail.smtp.limit.banUserPeriod': 'Period of temporary blocking of sending letters (in seconds)',
        'template.email.address': 'Web address',
    }

    public static accordionName = {
        'statistic.group': 'Statistic',
        'monitoring.group': 'Monitoring',
        'template.group': 'Template',
        'mail.group': 'Mail',
    }

    public static paymentSystemName = {
        'Host': 'Card'
    }

    public static operationName = {
        'CashAdvance': 'Cash-Advance',
        'PreAuthorizationRegistration': 'Pre-Auth',
        'PreAuthorizationIncrement': 'Pre-Auth Increment',
        'PreAuthorizationCompletion': 'Pre-Auth Complete'
    }
}

export class SettingTypes {
    public static POSITIVE_LONG = 'POSITIVE_LONG';
    public static DATE = 'DATE';
    public static URL = 'URL';
    public static SECURE_STRING = 'SECURE_STRING';
    public static BOOLEAN = 'BOOLEAN'
}
