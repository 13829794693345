import {ChangePasswordForm} from "@/store/models/forms/ChangePasswordForm";

export class ChangePasswordRequestModel {
    
    private newPassword: string;

    constructor(changePasswordForm: ChangePasswordForm) {
        this.newPassword = changePasswordForm.newPassword;
    }
}
