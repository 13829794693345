import {Table} from "@/logic/table/Table";
import TerminalTypeStoredModel from "@/store/models/stored/TerminalTypeStoredModel";
import {HeaderCell} from "@/logic/table/HeaderCell";
import {SearchConditionMultiple} from "filter-data/lib/lib/types";
import {SearchCondition} from "@/logic/table/SearchCondition";
import {SearchType} from "filter-data";
import {TableFilter} from "@/logic/table/TableFilter";
import {Utils} from "@/logic/Utils";

export class TerminalTypeTable extends Table<TerminalTypeStoredModel> {
    get headers(): Array<HeaderCell> {
        return HeaderCell.changeSortTypeInList(
            TableFilter.getFilterObject('terminalType'),
            [
                new HeaderCell('Id', 'id', HeaderCell.DESC),
                new HeaderCell('Type', 'type'),
                new HeaderCell('Name', 'name'),
                new HeaderCell('Status', 'enabled'),
                new HeaderCell(
                    'Financial institute',
                    'financialInstitute',
                    HeaderCell.SORTABLE,
                    false,
                    true,
                    '200px',
                    () => Utils.isSuperAdmin()
                ),
                new HeaderCell('', 'actions', HeaderCell.UNSORTABLE)
            ]
        );
    }

    filterConfigurationList(): Array<SearchConditionMultiple> {
        return [
            new SearchCondition(
                ['id', 'type', 'name', 'enabled', 'financialInstitute'],
                SearchType.LK,
                this.filterOptions.searchValue
            )
        ];
    }
}
