import {ActionContext} from "vuex";
import {State} from "@/store";
import {ConfigurationRangeStoredModel} from "@/store/models/stored/ConfigurationRangeStoredModel";
import axios from "@/http-common";
import {AxiosError, AxiosResponse} from "axios";
import {ConfigurationRangeFormModel} from "@/store/models/forms/ConfigurationRangeFormModel";
import {ConfigurationRangeRequestModel} from "@/store/models/request/ConfigurationRangeRequestModel";
import {TableFilter} from "@/logic/table/TableFilter";
import {ConfigurationRangeTable} from "@/logic/table/ConfigurationRangeTable";
import router from "@/router";
import {SelectOption} from "@/logic/select/SelectOption";

export class ConfigurationRangeState {
    constructor(
        private _configurationRangeList: Array<ConfigurationRangeStoredModel> = [],

        private _filteredConfigurationRangeList: ConfigurationRangeTable = new ConfigurationRangeTable(
            [],
            TableFilter.getFilterObject('configurationRange')
        ),

        private _configurationRangeListIsLoading: boolean = true
    ) {
    }

    public get configurationRangeList(): Array<ConfigurationRangeStoredModel> {
        return this._configurationRangeList;
    }

    public set configurationRangeList(configurationRangeList: Array<ConfigurationRangeStoredModel>) {
        this._configurationRangeList = configurationRangeList;
    }

    public get filteredConfigurationRangeList(): ConfigurationRangeTable {
        return this._filteredConfigurationRangeList;
    }

    public set filteredConfigurationRangeList(configurationRangeList: ConfigurationRangeTable) {
        this._filteredConfigurationRangeList = configurationRangeList;
    }

    public get configurationRangeListIsLoading(): boolean {
        return this._configurationRangeListIsLoading;
    }

    public set configurationRangeListIsLoading(loadingStatus: boolean) {
        this._configurationRangeListIsLoading = loadingStatus;
    }
}

type Context = ActionContext<ConfigurationRangeState, State>;

export default {
    namespaced: true,

    state: new ConfigurationRangeState(),

    mutations: {
        list(state: ConfigurationRangeState, configurationRangeList: Array<ConfigurationRangeStoredModel>): void {
            state.configurationRangeList = configurationRangeList
                .map((configurationRange: ConfigurationRangeStoredModel) =>
                    new ConfigurationRangeStoredModel(configurationRange))
                .sort((configurationRangeA, configurationRangeB) =>
                    configurationRangeA.id > configurationRangeB.id ? -1 : 1);
        },

        filteredList(state: ConfigurationRangeState): void {
            state.filteredConfigurationRangeList = new ConfigurationRangeTable(
                state.configurationRangeList,
                TableFilter.getFilterObject('configurationRange')
            );
        },

        filterOptions(state: ConfigurationRangeState, filterOptions: TableFilter): void {
            state.filteredConfigurationRangeList.filterOptions = new TableFilter(filterOptions);
            TableFilter.setFilterObject('configurationRange', filterOptions);
        },

        add(state: ConfigurationRangeState, configurationRange: ConfigurationRangeStoredModel): void {
            state.configurationRangeList.push(configurationRange);
        },

        update(state: ConfigurationRangeState, updateConfigurationRange: ConfigurationRangeStoredModel): void {
            state.configurationRangeList = state.configurationRangeList
                .map(configurationRange => configurationRange.id === updateConfigurationRange.id
                    ? updateConfigurationRange
                    : configurationRange
                );

            state.filteredConfigurationRangeList.dataList = state.configurationRangeList;
        },

        delete(state: ConfigurationRangeState, deletedId: number): void {
            state.configurationRangeList = state.configurationRangeList
                .filter(configurationRange => configurationRange.id !== deletedId);

            state.filteredConfigurationRangeList.dataList = state.configurationRangeList;
        }
    },

    actions: {
        list(context: Context): Promise<void> {
            return axios.get('/api/configuration-range/list')
                .then((response: AxiosResponse) => {
                    context.commit('list', response.data);
                    context.commit('filteredList', TableFilter.getFilterObject('configurationRange'));
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}))
                .finally(() => context.state.configurationRangeListIsLoading = false);
        },

        getById(context: Context, id: number): Promise<ConfigurationRangeStoredModel> {
            return axios.get(`/api/configuration-range/${id}`)
                .then((response: AxiosResponse) => new ConfigurationRangeStoredModel(response.data))
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        },

        add(context: Context, configurationRangeForm: ConfigurationRangeFormModel): Promise<void> {
            return axios.post('/api/configuration-range/', new ConfigurationRangeRequestModel(configurationRangeForm))
                .then((response: AxiosResponse) => {
                    context.commit('add', new ConfigurationRangeStoredModel(response.data));
                    context.dispatch('alert/success', 'Card range created', {root: true});
                    router.push({ path: `/card-range/list` });
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        },

        update(context: Context, configurationRangeForm: ConfigurationRangeFormModel): Promise<void> {
            return axios.put('/api/configuration-range/', new ConfigurationRangeRequestModel(configurationRangeForm))
                .then((response: AxiosResponse) => {
                    context.commit('update', new ConfigurationRangeStoredModel(response.data));
                    context.dispatch('alert/success', 'Card range updated', {root: true});
                    router.push({ path: `/card-range/list` });
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        },

        delete(context: Context, id: number): Promise<void> {
            return axios.delete(`/api/configuration-range/${id}`)
                .then(() => {
                    context.commit('delete', id);
                    context.dispatch('alert/success', 'Card range deleted', {root: true});
                    router.push('/card-range/list');
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        }
    },

    getters: {
        list: (state: ConfigurationRangeState): Array<ConfigurationRangeStoredModel> => {
            return state.configurationRangeList
                .sort(
                    (configurationRangeA, configurationRangeB) =>
                        configurationRangeA.id > configurationRangeB.id ? 1 : -1
                );
        },

        byId: (state: ConfigurationRangeState) => (id: number): ConfigurationRangeStoredModel | undefined => {
            return state.configurationRangeList
                .find((configurationRange: ConfigurationRangeStoredModel) => id === configurationRange.id);
        },

        filteredList: (state: ConfigurationRangeState): ConfigurationRangeTable => {
            return state.filteredConfigurationRangeList;
        },

        configurationRangeListIsLoading: (state: ConfigurationRangeState): boolean => {
            return state.configurationRangeListIsLoading;
        },

        selectOptionList: (state: ConfigurationRangeState): Array<SelectOption<number>> => {
            return state.configurationRangeList.map((configurationRange: ConfigurationRangeStoredModel) =>
                new SelectOption<number>(
                    new ConfigurationRangeStoredModel(configurationRange).getSelectName(),
                    configurationRange.id
                )
            )
        }
    },
}
