import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "alertBlock" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CIcon = _resolveComponent("CIcon")!
  const _component_CAlert = _resolveComponent("CAlert")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.alertList, (alert, alertIndex) => {
      return (_openBlock(), _createElementBlock("div", { key: alertIndex }, [
        (_openBlock(), _createBlock(_component_CAlert, {
          color: alert.type,
          key: alert.id,
          class: "d-flex align-items-center",
          id: `alert${alert.id}`,
          testId: `alert__${alert.type}`
        }, {
          default: _withCtx(() => [
            (alert.type === _ctx.Alert.SUCCESS)
              ? (_openBlock(), _createBlock(_component_CIcon, {
                  key: 0,
                  icon: "cil-check-circle",
                  class: "flex-shrink-0 me-2",
                  width: "24",
                  height: "24"
                }))
              : _createCommentVNode("", true),
            (alert.type === _ctx.Alert.ERROR)
              ? (_openBlock(), _createBlock(_component_CIcon, {
                  key: 1,
                  icon: "cil-warning",
                  class: "flex-shrink-0 me-2",
                  width: "24",
                  height: "24"
                }))
              : _createCommentVNode("", true),
            _createElementVNode("div", null, _toDisplayString(alert.message), 1)
          ]),
          _: 2
        }, 1032, ["color", "id", "testId"]))
      ]))
    }), 128))
  ]))
}