import {HeaderCell} from "@/logic/table/HeaderCell";
import {SearchType} from "filter-data";
import UserStoredModel from "@/store/models/stored/UserStoredModel";
import {Table} from "@/logic/table/Table";
import {SearchConditionMultiple} from "filter-data/lib/lib/types";
import {SearchCondition} from "@/logic/table/SearchCondition";
import {TableFilter} from "@/logic/table/TableFilter";
import {Utils} from "@/logic/Utils";

export class UserTable extends Table<UserStoredModel> {
    get headers(): Array<HeaderCell> {
        return HeaderCell.changeSortTypeInList(
            TableFilter.getFilterObject('user'),
            [
                new HeaderCell('Id', 'id', HeaderCell.DESC),
                new HeaderCell('Login', 'login'),
                new HeaderCell('Email', 'email'),
                new HeaderCell('Phone', 'phone'),
                new HeaderCell('Full name', 'fullName'),
                new HeaderCell('Status', 'status'),
                new HeaderCell(
                    'Financial institute',
                    'financialInstitute',
                    HeaderCell.SORTABLE,
                    false,
                    true,
                    '200px',
                    () => Utils.isSuperAdmin()
                ),
                new HeaderCell('', 'actions', HeaderCell.UNSORTABLE)
            ]
        );
    }

    filterConfigurationList(): Array<SearchConditionMultiple> {
        return [
            new SearchCondition(
                ['id', 'fullName', 'login', 'phone', 'email', 'financialInstitute'],
                SearchType.LK,
                this.filterOptions.searchValue
            )
        ];
    }
}
