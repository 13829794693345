import {BaseFormModel} from "@/store/models/forms/BaseFormModel";

export class BaseRequestModel {
    private financialInstitute: string | undefined;
    private financialInstituteId: number | undefined;

    constructor(object: BaseFormModel) {
        this.financialInstitute = object.financialInstitute;
        this.financialInstituteId = object.financialInstituteId;
    }
}
