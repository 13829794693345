import {Utils} from "@/logic/Utils";

interface IBadge {
    color: string;
    text: string;
    shape: string;
}

export class SidebarItem {
  constructor(
    private _name: string = "",
    private _to: string = "/",
    private _icon: string = "",
    private _component: string = "CNavItem",
    private _items?: Array<SidebarItem>,
    private _badge?: IBadge,
    public permissionCondition?: () => boolean
  ) {}

    public get name(): string {
        return this._name;
    }

    public get to(): string {
        return this._to;
    }

    public get icon(): string {
        return this._icon;
    }

    public get component(): string {
        return this._component;
    }

    public get badge(): IBadge | undefined {
        return this._badge;
    }

    public get items(): Array<SidebarItem> | undefined {
        return this._items;
    }
}

export default [
    new SidebarItem('Dashboard', '/dashboard', 'cil-spreadsheet'),
    new SidebarItem('Terminals', '/terminal/list', 'cil-router'),
    new SidebarItem('Terminal types', '/terminal-type/list', 'cil-tablet'),
    new SidebarItem('Transactions', '/transaction/list', 'cil-Cash'),
    new SidebarItem(
        'EMV',
        '/aid',
        'cil-credit-card',
        'CNavGroup',
        [
            new SidebarItem('AID\'s', '/aid/list', 'cil-credit-card'),
            new SidebarItem('Keys for AID\'s', '/aid-key/list', 'cil-graph'),
            new SidebarItem('AID limits', '/aid-limit/list', 'cil-chart'),
            new SidebarItem('Limits', '/limit/list', 'cil-chart-line'),
            new SidebarItem('Configurations', '/configuration/list', 'cil-settings'),
        ]
    ),
    new SidebarItem(
        'Financial',
        '/financial',
        'cil-bank',
        'CNavGroup',
        [
            new SidebarItem(
                'Financial institutes',
                '/financial-institute/list',
                'cil-bank',
                'CNavItem',
                undefined,
                undefined,
                () => Utils.isSuperAdmin()
            ),
            new SidebarItem('Currencies', '/currency/list', 'cil-dollar'),
            new SidebarItem('Card ranges', '/card-range/list', 'cil-chart-pie'),
            new SidebarItem('Bin ranges', '/bin-range/list', 'cil-fullscreen'),
            new SidebarItem('Auto reconciliation groups', '/auto-reconciliation-group/list', 'cil-av-timer'),
            new SidebarItem('Payment instruments', '/payment-instrument/list', 'cil-credit-card'),
            new SidebarItem('Operations', '/operation/list', 'cil-command'),
        ]
    ),
    new SidebarItem("Users", "/user/list", "cil-people"),
    new SidebarItem("Merchants", "/merchant/list", "cil-industry"),
    new SidebarItem('Settings', '/settings/', 'cil-settings'),
]
