import {Table} from "@/logic/table/Table";
import {AidLimitStoredModel} from "@/store/models/stored/AidLimitStoredModel";
import {HeaderCell} from "@/logic/table/HeaderCell";
import {SearchConditionMultiple} from "filter-data/lib/lib/types";
import {SearchCondition} from "@/logic/table/SearchCondition";
import {SearchType} from "filter-data";
import {TableFilter} from "@/logic/table/TableFilter";
import {Utils} from "@/logic/Utils";

export class AidLimitTable extends Table<AidLimitStoredModel> {
    filterConfigurationList(): Array<SearchConditionMultiple> {
        return [
            new SearchCondition(
                [
                    'id',
                    'aidId',
                    'currencyNumberCode',
                    'contactlessLimit',
                    'cvmLimit',
                    'contactlessFloor',
                    'terminalFloorLimit',
                    'odcvmTransactionLimit',
                    'financialInstitute'
                ],
                SearchType.LK,
                this.filterOptions.searchValue
            )
        ];
    }

    get headers(): Array<HeaderCell> {
        return HeaderCell.changeSortTypeInList(
            TableFilter.getFilterObject('aidLimit'),
            [
                new HeaderCell('Id', 'id', HeaderCell.DESC),
                new HeaderCell('AID', 'aidId'),
                new HeaderCell('Currency', 'currencyNumberCode'),
                new HeaderCell('Contactless transaction', 'contactlessLimit', HeaderCell.SORTABLE, true),
                new HeaderCell('CVM required', 'cvmLimit', HeaderCell.SORTABLE, true),
                new HeaderCell('Сontactless floor', 'contactlessFloor', HeaderCell.SORTABLE, true),
                new HeaderCell('Terminal floor', 'terminalFloorLimit', HeaderCell.SORTABLE, true, false),
                new HeaderCell('ODCVM transaction', 'odcvmTransactionLimit', HeaderCell.SORTABLE, true, false),
                new HeaderCell(
                    'Financial institute',
                    'financialInstitute',
                    HeaderCell.SORTABLE,
                    false,
                    true,
                    '200px',
                    () => Utils.isSuperAdmin()
                ),
                new HeaderCell('', 'actions', HeaderCell.UNSORTABLE),
            ]
        );
    }

}
