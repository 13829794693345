import {Table} from "@/logic/table/Table";
import {CurrencyStoredModel} from "@/store/models/stored/CurrencyStoredModel";
import {SearchConditionMultiple} from "filter-data/lib/lib/types";
import {HeaderCell} from "@/logic/table/HeaderCell";
import {SearchCondition} from "@/logic/table/SearchCondition";
import {SearchType} from "filter-data";
import {TableFilter} from "@/logic/table/TableFilter";
import {Utils} from "@/logic/Utils";

export class CurrencyTable extends Table<CurrencyStoredModel> {
    filterConfigurationList(): Array<SearchConditionMultiple> {
        return [
            new SearchCondition(
                [
                    'id',
                    'numberCode',
                    'letterCode',
                    'unitName',
                    'symbol',
                    'enabled',
                    'financialInstitute'
                ],
                SearchType.LK,
                this.filterOptions.searchValue
            )
        ];
    }

    get headers(): Array<HeaderCell> {
        return HeaderCell.changeSortTypeInList(
            TableFilter.getFilterObject('currency'),
            [
                new HeaderCell('Id', 'id', HeaderCell.DESC),
                new HeaderCell('Number code', 'numberCode'),
                new HeaderCell('Letter code', 'letterCode'),
                new HeaderCell('Unit name', 'unitName'),
                new HeaderCell('Symbol', 'symbol'),
                new HeaderCell('Default', 'defaultCurrency'),
                new HeaderCell('Status', 'enabled'),
                new HeaderCell(
                    'Financial institute',
                    'financialInstitute',
                    HeaderCell.SORTABLE,
                    false,
                    true,
                    '200px',
                    () => Utils.isSuperAdmin()
                ),
                new HeaderCell('', 'actions', HeaderCell.UNSORTABLE)
            ]
        );
    }

}
