import {BaseStoredModel} from "@/store/models/stored/BaseStoredModel";

export class ConfigurationRangeStoredModel extends BaseStoredModel {
    private readonly _id: number;
    private readonly _end: string;
    private readonly _start: string;

    constructor(configurationRange: ConfigurationRangeStoredModel) {
        super(configurationRange);

        this._id = configurationRange.id;
        this._end = configurationRange.end;
        this._start = configurationRange.start;
    }

    get id(): number {
        return this._id;
    }

    get end(): string {
        return this._end;
    }

    get start(): string {
        return this._start;
    }

    public getName(): string {
        return `${this._start} - ${this._end}`;
    }
}
