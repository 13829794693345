import {Table} from "@/logic/table/Table";
import {ConfigurationRangeStoredModel} from "@/store/models/stored/ConfigurationRangeStoredModel";
import {SearchConditionMultiple} from "filter-data/lib/lib/types";
import {HeaderCell} from "@/logic/table/HeaderCell";
import {SearchCondition} from "@/logic/table/SearchCondition";
import {SearchType} from "filter-data";
import {TableFilter} from "@/logic/table/TableFilter";
import {Utils} from "@/logic/Utils";

export class ConfigurationRangeTable extends Table<ConfigurationRangeStoredModel> {
    filterConfigurationList(): Array<SearchConditionMultiple> {
        return [
            new SearchCondition(
                ['id', 'start', 'end', 'financialInstitute'],
                SearchType.LK,
                this.filterOptions.searchValue
            )
        ];
    }

    get headers(): Array<HeaderCell> {
        return HeaderCell.changeSortTypeInList(
            TableFilter.getFilterObject('configurationRange'),
            [
                new HeaderCell('Id', 'id', HeaderCell.DESC),
                new HeaderCell('Start of range', 'start'),
                new HeaderCell('End of range', 'end'),
                new HeaderCell(
                    'Financial institute',
                    'financialInstitute',
                    HeaderCell.SORTABLE,
                    false,
                    true,
                    '200px',
                    () => Utils.isSuperAdmin()
                ),
                new HeaderCell('', 'actions', HeaderCell.UNSORTABLE),
            ]);
    }

}
