import {Table} from "@/logic/table/Table";
import {AidKeyStoredModel} from "@/store/models/stored/AidKeyStoredModel";
import {SearchConditionMultiple} from "filter-data/lib/lib/types";
import {HeaderCell} from "@/logic/table/HeaderCell";
import {SearchCondition} from "@/logic/table/SearchCondition";
import {SearchType} from "filter-data";
import {TableFilter} from "@/logic/table/TableFilter";
import {Utils} from "@/logic/Utils";

export class AidKeyTable extends Table<AidKeyStoredModel> {
    filterConfigurationList(): Array<SearchConditionMultiple> {
        return [
            new SearchCondition(
                ['id', 'keyIndex', 'exponent', 'hash', 'rid', 'comment', 'financialInstitute'],
                SearchType.LK,
                this.filterOptions.searchValue
            )
        ];
    }

    get headers(): Array<HeaderCell> {
        return HeaderCell.changeSortTypeInList(
            TableFilter.getFilterObject('aidKey'),
            [
                new HeaderCell('Id', 'id', HeaderCell.DESC),
                new HeaderCell('Key index', 'keyIndex'),
                new HeaderCell('Exponent', 'exponent'),
                new HeaderCell('Comment', 'comment', HeaderCell.SORTABLE, false, true, '200px'),
                new HeaderCell('Key hash', 'hash'),
                new HeaderCell('RID', 'rid'),
                new HeaderCell(
                    'Financial institute',
                    'financialInstitute',
                    HeaderCell.SORTABLE,
                    false,
                    true,
                    '200px',
                    () => Utils.isSuperAdmin()
                ),
                new HeaderCell('', 'actions', HeaderCell.UNSORTABLE),
            ]
        );
    }

}
