import {BaseStoredModel} from "@/store/models/stored/BaseStoredModel";

export class AidStoredModel extends BaseStoredModel {

    public static CONTACT = 0;
    public static CONTACTLESS = 1;

    private readonly _type: number;
    private readonly _priorityIndex: string;
    private readonly _tacDefault: string;
    private readonly _tacDenial: string;
    private readonly _tacOnline: string;
    private readonly _selectionFlags: string;
    private readonly _rid: string;
    private readonly _pix: string;
    private readonly _tc: string;
    private readonly _tcForRefund: string;
    private readonly _tav: string;
    private readonly _lav: string;
    private readonly _id: number;
    private readonly _terminalTransactionQualifiers: string;
    private readonly _terminalTransactionQualifiersForRefund: string;
    private readonly _enhancedContactlessReaderCapabilities: string;
    private readonly _enhancedContactlessReaderCapabilitiesForRefund: string;
    private readonly _trustedPlatformModuleCapabilities: string;
    private readonly _trustedPlatformModuleCapabilitiesForRefund: string;
    private _isDuplicate: boolean;

    constructor(aid: AidStoredModel) {
        super(aid);

        this._id = aid.id;
        this._tc = aid.tc;
        this._rid = aid.rid;
        this._tav = aid.tav;
        this._lav = aid.lav;
        this._pix = aid.pix;
        this._type = aid.type;
        this._tacDenial = aid.tacDenial;
        this._tacOnline = aid.tacOnline;
        this._tacDefault = aid.tacDefault;
        this._tcForRefund = aid.tcForRefund;
        this._priorityIndex = aid.priorityIndex;
        this._selectionFlags = aid.selectionFlags;
        this._terminalTransactionQualifiers = aid.terminalTransactionQualifiers;
        this._trustedPlatformModuleCapabilities = aid.trustedPlatformModuleCapabilities;
        this._enhancedContactlessReaderCapabilities = aid.enhancedContactlessReaderCapabilities;
        this._terminalTransactionQualifiersForRefund = aid.terminalTransactionQualifiersForRefund;
        this._enhancedContactlessReaderCapabilitiesForRefund = aid.enhancedContactlessReaderCapabilitiesForRefund;
        this._trustedPlatformModuleCapabilitiesForRefund = aid.enhancedContactlessReaderCapabilitiesForRefund;
        this._isDuplicate = false;
    }

    get type(): number {
        return this._type;
    }

    get priorityIndex(): string {
        return this._priorityIndex;
    }

    get tacDefault(): string {
        return this._tacDefault;
    }

    get tacDenial(): string {
        return this._tacDenial;
    }

    get tacOnline(): string {
        return this._tacOnline;
    }

    get selectionFlags(): string {
        return this._selectionFlags;
    }

    get rid(): string {
        return this._rid;
    }

    get pix(): string {
        return this._pix;
    }

    get tc(): string {
        return this._tc;
    }

    get tcForRefund(): string {
        return this._tcForRefund;
    }

    get tav(): string {
        return this._tav;
    }

    get lav(): string {
        return this._lav;
    }

    get id(): number {
        return this._id;
    }

    get terminalTransactionQualifiers(): string {
        return this._terminalTransactionQualifiers;
    }

    get terminalTransactionQualifiersForRefund(): string {
        return this._terminalTransactionQualifiersForRefund;
    }

    get enhancedContactlessReaderCapabilities(): string {
        return this._enhancedContactlessReaderCapabilities;
    }

    get enhancedContactlessReaderCapabilitiesForRefund(): string {
        return this._enhancedContactlessReaderCapabilitiesForRefund;
    }

    get trustedPlatformModuleCapabilities(): string {
        return this._trustedPlatformModuleCapabilities;
    }

    get trustedPlatformModuleCapabilitiesForRefund(): string {
        return this._trustedPlatformModuleCapabilitiesForRefund;
    }

    get isDuplicate(): boolean {
        return this._isDuplicate;
    }

    set isDuplicate(value: boolean) {
        this._isDuplicate = value;
    }

    public getName(): string {
        let aidName = `(${this._id}) ${this._rid}`;

        if (this._pix) {
            aidName += `_${this._pix}`;
        }

        aidName += ` (${this._type === AidStoredModel.CONTACT ? 'Contact' : 'Contactless'})`;

        return aidName;
    }
}
