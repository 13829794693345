import {ConfigurationLimitFormModel} from "@/store/models/forms/ConfigurationLimitFormModel";
import {BaseRequestModel} from "@/store/models/request/BaseRequestModel";

export class ConfigurationLimitRequestModel extends BaseRequestModel {
    private cvmLimit: number | undefined;
    private letterCode: string;
    private numberCode: string;
    private currencyId: number | undefined;
    private id: number | undefined;
    private contactlessFloor: number | undefined;
    private contactlessLimit: number | undefined;
    private terminalTransactionLimit: number | undefined;
    private odcvmTransactionLimit: number | undefined;

    constructor(configurationLimit: ConfigurationLimitFormModel) {
        super(configurationLimit);

        this.cvmLimit = configurationLimit.cvmLimit;
        this.letterCode = configurationLimit.letterCode;
        this.numberCode = configurationLimit.numberCode;
        this.currencyId = configurationLimit.currencyId;
        this.id = configurationLimit.id;
        this.contactlessFloor = configurationLimit.contactlessFloor;
        this.contactlessLimit = configurationLimit.contactlessLimit;
        this.terminalTransactionLimit = configurationLimit.terminalTransactionLimit;
        this.odcvmTransactionLimit = configurationLimit.odcvmTransactionLimit;
    }
}
