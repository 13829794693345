import {BaseStoredModel} from "@/store/models/stored/BaseStoredModel";

export class OperationStoredModel extends BaseStoredModel {
    private readonly _id: number;
    private readonly _name: string;
    private readonly _enabled: boolean;
    private readonly _description: string;
    private readonly _defaultAccess: boolean;

    constructor(operation: OperationStoredModel) {
        super(operation);

        this._id = operation.id;
        this._name = operation.name;
        this._enabled = operation.enabled;
        this._description = operation.description;
        this._defaultAccess = operation.defaultAccess;
    }

    get id(): number {
        return this._id;
    }

    get name(): string {
        return this._name;
    }

    get enabled(): boolean {
        return this._enabled;
    }

    get description(): string {
        return this._description;
    }

    get defaultAccess(): boolean {
        return this._defaultAccess;
    }
}
