import {Table} from "@/logic/table/Table";
import TerminalStoredModel from "@/store/models/stored/TerminalStoredModel";
import {HeaderCell} from "@/logic/table/HeaderCell";
import {SearchConditionMultiple} from "filter-data/lib/lib/types";
import {SearchCondition} from "@/logic/table/SearchCondition";
import {SearchType} from "filter-data";
import {TableFilter} from "@/logic/table/TableFilter";
import {Utils} from "@/logic/Utils";

export class TerminalTable extends Table<TerminalStoredModel> {
    filterConfigurationList(): Array<SearchConditionMultiple> {
        return [
            new SearchCondition(
                [
                    'id',
                    'externalId',
                    'status',
                    'typeName',
                    'serialNumber',
                    'configurationName',
                    'autoReconciliationGroupName',
                    'merchantName',
                    'financialInstitute'
                ],
                SearchType.LK,
                this.filterOptions.searchValue
            )
        ];
    }

    get headers(): Array<HeaderCell> {
        return HeaderCell.changeSortTypeInList(
            TableFilter.getFilterObject('terminal'),
            [
                new HeaderCell('Terminal ID', 'externalId', HeaderCell.DESC, true),
                new HeaderCell('Configuration', 'configurationName'),
                new HeaderCell('Type name', 'typeName'),
                new HeaderCell('Serial number', 'serialNumber'),
                new HeaderCell('Auto reconciliation group', 'autoReconciliationGroupName'),
				new HeaderCell('Merchant', 'merchantName', HeaderCell.SORTABLE, false, true, '200px'),
				 new HeaderCell(
                    'Financial institute',
                    'financialInstitute',
                    HeaderCell.SORTABLE,
                    false,
                    true,
                    '200px',
                    () => Utils.isSuperAdmin()
                ),
                new HeaderCell('Status', 'status'),
                new HeaderCell('', 'actions', HeaderCell.UNSORTABLE)
            ]
        );
    }
}
