import {BaseStoredModel} from "@/store/models/stored/BaseStoredModel";

export default class TerminalTypeStoredModel extends BaseStoredModel{

    public static ENABLED = true;
    public static DISABLED = false;

    private readonly _id: number;
    private readonly _type: number = 0;
    private readonly _name: string = "";
    private readonly _description: string = "";
    private readonly _enabled: boolean = TerminalTypeStoredModel.DISABLED;

    constructor(terminalType: TerminalTypeStoredModel) {
        super(terminalType);

        this._id = terminalType.id;
        this._type = terminalType.type;
        this._name = terminalType.name;
        this._description = terminalType.description;
        this._enabled = terminalType.enabled;
    }

    get id(): number {
        return this._id;
    }

    public get type(): number {
        return this._type;
    }

    public get name(): string {
        return this._name;
    }

    public get description(): string {
        return this._description;
    }

    public get enabled(): boolean {
        return this._enabled;
    }

    public getName(): string {
        return this._name;
    }
}
