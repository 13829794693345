import {ActionContext} from "vuex";
import {State} from "@/store";
import axios from "@/http-common";
import {AxiosError, AxiosResponse} from "axios";
import {TransactionsTurnoverStatistic} from "@/store/models/statistic/TransactionsTurnoverStatistic";
import {TransactionStatistic} from "@/store/models/statistic/TransactionStatistic";
import {TransactionsTerminalStatistic} from "@/store/models/statistic/TransactionsTerminalStatistic";
import {TotalTransactionsStatistic} from "@/store/models/statistic/TotalTransactionsStatistic";
import {StatisticTransactionsByPeriodModel} from "@/store/models/request/StatisticTransactionsByPeriodModel";
import {
    TerminalModelsStatistic,
    TerminalOnlineStatistic,
    TerminalVersionsStatistic,
    TerminalWithOperationsStatistic
} from '../models/statistic/TerminalStatistic';
import {TransactionTimeoutsStatistic} from "@/store/models/statistic/TransactionTimeoutsStatistic";
import {SelectOption} from '@/logic/select/SelectOption';
import {TransactionTimeStatistic} from "@/store/models/statistic/TransactionTimeStatistic";
import {StatisticTransactionsNetworkModel} from "@/store/models/request/StatisticTransactionsNetworkModel";

export class StatisticState {

    constructor(
        private _transactionsNetworkTypes: Array<string> = [],
        private _terminalStatisticByType: Array<TerminalModelsStatistic> = [],
        private _transactionsTimeouts: Array<TransactionTimeoutsStatistic> = [],
        private _transactionTimeStatistic: Array<TransactionTimeStatistic> = [],
        private _transactionStatistic: TransactionStatistic = new TransactionStatistic(),
        private _terminalAppVersions: TerminalVersionsStatistic = new TerminalVersionsStatistic(),
        private _terminalOnlineStatistic: TerminalOnlineStatistic = new TerminalOnlineStatistic(),
        private _terminalFirmwareVersions: TerminalVersionsStatistic = new TerminalVersionsStatistic(),
        private _totalTransactionsStatistic: TotalTransactionsStatistic = new TotalTransactionsStatistic(),
        private _terminalWithOperation: TerminalWithOperationsStatistic = new TerminalWithOperationsStatistic(),
        private _transactionsTerminalStatistic: TransactionsTerminalStatistic = new TransactionsTerminalStatistic(),
        private _transactionsTurnoverStatistic: TransactionsTurnoverStatistic = new TransactionsTurnoverStatistic()
    ) {
    }

    get terminalWithOperation(): TerminalWithOperationsStatistic {
        return this._terminalWithOperation;
    }

    set terminalWithOperation(value: TerminalWithOperationsStatistic) {
        this._terminalWithOperation = value;
    }

    get transactionsTurnoverStatistic(): TransactionsTurnoverStatistic {
        return this._transactionsTurnoverStatistic;
    }

    set transactionsTurnoverStatistic(value: TransactionsTurnoverStatistic) {
        this._transactionsTurnoverStatistic = value;
    }

    get transactionStatistic(): TransactionStatistic {
        return this._transactionStatistic;
    }

    set transactionStatistic(value: TransactionStatistic) {
        this._transactionStatistic = value;
    }

    get transactionsTerminalStatistic(): TransactionsTerminalStatistic {
        return this._transactionsTerminalStatistic;
    }

    set transactionsTerminalStatistic(value: TransactionsTerminalStatistic) {
        this._transactionsTerminalStatistic = value;
    }

    get totalTransactionsStatistic(): TotalTransactionsStatistic {
        return this._totalTransactionsStatistic;
    }

    set totalTransactionsStatistic(value: TotalTransactionsStatistic) {
        this._totalTransactionsStatistic = value;
    }

    get terminalStatisticByType(): Array<TerminalModelsStatistic> {
        return this._terminalStatisticByType;
    }

    set terminalStatisticByType(value: Array<TerminalModelsStatistic>) {
        this._terminalStatisticByType = value;
    }

    get terminalWithOperationsStatistic(): TerminalWithOperationsStatistic {
        return this._terminalWithOperation;
    }

    set terminalWithOperationsStatistic(value: TerminalWithOperationsStatistic) {
        this._terminalWithOperation = value;
    }

    get terminalFirmwareVersions(): TerminalVersionsStatistic {
        return this._terminalFirmwareVersions;
    }

    set terminalFirmwareVersions(value: TerminalVersionsStatistic) {
        this._terminalFirmwareVersions = value;
    }

    get terminalAppVersions(): TerminalVersionsStatistic {
        return this._terminalAppVersions;
    }

    set terminalAppVersions(value: TerminalVersionsStatistic) {
        this._terminalAppVersions = value;
    }

    get terminalOnlineStatistic(): TerminalOnlineStatistic {
        return this._terminalOnlineStatistic;
    }

    set terminalOnlineStatistic(value: TerminalOnlineStatistic) {
        this._terminalOnlineStatistic = value;
    }

    get transactionsTimeouts() {
        return this._transactionsTimeouts;
    }

    set transactionsTimeouts(value: Array<TransactionTimeoutsStatistic>) {
        this._transactionsTimeouts = value;
    }

    get transactionsNetworkTypes(): Array<string> {
        return this._transactionsNetworkTypes;
    }

    set transactionsNetworkTypes(transactionList: Array<string>) {
        this._transactionsNetworkTypes = transactionList;
    }

    get transactionTimeStatistic(): Array<TransactionTimeStatistic> {
        return this._transactionTimeStatistic;
    }

    set transactionTimeStatistic(transactionTimeStatistics: Array<TransactionTimeStatistic>) {
        this._transactionTimeStatistic = transactionTimeStatistics;
    }
}

type Context = ActionContext<StatisticState, State>;

export default {
    namespaced: true,
    state: new StatisticState(),

    mutations: {
        terminalStatisticByType(state: StatisticState, statistic: Array<TerminalModelsStatistic>): void {
            state.terminalStatisticByType = statistic.sort((a: TerminalModelsStatistic, b: TerminalModelsStatistic) =>
                b.numberTerminals - a.numberTerminals);
        },

        terminalWithOperationStatistic(state: StatisticState, statistic: TerminalWithOperationsStatistic): void {
            state.terminalWithOperationsStatistic = statistic;
        },

        terminalFirmwareVersionsStatistic(state: StatisticState, statistic: TerminalVersionsStatistic): void {
            state.terminalFirmwareVersions = statistic;
        },

        terminalAppVersionsStatistic(state: StatisticState, statistic: TerminalVersionsStatistic): void {
            state.terminalAppVersions = statistic;
        },

        terminalOnlineStatistic(state: StatisticState, statistic: TerminalOnlineStatistic): void {
            state.terminalOnlineStatistic = statistic;
        },

        transactionTurnoverStatistic(state: StatisticState, statistic: TransactionsTurnoverStatistic): void {
            state.transactionsTurnoverStatistic = new TransactionsTurnoverStatistic(statistic);
        },

        transactionStatistic(state: StatisticState, statistic: TransactionStatistic): void {
            state.transactionStatistic = new TransactionStatistic(statistic);
        },

        transactionsTerminalStatistic(state: StatisticState, statistic: TransactionsTerminalStatistic): void {
            state.transactionsTerminalStatistic = new TransactionsTerminalStatistic(statistic);
        },

        totalTransactionsStatistic(state: StatisticState, statistic: TotalTransactionsStatistic): void {
            state.totalTransactionsStatistic = new TotalTransactionsStatistic(statistic);
        },

        transactionsTimeouts(state: StatisticState, timeoutsStatistics: Array<TransactionTimeoutsStatistic>): void {
            state.transactionsTimeouts = timeoutsStatistics;
        },

        transactionsNetworkTypes(state: StatisticState, networkTypes: Array<string>): void {
            state.transactionsNetworkTypes = networkTypes.sort();
        },

        transactionTimeStatistic(state: StatisticState, statistic: Array<TransactionTimeStatistic>): void {
            state.transactionTimeStatistic = statistic;
        }
    },

    actions: {
        transactionWithTurnover(context: Context, data: StatisticTransactionsByPeriodModel): Promise<void> {
            return axios.get('/api/statistic/transaction-turnover-by-period', {params: data})
                .then((response: AxiosResponse) => context.commit(`transactionTurnoverStatistic`, response.data))
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        },

        transaction(context: Context, data: StatisticTransactionsByPeriodModel): Promise<void> {
            return axios.get('/api/statistic/transaction-by-period', {params: data})
                .then((response: AxiosResponse) => context.commit(`transactionStatistic`, response.data))
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        },

        terminal(context: Context, data: StatisticTransactionsByPeriodModel): Promise<void> {
            return axios.get('/api/statistic/terminals-by-period', {params: data})
                .then((response: AxiosResponse) => context.commit(`transactionsTerminalStatistic`, response.data))
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        },

        total(context: Context, data: StatisticTransactionsByPeriodModel): Promise<void> {
            return axios.get('/api/statistic/total-by-period', {params: data})
                .then((response: AxiosResponse) => context.commit(`totalTransactionsStatistic`, response.data))
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        },
        terminalStatisticByType(context: Context, financialInstituteId: string | number): Promise<void> {
            return axios.get('/api/statistic/terminal-types', {params: {financialInstituteId}})
                .then((response: AxiosResponse) => context.commit('terminalStatisticByType', response.data))
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        },

        terminalWithOperationStatistic(context: Context): Promise<void> {
            return axios.get(`/api/statistic/terminal-per-today`)
                .then((response: AxiosResponse) => context.commit(`terminalWithOperationStatistic`, response.data))
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        },

        terminalFirmwareVersionsStatistic(
            context: Context,
            params: { terminalType?: string | number, financialInstituteId?: string | number }
          ): Promise<void> {
            return axios.get('api/statistic/device/version-count/sdk', { params })
              .then((response: AxiosResponse) => context.commit('terminalFirmwareVersionsStatistic', response.data))
              .catch((error: AxiosError) => context.dispatch('alert/error', error, { root: true }));
        },  

        terminalAppVersionsStatistic(
            context: Context,
            params: { terminalType?: string | number, financialInstituteId?: string | number }
          ): Promise<void> {
            return axios.get('api/statistic/device/version-count/app', { params })
              .then((response: AxiosResponse) => context.commit('terminalAppVersionsStatistic', response.data))
              .catch((error: AxiosError) => context.dispatch('alert/error', error, { root: true }));
        },          

        terminalOnlineStatistic(context: Context, financialInstituteId?: string | number): Promise<void> {
            return axios.get('/api/statistic/device/online', {params: {financialInstituteId}})
                .then((response: AxiosResponse) => context.commit('terminalOnlineStatistic', response.data))
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        },

        transactionsTimeouts(context: Context, data: StatisticTransactionsNetworkModel): Promise<void> {
            return axios.get('/api/statistic/transactions-timeouts', {params: data})
                .then((response: AxiosResponse) => context.commit('transactionsTimeouts', response.data))
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        },

        transactionTime(context: Context, data: StatisticTransactionsNetworkModel): Promise<void> {
            return axios.get('/api/statistic/transactions-time', {params: data})
                .then((response: AxiosResponse) => context.commit(`transactionTimeStatistic`, response.data))
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        },

        transactionsNetworkTypes(context: Context): Promise<void> {
            return axios.get('/api/transaction/network-types')
                .then((response: AxiosResponse) => context.commit('transactionsNetworkTypes', response.data))
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        }
    },

    getters: {
        terminalByType: (state: StatisticState): Array<TerminalModelsStatistic> => {
            return state.terminalStatisticByType;
        },

        terminalWithOperationStatistic: (state: StatisticState): TerminalWithOperationsStatistic => {
            return state.terminalWithOperationsStatistic;
        },

        terminalFirmwareVersions: (state: StatisticState): TerminalVersionsStatistic => {
            return state.terminalFirmwareVersions;
        },

        terminalAppVersions: (state: StatisticState): TerminalVersionsStatistic => {
            return state.terminalAppVersions;
        },

        terminalOnline: (state: StatisticState): TerminalOnlineStatistic => {
            return state.terminalOnlineStatistic;
        },

        transactionTurnover: (state: StatisticState): TransactionsTurnoverStatistic => {
            return state.transactionsTurnoverStatistic;
        },

        transaction: (state: StatisticState): TransactionStatistic => {
            return state.transactionStatistic;
        },

        transactionsTerminal: (state: StatisticState): TransactionsTerminalStatistic => {
            return state.transactionsTerminalStatistic;
        },

        totalTransactions: (state: StatisticState): TotalTransactionsStatistic => {
            return state.totalTransactionsStatistic;
        },

        transactionsTimeouts: (state: StatisticState): Array<TransactionTimeoutsStatistic> => {
            return state.transactionsTimeouts;
        },

        transactionTime: (state: StatisticState): Array<TransactionTimeStatistic> => {
            return state.transactionTimeStatistic;
        },

        selectTransactionsNetworkTypes: (state: StatisticState): Array<SelectOption<string>> => {
            const networkTypesSelect = state.transactionsNetworkTypes
                .map((networkType) => new SelectOption<string>(networkType, networkType));

            networkTypesSelect.unshift(new SelectOption<string>('All', ''));

            return networkTypesSelect;
        }
    },
}
