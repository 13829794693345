
import {defineComponent} from 'vue';
import AppSidebarNav from '@/components/AppSidebarNav.vue';
import {mapGetters} from "vuex";
import {Router} from "@/store/models/Router";

export default defineComponent({
  name: 'AppSidebar',

  components: {
    AppSidebarNav,
  },

  data() {
    return {
      Router: Router
    }
  },

  computed: {
    ...mapGetters({
      sidebarUnfoldable: 'sidebar/sidebarUnfoldable',
      sidebarVisible: 'sidebar/sidebarVisible'
    }),
  },
})
