import {ActionContext} from "vuex";
import {State} from "@/store";
import {SelectOption} from "@/logic/select/SelectOption"
import axios from "@/http-common";
import {AxiosError, AxiosResponse} from "axios";
import {TerminalTypeRequestModel} from "@/store/models/request/TerminalTypeRequestModel";
import TerminalTypeStoredModel from "@/store/models/stored/TerminalTypeStoredModel";
import {TerminalTypeTable} from "@/logic/table/TerminalTypeTable";
import {TableFilter} from "@/logic/table/TableFilter";
import {TerminalTypeFormModel} from "@/store/models/forms/TerminalTypeFormModel";
import router from "@/router";

export class TerminalTypeState {
    constructor(
        private _terminalTypeList: Array<TerminalTypeStoredModel> = [],

        private _filteredTerminalTypeList: TerminalTypeTable = new TerminalTypeTable(
            [],
            TableFilter.getFilterObject('terminalType')
        ),

        private _terminalTypeListIsLoading: boolean = true
    ) {}

    public get terminalTypeList(): Array<TerminalTypeStoredModel> {
        return this._terminalTypeList;
    }

    public set terminalTypeList(terminalTypeList: Array<TerminalTypeStoredModel>) {
        this._terminalTypeList = terminalTypeList;
    }

    public get filteredTerminalTypeList(): TerminalTypeTable {
        return this._filteredTerminalTypeList;
    }

    public set filteredTerminalTypeList(terminalTypeList: TerminalTypeTable) {
        this._filteredTerminalTypeList = terminalTypeList;
    }

    public get terminalTypeListIsLoading(): boolean {
        return this._terminalTypeListIsLoading;
    }

    public set terminalTypeListIsLoading(loadingStatus: boolean) {
        this._terminalTypeListIsLoading = loadingStatus;
    }
}

type Context = ActionContext<TerminalTypeState, State>;

export default {
    namespaced: true,

    state: new TerminalTypeState(),

    mutations: {
        list(state: TerminalTypeState, terminalTypeList: Array<TerminalTypeStoredModel>): void {
            state.terminalTypeList = terminalTypeList
                .map((terminalType: TerminalTypeStoredModel) => new TerminalTypeStoredModel(terminalType))
                .sort((terminalTypeA, terminalTypeB) => terminalTypeA.id > terminalTypeB.id ? -1 : 1);
        },

        filteredList(state: TerminalTypeState): void {
            state.filteredTerminalTypeList = new TerminalTypeTable(
                state.terminalTypeList,
                TableFilter.getFilterObject('terminalType')
            );
        },

        filterOptions(state: TerminalTypeState, filterOptions: TableFilter): void {
            state.filteredTerminalTypeList.filterOptions = new TableFilter(filterOptions);
            TableFilter.setFilterObject('terminalType', filterOptions);
        },

        add(state: TerminalTypeState, terminalType: TerminalTypeStoredModel): void {
            state.terminalTypeList.push(terminalType);
        },

        update(state: TerminalTypeState, updateTerminalType: TerminalTypeStoredModel): void {
            state.terminalTypeList = state.terminalTypeList
                .map(terminalType => terminalType.id === updateTerminalType.id ? updateTerminalType : terminalType);

            state.filteredTerminalTypeList.dataList = state.terminalTypeList;
        },
    },

    actions: {
        list(context: Context): Promise<void> {
            return axios.get('/api/terminal-type/list')
                .then((response: AxiosResponse) => {
                    context.commit('list', response.data);
                    context.commit('filteredList', TableFilter.getFilterObject('terminalType'));
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}))
                .finally(() => context.state.terminalTypeListIsLoading = false);
        },

        getById(context: Context, id: number): Promise<TerminalTypeStoredModel> {
            return axios.get(`/api/terminal-type/${id}`)
                .then((response: AxiosResponse) => new TerminalTypeStoredModel(response.data))
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        },

        add(context: Context, terminalType: TerminalTypeFormModel): Promise<void> {
            return axios.post('/api/terminal-type/', new TerminalTypeRequestModel(terminalType))
                .then((response: AxiosResponse) => {
                    context.commit('add', new TerminalTypeStoredModel(response.data));
                    context.dispatch('alert/success', 'Terminal type created', {root: true});
                    router.push({ path: `/terminal-type/list` });
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        },

        update(context: Context, terminalType: TerminalTypeFormModel): Promise<void> {
            return axios.put('/api/terminal-type/', new TerminalTypeRequestModel(terminalType))
                .then((response: AxiosResponse) => {
                    context.commit('update', new TerminalTypeStoredModel(response.data));
                    context.dispatch('alert/success','Terminal type updated', {root: true});
                    router.push({ path: `/terminal-type/list` });
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        },

        changeTerminalTypeStatus(context: Context, terminalTypeForm: TerminalTypeFormModel): Promise<void> {
            terminalTypeForm.enabled = !terminalTypeForm.enabled;

            return context.dispatch('update', terminalTypeForm);
        },
    },

    getters: {
        list: (state: TerminalTypeState): Array<TerminalTypeStoredModel> => {
            return state.terminalTypeList;
        },

        terminalType: (state: TerminalTypeState): (id: number) => TerminalTypeStoredModel | undefined => {
            return (id: number) => state.terminalTypeList
                .find((terminalType: TerminalTypeStoredModel) => id === terminalType.id);
        },

        selectEnabledOptionList: (state: TerminalTypeState): Array<SelectOption<number>> => {
            return state.terminalTypeList
                .filter((terminalType: TerminalTypeStoredModel) => terminalType.enabled)
                .map((terminalType: TerminalTypeStoredModel) => new SelectOption<number>(
                    new TerminalTypeStoredModel(terminalType).getSelectName(),
                    terminalType.id
                ))
        },

        filteredList: (state: TerminalTypeState): TerminalTypeTable => {
            return state.filteredTerminalTypeList;
        },

        terminalTypeListIsLoading: (state: TerminalTypeState): boolean => {
            return state.terminalTypeListIsLoading;
        }
    },
}
