import {BaseStoredModel} from "@/store/models/stored/BaseStoredModel";

export class BinRangeStoredModel extends BaseStoredModel {

    private readonly _id: number;
    private readonly _paymentSystem: string;
    private readonly _start: string;
    private readonly _end: string;

    constructor(binRange: BinRangeStoredModel) {
        super(binRange);

        this._id = binRange.id;
        this._paymentSystem = binRange.paymentSystem;
        this._start = binRange.start;
        this._end = binRange.end;
    }

    get id(): number {
        return this._id;
    }

    get paymentSystem(): string {
        return this._paymentSystem;
    }

    get start(): string {
        return this._start;
    }

    get end(): string {
        return this._end;
    }
}
