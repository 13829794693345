import {TerminalTypeFormModel} from "@/store/models/forms/TerminalTypeFormModel";
import {BaseRequestModel} from "@/store/models/request/BaseRequestModel";

export class TerminalTypeRequestModel extends BaseRequestModel {

    private id: number | undefined;
    private type: number | undefined;
    private name: string;
    private description: string;
    private enabled: boolean;

    constructor(terminalType: TerminalTypeFormModel) {
        super(terminalType);

        this.id = terminalType.id;
        this.type = terminalType.type;
        this.name = terminalType.name;
        this.description = terminalType.description;
        this.enabled = terminalType.enabled;
    }
}
