import {ActionContext} from "vuex";
import {State} from "@/store";
import axios from "@/http-common";
import {AxiosError, AxiosResponse} from "axios";
import {TransactionTable} from "@/logic/table/TransactionTable";
import {TableFilter} from "@/logic/table/TableFilter";
import TransactionStoredModel from "@/store/models/stored/transaction/TransactionStoredModel";

export class TransactionState {
    constructor(
        private _transactionList: Array<TransactionStoredModel> = [],

        private _filteredTransactionList: TransactionTable =
            new TransactionTable([], TableFilter.getFilterObject('transaction')),

        private _transactionListIsLoading: boolean = true
    ) {}

    public get transactionList(): Array<TransactionStoredModel> {
        return this._transactionList;
    }

    public set transactionList(transactionList: Array<TransactionStoredModel>) {
        this._transactionList = transactionList;
    }

    get filteredTransactionList(): TransactionTable {
        return this._filteredTransactionList;
    }

    set filteredTransactionList(terminalList: TransactionTable) {
        this._filteredTransactionList = terminalList;
    }

    get transactionListIsLoading(): boolean {
        return this._transactionListIsLoading;
    }

    set transactionListIsLoading(loadingStatus: boolean) {
        this._transactionListIsLoading = loadingStatus;
    }
}

type Context = ActionContext<TransactionState, State>;

export default {
    namespaced: true,

    state: new TransactionState(),

    mutations: {
        list(state: TransactionState, transactionList: Array<TransactionStoredModel>): void {
            state.transactionList = transactionList
                .map((transaction: TransactionStoredModel) => new TransactionStoredModel(transaction));
        },

        filteredList(state: TransactionState): void {
            state.filteredTransactionList =
                new TransactionTable(state.transactionList, TableFilter.getFilterObject('transaction'));
        },

        filterOptions(state: TransactionState, filterOptions: TableFilter): void {
            state.filteredTransactionList.filterOptions = new TableFilter(filterOptions);
            TableFilter.setFilterObject('transaction', filterOptions);
        },
    },

    actions: {
        list(context: Context): Promise<void> {
            return axios.get('/api/transaction/list')
                .then((response: AxiosResponse) => {
                    context.commit('list', response.data);
                    context.commit('filteredList', TableFilter.getFilterObject('transaction'));
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}))
                .finally(() => context.state.transactionListIsLoading = false);
        },

        getById(context: Context, id: number): Promise<TransactionStoredModel> {
            return axios.get(`/api/transaction/${id}`)
                .then((response: AxiosResponse) => new TransactionStoredModel(response.data))
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}))
        }
    },

    getters: {
        list: (state: TransactionState): Array<TransactionStoredModel> => {
            return state.transactionList;
        },

        byId: (state: TransactionState): (type: number) => TransactionStoredModel | undefined => {
            return (id: number) =>
                state.transactionList.find((transaction: TransactionStoredModel) => id === transaction.id);
        },

        filteredList: (state: TransactionState): TransactionTable => {
            return state.filteredTransactionList;
        },

        transactionListIsLoading: (state: TransactionState): boolean => {
            return state.transactionListIsLoading;
        }
    },
}
