import {Table} from "@/logic/table/Table";
import {MerchantStoredModel} from "@/store/models/stored/MerchantStoredModel";
import {HeaderCell} from "@/logic/table/HeaderCell";
import {SearchConditionMultiple} from "filter-data/lib/lib/types";
import {SearchCondition} from "@/logic/table/SearchCondition";
import {SearchType} from "filter-data";
import {TableFilter} from "@/logic/table/TableFilter";
import {Utils} from "@/logic/Utils";

export class MerchantTable extends Table<MerchantStoredModel> {
    filterConfigurationList(): Array<SearchConditionMultiple> {
        return [
            new SearchCondition(
                ['id', 'name', 'mcc', 'inn', 'nameAndLocation', 'externalId', 'financialInstitute'],
                SearchType.LK,
                this.filterOptions.searchValue
            )
        ];
    }

    get headers(): Array<HeaderCell> {
        return HeaderCell.changeSortTypeInList(
            TableFilter.getFilterObject('merchant'),
            [
                new HeaderCell('Id', 'id', HeaderCell.DESC),
                new HeaderCell('Name', 'name', HeaderCell.SORTABLE, false, true, '200px'),
                new HeaderCell('MCC', 'mcc'),
                new HeaderCell('INN', 'inn'),
                new HeaderCell('Location', 'nameAndLocation'),
                new HeaderCell('Address', 'address', HeaderCell.SORTABLE, false, false, '200px'),
                new HeaderCell('Merchant ID', 'externalId'),
                new HeaderCell(
                    'Financial institute',
                    'financialInstitute',
                    HeaderCell.SORTABLE,
                    false,
                    true,
                    '200px',
                    () => Utils.isSuperAdmin()
                ),
                new HeaderCell('', 'actions', HeaderCell.UNSORTABLE)
            ]
        );
    }
}
