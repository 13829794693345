import {ActionContext} from "vuex";
import {State} from "@/store";
import axios from "@/http-common";
import {AxiosError, AxiosResponse} from "axios";
import {CurrencyStoredModel} from "@/store/models/stored/CurrencyStoredModel";
import {CurrencyRequestModel} from "@/store/models/request/CurrencyRequestModel";
import {CurrencyFormModel} from "@/store/models/forms/CurrencyFormModel";
import {CurrencyTable} from "@/logic/table/CurrencyTable";
import {TableFilter} from "@/logic/table/TableFilter";
import {SelectOption} from "@/logic/select/SelectOption";
import router from "@/router";

export class CurrencyState {
    constructor(
        private _currencyList: Array<CurrencyStoredModel> = [],
        private _filteredCurrencyList: CurrencyTable = new CurrencyTable([], TableFilter.getFilterObject('currency')),
        private _currencyListIsLoading: boolean = true
    ) {}

    public get currencyList(): Array<CurrencyStoredModel> {
        return this._currencyList;
    }

    public set currencyList(currencyList: Array<CurrencyStoredModel>) {
        this._currencyList = currencyList;
    }

    get filteredCurrencyList(): CurrencyTable {
        return this._filteredCurrencyList;
    }

    set filteredCurrencyList(currencyList: CurrencyTable) {
        this._filteredCurrencyList = currencyList;
    }

    get currencyListIsLoading(): boolean {
        return this._currencyListIsLoading;
    }

    set currencyListIsLoading(loadingStatus: boolean) {
        this._currencyListIsLoading = loadingStatus;
    }
}

type Context = ActionContext<CurrencyState, State>;

export default {
    namespaced: true,

    state: new CurrencyState(),

    mutations: {
        list(state: CurrencyState, currencyList: Array<CurrencyStoredModel>): void {
            state.currencyList = currencyList
                .map((currency: CurrencyStoredModel) => new CurrencyStoredModel(currency))
                .sort((currencyA, currencyB) => currencyA.id > currencyB.id ? -1 : 1);
        },

        filteredList(state: CurrencyState): void {
            state.filteredCurrencyList = new CurrencyTable(state.currencyList, TableFilter.getFilterObject('currency'));
        },

        filterOptions(state: CurrencyState, filterOptions: TableFilter): void {
            state.filteredCurrencyList.filterOptions = new TableFilter(filterOptions);
            TableFilter.setFilterObject('currency', filterOptions);
        },

        add(state: CurrencyState, currency: CurrencyStoredModel): void {
            state.currencyList.push(currency);
        },

        update(state: CurrencyState, updateCurrency: CurrencyStoredModel): void {
            state.currencyList = state.currencyList
                .map(currency => currency.id === updateCurrency.id ? updateCurrency : currency);

            state.filteredCurrencyList.dataList = state.currencyList;
        },

        delete(state: CurrencyState, deletedId: number): void {
            state.currencyList = state.currencyList.filter(currency => currency.id !== deletedId);
            state.filteredCurrencyList.dataList = state.currencyList;
        }
    },

    actions: {
        list(context: Context): Promise<void> {
            return axios.get('/api/currency/list')
                .then((response: AxiosResponse) => {
                    context.commit('list', response.data);
                    context.commit('filteredList', TableFilter.getFilterObject('currency'));
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}))
                .finally(() => context.state.currencyListIsLoading = false);
        },

        accessibleList(context: Context): Promise<void> {
            return axios.get('/api/currency/accessible-list')
                .then((response: AxiosResponse) => {
                    context.commit('list', response.data);
                    context.commit('filteredList', TableFilter.getFilterObject('currency'));
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}))
                .finally(() => context.state.currencyListIsLoading = false);
        },

        getById(context: Context, id: number): Promise<CurrencyStoredModel> {
            return axios.get(`/api/currency/${id}`)
                .then((response: AxiosResponse) => new CurrencyStoredModel(response.data))
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        },

        add(context: Context, currencyForm: CurrencyFormModel): Promise<void> {
            return axios.post('/api/currency/', new CurrencyRequestModel(currencyForm))
                .then((response: AxiosResponse) => {
                    context.commit('add', new CurrencyStoredModel(response.data));
                    context.dispatch('alert/success', 'Currency created', {root: true});
                    router.push({path: `/currency/list`});
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        },

        update(context: Context, currencyForm: CurrencyFormModel): Promise<void> {
            return axios.put('/api/currency/', new CurrencyRequestModel(currencyForm))
                .then((response: AxiosResponse) => {
                    context.commit('update', new CurrencyStoredModel(response.data));
                    context.dispatch('alert/success', 'Currency updated', {root: true});
                    router.push({path: `/currency/list`});
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        },

        changeCurrencyStatus(context: Context, currencyForm: CurrencyFormModel): Promise<void> {
            currencyForm.enabled = !currencyForm.enabled;

            return context.dispatch('update', currencyForm);
        },

        delete(context: Context, id: number): Promise<void> {
            return axios.delete(`/api/currency/${id}`)
                .then(() => {
                    context.commit('delete', id);
                    context.dispatch('alert/success', 'Currency deleted', {root: true});
                    router.push({path: `/currency/list/`})
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        }
    },

    getters: {
        list: (state: CurrencyState): Array<CurrencyStoredModel> => {
            return state.currencyList;
        },

        byId: (state: CurrencyState) => (id: number): CurrencyStoredModel | undefined => {
            return state.currencyList.find((currency: CurrencyStoredModel) => id === currency.id);
        },

        filteredList(state: CurrencyState): CurrencyTable {
            return state.filteredCurrencyList;
        },

        currencyListIsLoading(state: CurrencyState): boolean {
            return state.currencyListIsLoading;
        },

        selectOptionList: (state: CurrencyState): Array<SelectOption<number>> => {
            return state.currencyList
                .map((currency: CurrencyStoredModel) =>
                    new SelectOption<number>(new CurrencyStoredModel(currency).getSelectName(), currency.id)
                )
        },

        selectOptionListForChart: (state: CurrencyState): Array<SelectOption<CurrencyStoredModel>> => {
            return state.currencyList
                .map((currency: CurrencyStoredModel) =>
                    new SelectOption<CurrencyStoredModel>(currency.symbol, currency));
        }
    },
}
