import {RouteLocation, RouterLink, useRoute} from 'vue-router'
import {SidebarItem} from "@/logic/sidebar/sidebarComponents";
import {h, resolveComponent} from "vue";
import {CBadge, CNavGroup} from "@coreui/vue";
import {VNode} from "@vue/runtime-core";
import router from "@/router";

function normalizePath(path: string) {
    return decodeURI(path)
        .replace(/#.*$/, '')
        .replace(/(index)?\.(html)$/, '');
}

function isActiveLink(route: RouteLocation, link: string): boolean {
    if (link === undefined) {
        return false;
    }

    if (route.hash === link) {
        return true;
    }

    const currentPath = normalizePath(route.path);
    const targetPath = normalizePath(link);

    return currentPath.includes(targetPath);
}

function isActiveItem(route: RouteLocation, item: SidebarItem): boolean {
    if (isActiveLink(route, item.to)) {
        return true;
    }

    if (item.items) {
        return item.items.some((child: SidebarItem) => isActiveItem(route, child));
    }

    return false;
}

function renderGroupOfLink(item: SidebarItem): VNode {
    const route = useRoute();

    return h(
        CNavGroup,
        {
            ...({
                visible: item.items && item.items.some((child) => isActiveItem(route, child)),
                isActive: item.items && item.items.some((child) => isActiveItem(route, child)),
                testId: `link__${item.name}`
            }),
        },
        {
            togglerContent: () => [
                h(resolveComponent('CIcon'), {
                    customClassName: 'nav-icon',
                    name: item.icon,
                }),
                item.name,
            ],
            default: () => item.items && item.items
                .filter(child => !child.permissionCondition || child.permissionCondition && child.permissionCondition())
                .map((child) => renderItem(child)),
        },
    );
}

function renderSingleLink(item: SidebarItem): VNode {
    const route = useRoute();

    return h(
        RouterLink,
        {
            to: item.to,
            custom: true,
        },
        {
            default: (props: any) =>
                h(
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    resolveComponent(item.component),
                    {
                        isActive: isActiveItem(route, item),
                        to: props.href,
                        onClick: () => router.push({path: props.href}),
                        testId: `link__${props.route.name}`
                    },
                    {
                        default: () => [
                            h(resolveComponent('CIcon'), {customClassName: 'nav-icon', name: item.icon}),
                            item.name,
                            h(
                                CBadge,
                                {class: 'ms-auto', color: item.badge && item.badge.color},
                                {default: () => item.badge && item.badge.text},
                            ),
                        ],
                    },
                ),
        },
    );
}

export function renderItem(item: SidebarItem): VNode {
    return item.items ? renderGroupOfLink(item) : renderSingleLink(item);
}


