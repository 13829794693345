import {AutoReconciliationGroupFormModel} from "@/store/models/forms/AutoReconciliationGroupFormModel";
import {BaseRequestModel} from "@/store/models/request/BaseRequestModel";

export class AutoReconciliationGroupRequestModel extends BaseRequestModel {
    private id: number | undefined;
    private name: string;
    private time: string;

    constructor(autoReconciliationGroup: AutoReconciliationGroupFormModel) {
        super(autoReconciliationGroup);

        this.id = autoReconciliationGroup.id;
        this.name = autoReconciliationGroup.name;
        this.time = autoReconciliationGroup.time;
    }
}
