import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_CBreadcrumbItem = _resolveComponent("CBreadcrumbItem")!
  const _component_CBreadcrumb = _resolveComponent("CBreadcrumb")!

  return (_openBlock(), _createBlock(_component_CBreadcrumb, { class: "d-md-down-none me-auto mb-0" }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.breadcrumbs, (item) => {
        return (_openBlock(), _createBlock(_component_CBreadcrumbItem, {
          key: item,
          active: item.active,
          testId: item.name
        }, {
          default: _withCtx(() => [
            (!item.active)
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 0,
                  to: item.active ? '' : item.path
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.name), 1)
                  ]),
                  _: 2
                }, 1032, ["to"]))
              : (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(item.name), 1))
          ]),
          _: 2
        }, 1032, ["active", "testId"]))
      }), 128))
    ]),
    _: 1
  }))
}