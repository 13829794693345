export class BaseStoredModel {
    private readonly _financialInstitute: string | undefined;
    private readonly _financialInstituteId: number | undefined;

    constructor(object: BaseStoredModel) {
        this._financialInstitute = object.financialInstitute;
        this._financialInstituteId = object.financialInstituteId;
    }

    get financialInstitute(): string | undefined {
        return this._financialInstitute;
    }

    get financialInstituteId(): number | undefined {
        return this._financialInstituteId;
    }

    public getName(): string {
        return '';
    }

    public getSelectName(): string {
        const financialInstitute = this.financialInstitute ? ` - ${this.financialInstitute}` : '';

        return this.getName() + financialInstitute;
    }
}
