import {MerchantFormModel} from "@/store/models/forms/MerchantFormModel";
import {BaseRequestModel} from "@/store/models/request/BaseRequestModel";

export class MerchantRequestModel extends BaseRequestModel {
    private id: number | undefined;
    private mcc: string | undefined;
    private inn: string;
    private name: string;
    private externalId: string;
    private nameAndLocation: string;
    private address: string;

    constructor(merchant: MerchantFormModel) {
        super(merchant);

        this.id = merchant.id;
        this.mcc = merchant.mcc;
        this.inn = merchant.inn;
        this.name = merchant.name;
        this.externalId = merchant.externalId;
        this.nameAndLocation = merchant.nameAndLocation;
        this.address = merchant.address;
    }
}
