import axios from "@/http-common";
import {ActionContext} from "vuex";
import {State} from "@/store";
import {LoginForm} from "@/store/models/forms/LoginForm";
import {ForgotPasswordForm} from "@/store/models/forms/ForgotPasswordForm";
import {ChangePasswordForm} from "@/store/models/forms/ChangePasswordForm";
import {AxiosError, AxiosResponse} from "axios";
import {Utils} from "@/logic/Utils";
import lscache from "lscache";
import UserStoredModel from "@/store/models/stored/UserStoredModel";
import {ForgotPasswordRequestModel} from "../models/request/ForgotPasswordRequestModel";
import {ChangePasswordRequestModel} from "../models/request/ChangePasswordRequestModel";

export class SessionState {
}

type Context = ActionContext<SessionState, State>;

export default {
    namespaced: true,

    state: new SessionState(),

    actions: {
        auth(context: Context, loginForm: LoginForm): Promise<void> {
            return axios.post('/api/session/auth', loginForm)
                .then((response: AxiosResponse) => Utils.authorizeUser(context, response))
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        },

        logout(context: Context): void {
            axios.post('/api/session/logout')
                .then(() => {
                    lscache.remove('isAuth');
                    lscache.remove('filter');
                    lscache.remove('sessionUser');
                    context.commit('sidebar/updateSidebarVisible', false, {root: true});
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        },

        restoreToken(context: Context, forgotPasswordForm: ForgotPasswordForm) {
            return axios.post('/api/session/send-restore-token', new ForgotPasswordRequestModel(forgotPasswordForm))
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        },

        passwordToken(context: Context, changePasswordForm: ChangePasswordForm) {
            return axios.post(`api/session/change-password/${changePasswordForm.token}`,
                new ChangePasswordRequestModel(changePasswordForm))
                .then((response: AxiosResponse) => Utils.authorizeUser(context, response))
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        }
    },

    getters: {
        sessionUser: (): UserStoredModel => lscache.get('sessionUser')
    }
}
