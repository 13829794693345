import {SearchConditionMultiple} from "filter-data/lib/lib/types";
import {SearchType} from "filter-data";

export class SearchCondition implements SearchConditionMultiple {
    constructor(
        private readonly _key: string | string[],
        private readonly _type: SearchType,
        private readonly _value: string | number
    ) {}

    get key(): string | string[] {
        return this._key;
    }

    get type(): SearchType {
        return this._type;
    }

    get value(): string | number {
        return this._value;
    }
}
