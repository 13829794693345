import {createRouter, createWebHistory} from 'vue-router';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import * as lscache from 'lscache';
import {Router} from '@/store/models/Router';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: `/:${Router.catalogMask}?`,
            name: `Home`,
            component: DefaultLayout,
            redirect: `/dashboard`,
            children: [
                {
                    path: `/:${Router.catalogMask}?/dashboard`,
                    name: `Dashboard`,
                    component: () => import(`../pages/Dashboard.vue`)
                },
                {
                    path: `/:${Router.catalogMask}?/user/list`,
                    name: `Users`,
                    component: () => import(`../pages/user/UserList.vue`)
                },
                {
                    path: `/:${Router.catalogMask}?/terminal-type/list`,
                    name: `Terminal types`,
                    component: () => import(`../pages/terminalType/TerminalTypeList.vue`)
                },
                {
                    path: `/:${Router.catalogMask}?/transaction/list`,
                    name: `Transactions`,
                    component: () => import(`../pages/transaction/TransactionList.vue`)
                },
                {
                    path: `/:${Router.catalogMask}?/transaction/list/show/:id`,
                    name: `Transaction information`,
                    component: () => import(`../pages/transaction/ShowTransaction.vue`)
                },
                {
                    path: `/:${Router.catalogMask}?/terminal/list`,
                    name: `Terminals`,
                    component: () => import(`../pages/terminal/TerminalList.vue`)
                },
                {
                    path: `/:${Router.catalogMask}?/bin-range/list`,
                    name: `Bin ranges`,
                    component: () => import(`../pages/binRange/BinRangeList.vue`)
                },
                {
                    path: `/:${Router.catalogMask}?/currency/list`,
                    name: `Currencies`,
                    component: () => import(`../pages/currency/CurrencyList.vue`)
                },
                {
                    path: `/:${Router.catalogMask}?/aid/list`,
                    name: `AID\`s`,
                    component: () => import(`../pages/aid/AidList.vue`)
                },
                {
                    path: `/:${Router.catalogMask}?/aid-key/list`,
                    name: `Keys for AID\`s`,
                    component: () => import(`../pages/aidKey/AidKeyList.vue`)
                },
                {
                    path: `/:${Router.catalogMask}?/aid-limit/list`,
                    name: `AID limits`,
                    component: () => import(`../pages/aidLimit/AidLimitList.vue`)
                },
                {
                    path: `/:${Router.catalogMask}?/card-range/list`,
                    name: `Card ranges`,
                    component: () => import(`../pages/configurationRange/ConfigurationRangeList.vue`)
                },
                {
                    path: `/:${Router.catalogMask}?/limit/list`,
                    name: `Limits`,
                    component: () => import(`../pages/configurationLimit/ConfigurationLimitList.vue`)
                },
                {
                    path: `/:${Router.catalogMask}?/configuration/list`,
                    name: `Configurations`,
                    component: () => import(`../pages/configuration/ConfigurationList.vue`)
                },
                {
                    path: `/:${Router.catalogMask}?/user/list/add`,
                    name: `Add user`,
                    component: () => import(`../pages/user/AddUser.vue`)
                },
                {
                    path: `/:${Router.catalogMask}?/user/list/edit/:id`,
                    name: `Edit user`,
                    component: () => import(`../pages/user/EditUser.vue`)
                },
                {
                    path: `/:${Router.catalogMask}?/user/list/show/:id`,
                    name: `User information`,
                    component: () => import(`../pages/user/ShowUser.vue`)
                },
                {
                    path: `/:${Router.catalogMask}?/terminal-type/list/add`,
                    name: `Add terminal type`,
                    component: () => import(`../pages/terminalType/AddTerminalType.vue`)
                },
                {
                    path: `/:${Router.catalogMask}?/terminal-type/list/edit/:id`,
                    name: `Edit terminal type`,
                    component: () => import(`../pages/terminalType/EditTerminalType.vue`)
                },
                {
                    path: `/:${Router.catalogMask}?/terminal-type/list/show/:id`,
                    name: `Terminal type information`,
                    component: () => import(`../pages/terminalType/ShowTerminalType.vue`)
                },
                {
                    path: `/:${Router.catalogMask}?/terminal/list/add`,
                    name: `Add terminal`,
                    component: () => import(`../pages/terminal/AddTerminal.vue`)
                },
                {
                    path: `/:${Router.catalogMask}?/terminal/list/edit/:id`,
                    name: `Edit terminal`,
                    component: () => import(`../pages/terminal/EditTerminal.vue`)
                },
                {
                    path: `/:${Router.catalogMask}?/terminal/list/show/:id`,
                    name: `Terminal information`,
                    component: () => import(`../pages/terminal/ShowTerminal.vue`)
                },
                {
                    path: `/:${Router.catalogMask}?/terminal/list/permission/list/:id`,
                    name: `Terminal permissions`,
                    component: () => import(`../pages/terminal/permissions/EditPermissions.vue`)
                },
                {
                    path: `/:${Router.catalogMask}?/bin-range/list/add`,
                    name: `Add bin range`,
                    component: () => import(`../pages/binRange/AddBinRange.vue`)
                },
                {
                    path: `/:${Router.catalogMask}?/bin-range/list/edit/:id`,
                    name: `Edit bin range`,
                    component: () => import(`../pages/binRange/EditBinRange.vue`)
                },
                {
                    path: `/:${Router.catalogMask}?/currency/list/add`,
                    name: `Add currency`,
                    component: () => import(`../pages/currency/AddCurrency.vue`)
                },
                {
                    path: `/:${Router.catalogMask}?/currency/list/edit/:id`,
                    name: `Edit currency`,
                    component: () => import(`../pages/currency/EditCurrency.vue`)
                },
                {
                    path: `/:${Router.catalogMask}?/currency/list/show/:id`,
                    name: `Currency information`,
                    component: () => import(`../pages/currency/ShowCurrency.vue`)
                },
                {
                    path: `/:${Router.catalogMask}?/aid/list/add`,
                    name: `Add AID`,
                    component: () => import(`../pages/aid/AddAid.vue`)
                },
                {
                    path: `/:${Router.catalogMask}?/aid/list/edit/:id`,
                    name: `Edit AID`,
                    component: () => import(`../pages/aid/EditAid.vue`)
                },
                {
                    path: `/:${Router.catalogMask}?/aid/list/show/:id`,
                    name: `AID information`,
                    component: () => import(`../pages/aid/ShowAid.vue`)
                },
                {
                    path: `/:${Router.catalogMask}?/aid-key/list/add`,
                    name: `Add AID key`,
                    component: () => import(`../pages/aidKey/AddAidKey.vue`)
                },
                {
                    path: `/:${Router.catalogMask}?/aid-key/list/edit/:id`,
                    name: `Edit AID key`,
                    component: () => import(`../pages/aidKey/EditAidKey.vue`)
                },
                {
                    path: `/:${Router.catalogMask}?/aid-key/list/show/:id`,
                    name: `AID key information`,
                    component: () => import(`../pages/aidKey/ShowAidKey.vue`)
                },
                {
                    path: `/:${Router.catalogMask}?/aid-limit/list/add`,
                    name: `Add AID limit`,
                    component: () => import(`../pages/aidLimit/AddAidLimit.vue`)
                },
                {
                    path: `/:${Router.catalogMask}?/aid-limit/list/edit/:id`,
                    name: `Edit AID limit`,
                    component: () => import(`../pages/aidLimit/EditAidLimit.vue`)
                },
                {
                    path: `/:${Router.catalogMask}?/aid-limit/list/show/:id`,
                    name: `AID limit information`,
                    component: () => import(`../pages/aidLimit/ShowAidLimit.vue`)
                },
                {
                    path: `/:${Router.catalogMask}?/card-range/list/add`,
                    name: `Add card range`,
                    component: () => import(`../pages/configurationRange/AddConfigurationRange.vue`)
                },
                {
                    path: `/:${Router.catalogMask}?/card-range/list/edit/:id`,
                    name: `Edit card range`,
                    component: () => import(`../pages/configurationRange/EditConfigurationRange.vue`)
                },
                {
                    path: `/:${Router.catalogMask}?/card-range/list/show/:id`,
                    name: `Card range information`,
                    component: () => import(`../pages/configurationRange/ShowConfigurationRange.vue`)
                },
                {
                    path: `/:${Router.catalogMask}?/limit/list/add`,
                    name: `Add limit`,
                    component: () => import(`../pages/configurationLimit/AddConfigurationLimit.vue`)
                },
                {
                    path: `/:${Router.catalogMask}?/limit/list/edit/:id`,
                    name: `Edit limit`,
                    component: () => import(`../pages/configurationLimit/EditConfigurationLimit.vue`)
                },
                {
                    path: `/:${Router.catalogMask}?/limit/list/show/:id`,
                    name: `Limit information`,
                    component: () => import(`../pages/configurationLimit/ShowConfigurationLimit.vue`)
                },
                {
                    path: `/:${Router.catalogMask}?/configuration/list/add`,
                    name: `Add configuration`,
                    component: () => import(`../pages/configuration/AddConfiguration.vue`)
                },
                {
                    path: `/:${Router.catalogMask}?/configuration/list/edit/:id`,
                    name: `Edit configuration`,
                    component: () => import(`../pages/configuration/EditConfiguration.vue`)
                },
                {
                    path: `/:${Router.catalogMask}?/configuration/list/show/:id`,
                    name: `Configuration information`,
                    component: () => import(`../pages/configuration/ShowConfiguration.vue`)
                },
                {
                    path: `/:${Router.catalogMask}?/auto-reconciliation-group/list`,
                    name: 'Auto reconciliation groups',
                    component: () => import('../pages/autoReconciliationGroup/AutoReconciliationGroupList.vue')
                },
                {
                    path: `/:${Router.catalogMask}?/auto-reconciliation-group/list/edit/:id`,
                    name: 'Edit auto reconciliation group',
                    component: () => import('../pages/autoReconciliationGroup/EditAutoReconciliationGroup.vue')
                },
                {
                    path: `/:${Router.catalogMask}?/auto-reconciliation-group/list/add`,
                    name: 'Add auto reconciliation group',
                    component: () => import('../pages/autoReconciliationGroup/AddAutoReconciliationGroup.vue')
                },
                {
                    path: `/:${Router.catalogMask}?/merchant/list`,
                    name: 'Merchants',
                    component: () => import('../pages/merchant/MerchantList.vue')
                },
                {
                    path: `/:${Router.catalogMask}?/merchant/list/add`,
                    name: 'Add merchant',
                    component: () => import('../pages/merchant/AddMerchant.vue')
                },
                {
                    path: `/:${Router.catalogMask}?/merchant/list/edit/:id`,
                    name: 'Edit merchant',
                    component: () => import('../pages/merchant/EditMerchant.vue')
                },
                {
                    path: `/:${Router.catalogMask}?/merchant/list/show/:id`,
                    name: 'Merchant information',
                    component: () => import('../pages/merchant/ShowMerchant.vue')
                },
                {
                    path: `/:${Router.catalogMask}?/financial-institute/list`,
                    name: 'Financial institutes',
                    component: () => import('../pages/financialInstitute/FinancialInstituteList.vue')
                },
                {
                    path: `/:${Router.catalogMask}?/financial-institute/list/add`,
                    name: 'Add financial institute',
                    component: () => import('../pages/financialInstitute/AddFinancialInstitute.vue')
                },
                {
                    path: `/:${Router.catalogMask}?/financial-institute/list/edit/:id`,
                    name: 'Edit financial institute',
                    component: () => import('../pages/financialInstitute/EditFinancialInstitute.vue')
                },
                {
                    path: `/:${Router.catalogMask}?/financial-institute/list/show/:id`,
                    name: 'Financial institute information',
                    component: () => import('../pages/financialInstitute/ShowFinancialInstitute.vue')
                },
                {
                    path: `/:${Router.catalogMask}?/payment-instrument/list`,
                    name: 'Payment instruments',
                    component: () => import('../pages/paymentInstrument/PaymentInstrumentList.vue')
                },
                {
                    path: `/:${Router.catalogMask}?/payment-instrument/list/edit/:id`,
                    name: 'Edit payment instrument',
                    component: () => import('../pages/paymentInstrument/EditPaymentInstrument.vue')
                },
                {
                    path: `/:${Router.catalogMask}?/payment-instrument/list/show/:id`,
                    name: 'Payment instrument information',
                    component: () => import('../pages/paymentInstrument/ShowPaymentInstrument.vue')
                },
                {
                    path: `/:${Router.catalogMask}?/operation/list`,
                    name: 'Operations',
                    component: () => import('../pages/operation/OperationList.vue')
                },
                {
                    path: `/:${Router.catalogMask}?/operation/list/edit/:id`,
                    name: 'Edit operation',
                    component: () => import('../pages/operation/EditOperation.vue')
                },
                {
                    path: `/:${Router.catalogMask}?/operation/list/show/:id`,
                    name: 'Operation information',
                    component: () => import('../pages/operation/ShowOperation.vue')
                },
                {
                    path: `/:${Router.catalogMask}?/settings/:id?`,
                    name: 'Settings',
                    component: () => import(`@/pages/settings/Settings.vue`),
                },
            ]
        },
        {
            path: `/:${Router.catalogMask}?/login`,
            name: `login`,
            component: () => import(`../pages/Login.vue`),
        },
        {
            path: `/:${Router.catalogMask}?/forgot-password`,
            name: `forgot-password`,
            component: () => import(`../pages/ForgotPassword.vue`)
        },
        {
            path: `/:${Router.catalogMask}?/change-password`,
            name: `change-password`,
            component: () => import(`../pages/ChangePassword.vue`)
        },
        {
            path: `/:${Router.catalogMask}?/not-found`,
            name: `not-found`,
            component: () => import(`../pages/Page404.vue`),
        },
        {
            path: "/:catchAll(.*)",
            redirect: `/not-found`
        }
    ]
});

router.beforeEach((to, from, next) => {
    const path = window.location.pathname.split(`/`).find(route => route) || ``;

    if (to.name === `change-password`) {
        next();
    } else if (to.name !== `login` && from.name !== `login` && !lscache.get(`isAuth`)) {
        next({name: `login`});
    } else if (lscache.get('sessionUser') && lscache.get('sessionUser').financialInstituteId &&
        String(to.name).includes('institute') &&
        !String(to.name).includes('setting')) {

        next({name: 'Dashboard'});
    } else if (!to.path.includes(path) && !Router.pageList.includes(path)) {
        const fullPath = to.path.replace(`.`, ``);

        next({path: `/${path}${fullPath}`, query: to.query});
    } else {
        next();
    }
});

export default router;
