import {ActionContext} from "vuex";
import {State} from "@/store";
import {SelectOption} from "@/logic/select/SelectOption";
import TerminalStoredModel from "@/store/models/stored/TerminalStoredModel";
import axios from "@/http-common";
import {AxiosError, AxiosResponse} from "axios";
import TerminalFormModel from "@/store/models/forms/TerminalFormModel";
import TerminalRequestModel from "@/store/models/request/TerminalRequestModel";
import {TerminalTable} from "@/logic/table/TerminalTable";
import {TableFilter} from "@/logic/table/TableFilter";
import router from "@/router";

export class TerminalState {
    constructor(
        private _terminalList: Array<TerminalStoredModel> = [],
        private _filteredTerminalList: TerminalTable = new TerminalTable([], TableFilter.getFilterObject('terminal')),
        private _terminalListIsLoading: boolean = true
    ) {}

    public get terminalList(): Array<TerminalStoredModel> {
        return this._terminalList;
    }

    public set terminalList(terminalList: Array<TerminalStoredModel>) {
        this._terminalList = terminalList;
    }

    get filteredTerminalList(): TerminalTable {
        return this._filteredTerminalList;
    }

    set filteredTerminalList(terminalList: TerminalTable) {
        this._filteredTerminalList = terminalList;
    }

    get terminalListIsLoading(): boolean {
        return this._terminalListIsLoading;
    }

    set terminalListIsLoading(loadingStatus: boolean) {
        this._terminalListIsLoading = loadingStatus;
    }
}

type Context = ActionContext<TerminalState, State>;

export default {
    namespaced: true,

    state: new TerminalState(),

    mutations: {
        list(state: TerminalState, terminalList: Array<TerminalStoredModel>): void {
            state.terminalList = terminalList
                .map((terminal: TerminalStoredModel) => new TerminalStoredModel(terminal))
                .sort((terminalA, terminalB) => terminalA.id > terminalB.id ? -1 : 1);
        },

        filteredList(state: TerminalState): void {
          state.filteredTerminalList = new TerminalTable(state.terminalList, TableFilter.getFilterObject('terminal'));
        },

        filterOptions(state: TerminalState, filterOptions: TableFilter): void {
            state.filteredTerminalList.filterOptions = new TableFilter(filterOptions);
            TableFilter.setFilterObject('terminal', filterOptions);
        },

        add(state: TerminalState, terminal: TerminalStoredModel): void {
            state.terminalList.push(new TerminalStoredModel(terminal));
        },

        update(state: TerminalState, updateTerminal: TerminalStoredModel): void {
            state.terminalList = state.terminalList
                .map(
                    terminal => terminal.id === updateTerminal.id
                    ? new TerminalStoredModel(updateTerminal)
                    : terminal
                );

            state.filteredTerminalList.dataList = state.terminalList;
        },

        delete(state: TerminalState, deletedId: number): void {
            state.terminalList = state.terminalList.filter(terminal => terminal.id !== deletedId);
            state.filteredTerminalList.dataList = state.terminalList;
        }
    },

    actions: {
        list(context: Context): Promise<void> {
            return axios.get('/api/terminal/list')
                .then((response: AxiosResponse) => {
                    context.commit('list', response.data);
                    context.commit('filteredList', TableFilter.getFilterObject('terminal'));
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}))
                .finally(() => context.state.terminalListIsLoading = false);
        },

        getById(context: Context, id: number): Promise<TerminalStoredModel> {
            return axios.get(`/api/terminal/${id}`)
                .then((response: AxiosResponse) => new TerminalStoredModel(response.data))
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}))
        },

        add(context: Context, terminalForm: TerminalFormModel): Promise<void> {
            return axios.post('/api/terminal/', new TerminalRequestModel(terminalForm))
                .then((response: AxiosResponse) => {
                    context.commit('add', new TerminalStoredModel(response.data));
                    context.dispatch('alert/success', 'Terminal created', {root: true});
                    router.push({path: `/terminal/list`});
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        },

        update(context: Context, terminalForm: TerminalFormModel): Promise<void> {
            return axios.put('/api/terminal/', new TerminalRequestModel(terminalForm))
                .then((response: AxiosResponse) => {
                    context.commit('update', new TerminalStoredModel(response.data));
                    context.dispatch('alert/success', 'Terminal updated', {root: true});
                    router.push({path: `/terminal/list`});
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        },

        delete(context: Context, id: number): Promise<void> {
            return axios.delete(`/api/terminal/${id}`)
                .then(() => {
                    context.commit('delete', id);
                    context.dispatch('alert/success', 'Terminal deleted', {root: true});
                    router.push({path: `/terminal/list`});
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        },

        changeTerminalStatus(context: Context, terminalForm: TerminalFormModel): Promise<void> {
            terminalForm.active = !terminalForm.active;

            return context.dispatch('update', terminalForm);
        }
    },

    getters: {
        list: (state: TerminalState): Array<TerminalStoredModel> => {
            return state.terminalList;
        },

        byId: (state: TerminalState): (type: number) => TerminalStoredModel | undefined => {
            return (id: number) => state.terminalList.find((terminal: TerminalStoredModel) => id === terminal.id);
        },

        selectEnabledOptionList: (state: TerminalState): Array<SelectOption<number>> => {
            return state.terminalList
                .filter((terminal: TerminalStoredModel) => terminal.status === TerminalStoredModel.ACTIVE)
                .map((terminal: TerminalStoredModel) =>
                    new SelectOption<number>(new TerminalStoredModel(terminal).getSelectName(), terminal.id))
        },

        filteredList: (state: TerminalState): TerminalTable => {
            return state.filteredTerminalList;
        },

        terminalListIsLoading: (state: TerminalState): boolean => {
            return state.terminalListIsLoading;
        }
    },
}
