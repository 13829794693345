import {BaseRequestModel} from "@/store/models/request/BaseRequestModel";
import {OperationFormModel} from "@/store/models/forms/OperationFormModel";

export class OperationRequestModel extends BaseRequestModel {
    private id: number | undefined;
    private name: string;
    private enabled: boolean;
    private description: string;
    private defaultAccess: boolean;


    constructor(operation: OperationFormModel) {
        super(operation);

        this.id = operation.id;
        this.name = operation.name;
        this.enabled = operation.enabled;
        this.description = operation.description;
        this.defaultAccess = operation.defaultAccess;
    }
}
