
import {defineComponent} from "vue";
import {Alert} from "@/store/models/Alert";

export default defineComponent({
  name: "AlertComponent",

  computed: {
    alertList(): Array<Alert> {
      return this.$store.getters['alert/list'];
    }
  },

  data() {
    return {
      Alert: Alert
    }
  },

  methods: {
    closeAlert(id: number) {
      this.$nextTick(() => {
        const alertNode = document.querySelector(`#alert${id}`);

        if (alertNode) {
          alertNode.classList.add('removed');
        }

        setTimeout(() => this.$store.commit('alert/remove', id), 300);
      })
    }
  },

  updated() {
    this.alertList.filter(alert => !alert.isDeleteAfterTimeout).forEach(alert => {
      alert.isDeleteAfterTimeout = true;
      setTimeout(() => this.closeAlert(alert.id), 1000 * alert.displayTime);
    });
  },
})
