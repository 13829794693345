import {TableFilter} from "@/logic/table/TableFilter";

export class HeaderCell {
    public static UNSORTABLE = 0;
    public static SORTABLE = 1;
    public static ASC = 2;
    public static DESC = 3;

    constructor(
       private readonly _name: string = '',
       private readonly _value: string = '',
       private _sortableType: number = HeaderCell.SORTABLE,
       private readonly _hasDescription: boolean = false,
       private readonly _required: boolean = true,
       private _width: string = '100%',
       public permissionCondition?: (ctx: unknown) => boolean
    ) {}

    get name(): string {
        return this._name;
    }

    get value(): string {
        return this._value;
    }

    get required(): boolean {
        return this._required;
    }

    get sortableType(): number {
        return this._sortableType;
    }

    set sortableType(value: number) {
        this._sortableType = value;
    }

    get hasDescription(): boolean {
        return this._hasDescription;
    }

    get width(): string {
        return this._width;
    }

    public changeSortType(): void {
        if (this._sortableType !== HeaderCell.UNSORTABLE) {
            this._sortableType = this._sortableType === HeaderCell.ASC ? HeaderCell.DESC : HeaderCell.ASC;
        }
    }

    public resetSort(): void {
        if (this._sortableType !== HeaderCell.UNSORTABLE) {
            this._sortableType = HeaderCell.SORTABLE;
        }
    }

    public static changeSortTypeInList(filterTable: TableFilter, headerList: Array<HeaderCell>): Array<HeaderCell> {
        if (filterTable.sortField && filterTable.sortType) {
            headerList.forEach((header: HeaderCell) => {
                if (header.value === filterTable.sortField) {
                    header.sortableType = filterTable.sortType;
                } else {
                    header.resetSort();
                }
            });
        }

        return headerList;
    }
}
