import {BaseStoredModel} from "@/store/models/stored/BaseStoredModel";

export class ConfigurationLimitStoredModel extends BaseStoredModel {
    private readonly _id: number;
    private readonly _cvmLimit: number;
    private readonly _letterCode: string;
    private readonly _numberCode: string;
    private readonly _currencyId: number;
    private readonly _contactlessFloor: number;
    private readonly _contactlessLimit: number;
    private readonly _odcvmTransactionLimit: number;
    private readonly _terminalTransactionLimit: number;

    constructor(configurationLimit: ConfigurationLimitStoredModel) {
        super(configurationLimit);

        this._id = configurationLimit.id;
        this._cvmLimit = configurationLimit.cvmLimit;
        this._letterCode = configurationLimit.letterCode;
        this._numberCode = configurationLimit.numberCode;
        this._currencyId = configurationLimit.currencyId;
        this._contactlessFloor = configurationLimit.contactlessFloor;
        this._contactlessLimit = configurationLimit.contactlessLimit;
        this._odcvmTransactionLimit = configurationLimit.odcvmTransactionLimit;
        this._terminalTransactionLimit = configurationLimit.terminalTransactionLimit;
    }

    get id(): number {
        return this._id;
    }

    get cvmLimit(): number {
        return this._cvmLimit;
    }

    get letterCode(): string {
        return this._letterCode;
    }

    get numberCode(): string {
        return this._numberCode;
    }

    get currencyId(): number {
        return this._currencyId;
    }

    get contactlessFloor(): number {
        return this._contactlessFloor;
    }

    get contactlessLimit(): number {
        return this._contactlessLimit;
    }

    get odcvmTransactionLimit(): number {
        return this._odcvmTransactionLimit;
    }

    get terminalTransactionLimit(): number {
        return this._terminalTransactionLimit;
    }

    public getName(): string {
        let configurationLimitName = ''

        if (this._numberCode) {
            configurationLimitName += `CUR: ${this._numberCode} `
        }

        if (this._contactlessLimit) {
            configurationLimitName += `CTL: ${this._contactlessLimit} `
        }

        if (this._cvmLimit) {
            configurationLimitName += `CVMRL: ${this._cvmLimit} `
        }

        if (this._contactlessFloor) {
            configurationLimitName += `CFL: ${this._contactlessFloor} `
        }

        if (this._terminalTransactionLimit) {
            configurationLimitName += `TTL: ${this._terminalTransactionLimit} `
        }

        return configurationLimitName;
    }
}
