import lscache from "lscache";

export class TableFilter {

    private _itemsPerPage: number;
    private _pageNumber: number;
    private _searchValue: string;
    private _sortField: string;
    private _sortType: number;

    constructor(tableFilter = {} as TableFilter) {
        this._itemsPerPage = tableFilter.itemsPerPage || 10;
        this._pageNumber = tableFilter.pageNumber || 1;
        this._searchValue = tableFilter.searchValue || '';
        this._sortField = tableFilter.sortField || '';
        this._sortType = tableFilter.sortType || 0;
    }

    get itemsPerPage(): number {
        return this._itemsPerPage;
    }

    set itemsPerPage(value: number) {
        this._itemsPerPage = value;
    }

    get pageNumber(): number {
        return this._pageNumber;
    }

    set pageNumber(value: number) {
        this._pageNumber = value;
    }

    get searchValue(): string {
        return this._searchValue;
    }

    set searchValue(value: string) {
        this._searchValue = value;
    }

    get sortField(): string {
        return this._sortField;
    }

    set sortField(value: string) {
        this._sortField = value;
    }

    get sortType(): number {
        return this._sortType;
    }

    set sortType(value: number) {
        this._sortType = value;
    }

    public static toJSONObject(tableFilter: TableFilter) {
        return {
            itemsPerPage: tableFilter.itemsPerPage,
            pageNumber: tableFilter.pageNumber,
            searchValue: tableFilter.searchValue,
            sortField: tableFilter.sortField,
            sortType: tableFilter.sortType,
        }
    }

    public static getFilterObject(entity: string) {
        const filter = lscache.get('filter');

        if (filter) {
            return new TableFilter(filter[entity]);
        } else {
            lscache.set('filter', {});
            return new TableFilter();
        }
    }

    public static setFilterObject(entity: string, tableFilter: TableFilter) {
        const filter = lscache.get('filter') || {};

        filter[entity] = TableFilter.toJSONObject(tableFilter);
        lscache.set('filter', filter);
    }
}
