import {TableFilter} from "@/logic/table/TableFilter";
import _ from "lodash";
import {HeaderCell} from "@/logic/table/HeaderCell";
import {filterData} from "filter-data";
import {SearchConditionMultiple} from "filter-data/lib/lib/types";

export abstract class Table<T> {
    protected _dataList: Array<T> = [];
    public filterOptions: TableFilter;

    constructor(dataList: Array<T>, filterOptions = new TableFilter()) {
        this._dataList = dataList;
        this.filterOptions = filterOptions;
    }

    get dataList(): Array<T> {
        let dataList = this._dataList.slice();

        if (this.filterOptions.searchValue) {
            dataList = this.filterBySearch(dataList);
        }

        if (this.filterOptions.sortType && this.filterOptions.sortField) {
            dataList = this.sort(dataList);
        }

        if (this.filterOptions.pageNumber) {
            dataList = this.filterByPage(dataList);
        }

        if (this.filterOptions.itemsPerPage) {
            dataList = this.filterByRowCount(dataList);
        }

        return dataList;
    }

    set dataList(dataList: Array<T>) {
        this._dataList = dataList;
    }

    public sort(dataList: Array<T>): Array<T> {
        return _.orderBy(
            dataList,
            [this.filterOptions.sortField],
            [this.filterOptions.sortType === HeaderCell.ASC ? 'asc' : 'desc']
        );
    }

    public filterBySearch(dataList: Array<T>): Array<T> {
        return filterData(dataList, this.filterConfigurationList());
    }

    abstract filterConfigurationList(): Array<SearchConditionMultiple>;

    public filterByPage(dataList: Array<T>): Array<T> {
        return dataList.slice(
            this.filterOptions.itemsPerPage * (this.filterOptions.pageNumber - 1),
            this.filterOptions.itemsPerPage * (this.filterOptions.pageNumber - 1) + this.filterOptions.itemsPerPage
        );
    }

    public filterByRowCount(dataList: Array<T>): Array<T> {
        return dataList.slice(0, this.filterOptions.itemsPerPage);
    }

    public pageCount(): number {
        const data = this.filterBySearch(this._dataList);

        return Math.ceil(data.length / this.filterOptions.itemsPerPage);
    }

    abstract get headers(): Array<HeaderCell>;
}

