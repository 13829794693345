import {IPermittedOperation} from "@/store/models/permission/IPermittedOperation";

export default class TerminalPermissionStoredModel {
    private readonly _paymentInstrumentId: number;
    private readonly _paymentInstrumentName: string;
    private readonly _paymentInstrumentType: string;
    private readonly _permittedOperations: Array<IPermittedOperation>;

    constructor(terminalPermissions: TerminalPermissionStoredModel) {
        this._paymentInstrumentId = terminalPermissions.paymentInstrumentId;
        this._paymentInstrumentName = terminalPermissions.paymentInstrumentName;
        this._paymentInstrumentType = terminalPermissions.paymentInstrumentType;
        this._permittedOperations = terminalPermissions.permittedOperations;
    }

    get paymentInstrumentId(): number {
        return this._paymentInstrumentId;
    }

    get paymentInstrumentName(): string {
        return this._paymentInstrumentName;
    }

    get paymentInstrumentType(): string {
        return this._paymentInstrumentType;
    }

    get permittedOperations(): Array<IPermittedOperation> {
        return this._permittedOperations;
    }
}
