import {Table} from "@/logic/table/Table";
import {AidStoredModel} from "@/store/models/stored/AidStoredModel";
import {SearchConditionMultiple} from "filter-data/lib/lib/types";
import {HeaderCell} from "@/logic/table/HeaderCell";
import {SearchCondition} from "@/logic/table/SearchCondition";
import {SearchType} from "filter-data";
import {TableFilter} from "@/logic/table/TableFilter";
import {Utils} from "@/logic/Utils";

export class AidTable extends Table<AidStoredModel> {
    filterConfigurationList(): Array<SearchConditionMultiple> {
        return [
            new SearchCondition(
                [
                    'id',
                    'rid',
                    'pix',
                    'type',
                    'tav',
                    'lav',
                    'priorityIndex',
                    'selectionFlags',
                    'tacDefault',
                    'tacDenial',
                    'tacOnline',
                    'tc',
                    'tcForRefund',
                    'financialInstitute'
                ],
                SearchType.LK,
                this.filterOptions.searchValue
            )
        ];
    }

    get headers(): Array<HeaderCell> {
        return HeaderCell.changeSortTypeInList(
            TableFilter.getFilterObject('aid'),
            [
                new HeaderCell('Id', 'id', HeaderCell.DESC),
                new HeaderCell('RID', 'rid', HeaderCell.SORTABLE, true),
                new HeaderCell('PIX', 'pix', HeaderCell.SORTABLE, true),
                new HeaderCell('Type', 'type'),
                new HeaderCell('TAV', 'tav', HeaderCell.SORTABLE, true),
                new HeaderCell('LAV', 'lav', HeaderCell.SORTABLE, true),
                new HeaderCell('PIDX', 'priorityIndex', HeaderCell.SORTABLE, true),
                new HeaderCell('ASF', 'selectionFlags', HeaderCell.SORTABLE, true),
                new HeaderCell('TAC Default', 'tacDefault', HeaderCell.SORTABLE, true),
                new HeaderCell('TAC Denial', 'tacDenial', HeaderCell.SORTABLE, true),
                new HeaderCell('TAC Online', 'tacOnline', HeaderCell.SORTABLE, true, false),
                new HeaderCell('TC All', 'tc', HeaderCell.SORTABLE, true, false),
                new HeaderCell('TC Refund', 'tcForRefund', HeaderCell.SORTABLE, true, false),
                new HeaderCell(
                    'Financial institute',
                    'financialInstitute',
                    HeaderCell.SORTABLE,
                    false,
                    true,
                    '200px',
                    () => Utils.isSuperAdmin()
                ),
                new HeaderCell('', 'actions', HeaderCell.UNSORTABLE),
            ],
        )
    }
}
