
import { defineComponent, h, onMounted, ref } from 'vue';
import { CSidebarNav, CNavItem, CNavGroup, CNavTitle } from '@coreui/vue';
import sidebarComponents from '@/logic/sidebar/sidebarComponents';
import { renderItem } from '@/logic/sidebar/sidebarUtils';

export default defineComponent({
  name: 'AppSidebarNav',

  components: {
    CNavItem,
    CNavGroup,
    CNavTitle,
  },

  setup: function () {
    const firstRender = ref(true);

    onMounted(() => {
      firstRender.value = false;
    });

    return () => h(CSidebarNav, {}, { default: () => sidebarComponents.map((item) => renderItem(item)) });
  },
})
