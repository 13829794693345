import {TerminalsByPeriod} from "@/store/models/statistic/TerminalsByPeriod";
import {BasePeriodModel} from "@/store/models/statistic/BasePeriodModel";

export class TransactionsTerminalStatistic extends BasePeriodModel {
    readonly terminalsByPeriod: Array<TerminalsByPeriod>;

    constructor(statistic?: TransactionsTerminalStatistic) {
        super(statistic?.startPeriod ? statistic.startPeriod : "", statistic?.endPeriod ? statistic.endPeriod : "");
        this.terminalsByPeriod = statistic?.terminalsByPeriod ? statistic.terminalsByPeriod : [];
    }
}
