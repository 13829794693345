import {BaseStoredModel} from "@/store/models/stored/BaseStoredModel";

export class PaymentInstrumentStoredModel extends BaseStoredModel {

    private readonly _id: number;
    private readonly _type: string;
    private readonly _name: string;
    private readonly _available: boolean;
    private readonly _enabled: boolean;
    private readonly _description: string;
    private readonly _defaultAccess: boolean;

    constructor(paymentInstrument: PaymentInstrumentStoredModel) {
        super(paymentInstrument);

        this._id = paymentInstrument.id;
        this._type = paymentInstrument.type;
        this._name = paymentInstrument.name;
        this._available = paymentInstrument.available;
        this._enabled = paymentInstrument.enabled;
        this._description = paymentInstrument.description;
        this._defaultAccess = paymentInstrument.defaultAccess;
    }

    get id(): number {
        return this._id;
    }

    get available(): boolean {
        return this._available;
    }

    get enabled(): boolean {
        return this._enabled;
    }

    get description(): string {
        return this._description;
    }

    get defaultAccess(): boolean {
        return this._defaultAccess;
    }

    get type(): string {
        return this._type;
    }

    get name(): string {
        return this._name;
    }
}
