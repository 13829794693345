import {BinRangeFormModel} from "@/store/models/forms/BinRangeFormModel";
import {BaseRequestModel} from "@/store/models/request/BaseRequestModel";

export class BinRangeRequestModel extends BaseRequestModel {
    private id: number | undefined;
    private paymentSystem: string;
    private start: string;
    private end: string;

    constructor(binRangeForm: BinRangeFormModel) {
        super(binRangeForm)

        this.id = binRangeForm.id;
        this.paymentSystem = binRangeForm.paymentSystem;
        this.start = binRangeForm.start;
        this.end = binRangeForm.end;
    }
}
