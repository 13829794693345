import {HeaderCell} from "@/logic/table/HeaderCell";
import {SearchType} from "filter-data";
import {AutoReconciliationGroupStoredModel} from "@/store/models/stored/AutoReconciliationGroupStoredModel";
import {Table} from "@/logic/table/Table";
import {SearchConditionMultiple} from "filter-data/lib/lib/types";
import {SearchCondition} from "@/logic/table/SearchCondition";
import {TableFilter} from "@/logic/table/TableFilter";
import {Utils} from "@/logic/Utils";

export class AutoReconciliationGroupTable extends Table<AutoReconciliationGroupStoredModel> {
    get headers(): Array<HeaderCell> {
        return HeaderCell.changeSortTypeInList(
            TableFilter.getFilterObject('autoReconciliationGroup'),
            [
                new HeaderCell('Id', 'id', HeaderCell.DESC),
                new HeaderCell('Name', 'name'),
                new HeaderCell('Time', 'time'),
                new HeaderCell(
                    'Financial institute',
                    'financialInstitute',
                    HeaderCell.SORTABLE,
                    false,
                    true,
                    '200px',
                    () => Utils.isSuperAdmin()
                ),
                new HeaderCell('', 'actions', HeaderCell.UNSORTABLE)
            ]
        );
    }

    filterConfigurationList(): Array<SearchConditionMultiple> {
        return [
            new SearchCondition(
                ['id', 'name', 'time', 'financialInstitute'],
                SearchType.LK,
                this.filterOptions.searchValue
            )
        ];
    }
}
