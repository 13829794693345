import {BaseStoredModel} from "@/store/models/stored/BaseStoredModel";

export default class UserStoredModel extends BaseStoredModel {

  public static ACTIVE = 0;
  public static AUTO_BLOCKED = 1;
  public static BLOCKED = 2;
  public static DELETED = 3;

  private readonly _id: number;
  private readonly _fullName: string;
  private readonly _login: string;
  private readonly _email: string;
  private readonly _phone: string;
  private readonly _status: number;
  private readonly _creationDate: Date | undefined;
  private readonly _creator: string | undefined;


  constructor(user: UserStoredModel) {
    super(user);

    this._id = user.id;
    this._fullName = user.fullName;
    this._login = user.login;
    this._email = user.email;
    this._phone = user.phone;
    this._status = user.status;
    this._creationDate = user.creationDate;
    this._creator = user.creator;
  }

  get id(): number {
    return this._id;
  }

  get login(): string {
    return this._login;
  }

  get email(): string {
    return this._email;
  }

  get phone(): string {
    return this._phone;
  }

  get fullName(): string {
    return this._fullName;
  }

  get status(): number {
    return this._status;
  }

  get creationDate(): Date | undefined {
    return this._creationDate;
  }

  get creator(): string | undefined {
    return this._creator;
  }
}
