import {HeaderCell} from "@/logic/table/HeaderCell";
import {SearchType} from "filter-data";
import {FinancialInstituteStoredModel} from "@/store/models/stored/FinancialInstituteStoredModel";
import {Table} from "@/logic/table/Table";
import {SearchConditionMultiple} from "filter-data/lib/lib/types";
import {SearchCondition} from "@/logic/table/SearchCondition";
import {TableFilter} from "@/logic/table/TableFilter";

export class FinancialInstituteTable extends Table<FinancialInstituteStoredModel> {
    get headers(): Array<HeaderCell> {
        return HeaderCell.changeSortTypeInList(
            TableFilter.getFilterObject('financialInstitute'),
            [
                new HeaderCell('Id', 'id', HeaderCell.DESC),
                new HeaderCell('Name', 'name', HeaderCell.SORTABLE, false, true, '200px'),
                new HeaderCell('Host type', 'hostType'),
                new HeaderCell('Host', 'host', HeaderCell.SORTABLE, false, true, '200px'),
                new HeaderCell('Real address', 'realAddress', HeaderCell.SORTABLE, false, true, '200px'),
                new HeaderCell('Legal address', 'legalAddress', HeaderCell.SORTABLE, false, false, '200px'),
                new HeaderCell('Mac', 'macEnabled', HeaderCell.SORTABLE, false, false,),
                new HeaderCell('Encryption', 'encryptionEnabled'),
                new HeaderCell('', 'actions', HeaderCell.UNSORTABLE)
            ]
        );
    }

    filterConfigurationList(): Array<SearchConditionMultiple> {
        return [
            new SearchCondition(
                [
                    'id',
                    'name',
                    'hostType',
                    'hostAddress',
                    'hostPort',
                    'realAddress',
                    'legalAddress',
                    'macEnabled',
                    'encryptionEnabled'
                ],
                SearchType.LK,
                this.filterOptions.searchValue
            )
        ];
    }
}
