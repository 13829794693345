import {ActionContext} from "vuex";
import {State} from "@/store";
import {OperationStoredModel} from "@/store/models/stored/OperationStoredModel";
import axios from "@/http-common";
import {AxiosError, AxiosResponse} from "axios";
import {OperationFormModel} from "@/store/models/forms/OperationFormModel";
import {OperationRequestModel} from "@/store/models/request/OperationRequestModel";
import {OperationTable} from "@/logic/table/OperationTable";
import {TableFilter} from "@/logic/table/TableFilter";
import router from "@/router";

export class OperationState {
    constructor(
        private _operationList: Array<OperationStoredModel> = [],

        private _filteredOperationList: OperationTable =
            new OperationTable([], TableFilter.getFilterObject('operation')),

        private _operationListIsLoading: boolean = true
    ) {}

    public get operationList(): Array<OperationStoredModel> {
        return this._operationList;
    }

    public set operationList(operationList: Array<OperationStoredModel>) {
        this._operationList = operationList;
    }

    get filteredOperationList(): OperationTable {
        return this._filteredOperationList;
    }

    set filteredOperationList(operationList: OperationTable) {
        this._filteredOperationList = operationList;
    }

    get operationListIsLoading(): boolean {
        return this._operationListIsLoading;
    }

    set operationListIsLoading(loadingStatus: boolean) {
        this._operationListIsLoading = loadingStatus;
    }
}

type Context = ActionContext<OperationState, State>;

export default {
    namespaced: true,

    state: new OperationState(),

    mutations: {
        list(state: OperationState, operationList: Array<OperationStoredModel>): void {
            state.operationList = operationList
                .map((operation: OperationStoredModel) => new OperationStoredModel(operation))
                .sort((operationA, operationB) => operationA.id > operationB.id ? -1 : 1);
        },

        filteredList(state: OperationState): void {
            state.filteredOperationList =
                new OperationTable(state.operationList, TableFilter.getFilterObject('operation'));
        },

        filterOptions(state: OperationState, filterOptions: TableFilter): void {
            state.filteredOperationList.filterOptions = new TableFilter(filterOptions);
            TableFilter.setFilterObject('operation', filterOptions);
        },

        update(state: OperationState, updateOperation: OperationStoredModel): void {
            state.operationList = state.operationList
                .map(
                    operation => operation.id === updateOperation.id
                        ? new OperationStoredModel(updateOperation)
                        : operation
                );

            state.filteredOperationList.dataList = state.operationList;
        },
    },

    actions: {
        list(context: Context): Promise<void> {
            return axios.get('/api/operation/list')
                .then((response: AxiosResponse) => {
                    context.commit('list', response.data);
                    context.commit('filteredList', TableFilter.getFilterObject('operation'));
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}))
                .finally(() => context.state.operationListIsLoading = false);
        },

        getById(context: Context, id: number): Promise<OperationStoredModel> {
            return axios.get(`/api/operation/${id}`)
                .then((response: AxiosResponse) => new OperationStoredModel(response.data))
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}))
        },

        update(context: Context, operationForm: OperationFormModel): Promise<void> {
            return axios.put('/api/operation/', new OperationRequestModel(operationForm))
                .then((response: AxiosResponse) => {
                    context.commit('update', new OperationStoredModel(response.data));
                    context.dispatch('alert/success', `Operation ${operationForm.name} updated`, {root: true});
                    router.push({path: `/operation/list`});
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        },
    },

    getters: {
        list: (state: OperationState): Array<OperationStoredModel> => {
            return state.operationList;
        },

        byId: (state: OperationState): (type: number) => OperationStoredModel | undefined => {
            return (id: number) => state.operationList.find((operation: OperationStoredModel) => id === operation.id);
        },

        filteredList: (state: OperationState): OperationTable => {
            return state.filteredOperationList;
        },

        operationListIsLoading: (state: OperationState): boolean => {
            return state.operationListIsLoading;
        }
    },
}
