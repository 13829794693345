export class TerminalModelsStatistic {
    public readonly typeName: string;
    public readonly numberTerminals: number;

    constructor(typeName: string, numberTerminals: number) {
        this.typeName = typeName;
        this.numberTerminals = numberTerminals;
    }
}

export class TerminalWithOperationsStatistic {
    readonly countActive: number;
    readonly countInactive: number;
    readonly countTotal: number;

    constructor(statistic?: TerminalWithOperationsStatistic) {
        if (statistic) {
            this.countActive = statistic.countActive;
            this.countInactive = statistic.countInactive;
            this.countTotal = statistic.countTotal;
        } else {
            this.countActive = 0;
            this.countInactive = 0;
            this.countTotal = 0;
        }
    }
}

export class TerminalVersionsStatistic {
    readonly totalTerminalsCount: number;
    readonly terminalType: string;
    readonly versions: VersionStatistic[];

    constructor(statistic?: TerminalVersionsStatistic) {
        if (statistic) {
            this.totalTerminalsCount = statistic.totalTerminalsCount;
            this.terminalType = statistic.terminalType;
            this.versions =
                statistic.versions.map((version) => new VersionStatistic(version.name, version.terminalsCount));
        } else {
            this.totalTerminalsCount = 0;
            this.terminalType = '';
            this.versions = [];
        }
    }
}

export class VersionStatistic {
    readonly name: string;
    readonly terminalsCount: number;

    constructor(name: string, terminalsCount: number) {
        if (name && terminalsCount) {
            this.name = name;
            this.terminalsCount = terminalsCount;
        } else {
            this.name = '';
            this.terminalsCount = 0;
        }
    }
}

export class TerminalOnlineStatistic {
    readonly countOnline: number;
    readonly countOffline: number;

    constructor(statistic?: TerminalOnlineStatistic) {
        if (statistic) {
            this.countOnline = statistic.countOnline;
            this.countOffline = statistic.countOffline;
        } else {
            this.countOnline = 0;
            this.countOffline = 0;
        }
    }
}