import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import CoreuiVue from '@coreui/vue';
import CIcon from '@coreui/icons-vue';
import {iconsSet as icons} from '@/assets/icons';

const app = createApp(App);

app.config.warnHandler = () => null;

app.use(store)
    .use(router)
    .use(CoreuiVue)
    .directive("click-outside", {
        beforeMount(el, binding) {
            el.clickOutsideEvent = function(event: Event) {
                if (!(el === event.target || el.contains(event.target))) {
                    binding.value(event, el);
                }
            };
            document.body.addEventListener('click', el.clickOutsideEvent);
        },
        unmounted(el) {
            document.body.removeEventListener('click', el.clickOutsideEvent);
        }
    })
    .provide('icons', icons)
    .component('CIcon', CIcon)
    .mount('#app');

