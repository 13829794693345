import TerminalStoredModel from "@/store/models/stored/TerminalStoredModel";
import TerminalFormModel from "@/store/models/forms/TerminalFormModel";
import {BaseRequestModel} from "@/store/models/request/BaseRequestModel";

export default class TerminalRequestModel extends BaseRequestModel {

    private id: number | undefined;
    private externalId: string;
    private description: string;
    private status: number;
    private configurationId: number | undefined;
    private autoReconciliationGroupId: number | null;
    private merchantId: number | undefined;
    private address: string;
    private serialNumber: string | undefined;
    private typeId: number | undefined;

    constructor(terminalForm: TerminalFormModel) {
        super(terminalForm);

        this.id = terminalForm.id;
        this.externalId = terminalForm.externalId;
        this.description = terminalForm.description;
        this.status = terminalForm.active ? TerminalStoredModel.ACTIVE : TerminalStoredModel.BLOCKED;
        this.configurationId = terminalForm.configurationId;
        this.autoReconciliationGroupId = terminalForm.autoReconciliationGroupId;
		this.merchantId = terminalForm.merchantId;
        this.address = terminalForm.address;
        this.serialNumber = terminalForm.serialNumber;
        this.typeId = terminalForm.typeId;
    }
}
