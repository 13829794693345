import {UserFormModel} from "@/store/models/forms/UserFormModel";
import {BaseRequestModel} from "@/store/models/request/BaseRequestModel";

export class UserRequestModel extends BaseRequestModel {

    private id: number | undefined;
    private password: string;
    private status: number;
    private login: string;
    private email: string;
    private phone: string;
    private fullName: string;

    constructor(userForm: UserFormModel) {
        super(userForm);

        this.id = userForm.id;
        this.password = userForm.password;
        this.status = userForm.status;
        this.login = userForm.login;
        this.email = userForm.email;
        this.phone = userForm.phone ? userForm.phone.replace('+', '') : '';
        this.fullName = userForm.fullName;
    }
}
