import {BaseRequestModel} from "@/store/models/request/BaseRequestModel";
import {SettingFormModel} from "@/store/models/forms/SettingFormModel";

export class SettingRequestModel extends BaseRequestModel {
    private id: number | undefined;
    private name: string;
    private value: string | number | boolean;
    private type: string;
    private format: string;
    private group: string | undefined;

    constructor(setting: SettingFormModel) {
        super(setting);

        this.id = setting.id;
        this.name = setting.name;
        this.value = setting.value;
        this.type = setting.type;
        this.format = setting.format;
        this.group = setting.group;
    }
}
