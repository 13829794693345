import {ActionContext} from "vuex";
import {State} from "@/store";
import TerminalPermissionStoredModel from "@/store/models/stored/TerminalPermissionStoredModel";
import axios from "@/http-common";
import {AxiosError, AxiosResponse} from "axios";
import TerminalPermissionFormModel from "@/store/models/forms/TerminalPermissionFormModel";
import TerminalPermissionRequestModel from "@/store/models/request/TerminalPermissionRequestModel";

export class TerminalPermissionState {
    constructor(
        private _terminalPermissionList: Array<TerminalPermissionStoredModel> = [],
        private _terminalPermissionListIsLoading: boolean = true
    ) {}

    public get terminalPermissionList(): Array<TerminalPermissionStoredModel> {
        return this._terminalPermissionList;
    }

    public set terminalPermissionList(terminalPermissionList: Array<TerminalPermissionStoredModel>) {
        this._terminalPermissionList = terminalPermissionList;
    }

    get terminalPermissionListIsLoading(): boolean {
        return this._terminalPermissionListIsLoading;
    }

    set terminalPermissionListIsLoading(loadingStatus: boolean) {
        this._terminalPermissionListIsLoading = loadingStatus;
    }
}

type Context = ActionContext<TerminalPermissionState, State>;

export default {
    namespaced: true,

    state: new TerminalPermissionState(),

    mutations: {
        list(state: TerminalPermissionState, terminalPermissionList: Array<TerminalPermissionStoredModel>): void {
            state.terminalPermissionList = terminalPermissionList
                .map((terminalPermission: TerminalPermissionStoredModel) =>
                    new TerminalPermissionStoredModel(terminalPermission))
        },

        updatePermission(state: TerminalPermissionState, terminalPermission: TerminalPermissionStoredModel): void {
            const updatedItemIndex: number = state.terminalPermissionList
                .findIndex(permission => permission.paymentInstrumentId === terminalPermission.paymentInstrumentId);

            state.terminalPermissionList[updatedItemIndex] = new TerminalPermissionStoredModel(terminalPermission);
        },

        showLoading(state: TerminalPermissionState, showLoading: boolean) {
            state.terminalPermissionListIsLoading = showLoading;
        }
    },

    actions: {
        list(context: Context, id: number): Promise<void> {
            context.commit('showLoading', true);

            return axios.get(`/api/terminal-permission/list/${id}`)
                .then((response: AxiosResponse) => context.commit('list', response.data))
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}))
                .finally(() => context.commit('showLoading', false));
        },

        update(context: Context, terminalPermissionForm: TerminalPermissionFormModel): Promise<void> {
            return axios.put('/api/terminal-permission/', new TerminalPermissionRequestModel(terminalPermissionForm))
                .then((response: AxiosResponse) => {
                    const paymentInstrumentName: string = terminalPermissionForm.paymentInstrumentName
                        ? `${terminalPermissionForm.paymentInstrumentName} ${terminalPermissionForm.paymentInstrumentType}`
                        : terminalPermissionForm.paymentInstrumentType;

                    context.dispatch(
                        'alert/success',
                        `Permission ${paymentInstrumentName} for the ${terminalPermissionForm.name} updated`,
                        {root: true}
                    );

                    context.commit('updatePermission', response.data);
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        }
    },

    getters: {
        list: (state: TerminalPermissionState): Array<TerminalPermissionStoredModel> => {
            return state.terminalPermissionList;
        },

        terminalPermissionListIsLoading: (state: TerminalPermissionState): boolean => {
            return state.terminalPermissionListIsLoading;
        }
    },
}
