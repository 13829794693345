import {AutoReconciliationGroupStoredModel} from "@/store/models/stored/AutoReconciliationGroupStoredModel";
import {BaseStoredModel} from "@/store/models/stored/BaseStoredModel";

export default class TerminalStoredModel extends BaseStoredModel {
    public static ACTIVE = 0;
    public static BLOCKED = 2;
    public static DELETED = 3;

    readonly id: number;
    readonly externalId: string;
    readonly description: string;
    readonly status: number;
    readonly configurationId: number;
    readonly configurationName: string;
    readonly creationDate: Date | undefined;
    readonly lastChangeDate: Date | undefined;
    readonly creator: string | undefined;
    readonly autoReconciliationGroupId: number;
    readonly autoReconciliationGroupName: string;
    readonly autoReconciliationGroupTime: string;
    readonly autoReconciliationGroup: AutoReconciliationGroupStoredModel;
    readonly merchantId: number;
    readonly merchantName: string | undefined;
    readonly address: string;
    readonly serialNumber: string;
    readonly typeName: string | undefined;
    readonly typeId: number;

    constructor(terminal: TerminalStoredModel) {
        super(terminal);

        this.id = terminal.id;
        this.externalId = terminal.externalId;
        this.description = terminal.description;
        this.status = terminal.status;
        this.configurationId = terminal.configurationId;
        this.configurationName = terminal.configurationName;
        this.creationDate = terminal.creationDate;
        this.lastChangeDate = terminal.lastChangeDate;
        this.creator = terminal.creator;
        this.autoReconciliationGroupId = terminal.autoReconciliationGroupId;
        this.autoReconciliationGroup = terminal.autoReconciliationGroup;

        this.autoReconciliationGroupName = terminal.autoReconciliationGroup
            ? terminal.autoReconciliationGroup.name
            : '';

        this.autoReconciliationGroupTime = terminal.autoReconciliationGroup
            ? terminal.autoReconciliationGroup.time
            : '';

        this.merchantId = terminal.merchantId;
        this.merchantName = terminal.merchantName;
        this.address = terminal.address;
        this.typeName = terminal.typeName;
        this.serialNumber = terminal.serialNumber;
        this.typeId = terminal.typeId;
    }
}
