import {BaseStoredModel} from "@/store/models/stored/BaseStoredModel";

export class CurrencyStoredModel extends BaseStoredModel {

    public static ENABLED = true;
    public static DISABLED = false;

    public static SYMBOL_POSITION_LEFT = 0;
    public static SYMBOL_POSITION_RIGHT = 1;

    private readonly _numberCode: string;
    private readonly _letterCode: string;
    private readonly _fraction: number;
    private readonly _unitName: string;
    private readonly _subUnitName: string;
    private readonly _symbol: string;
    private readonly _symbolPosition: number;
    private readonly _enabled: boolean;
    private readonly _defaultCurrency: boolean;
    private readonly _id: number;


    constructor(currency: CurrencyStoredModel) {
        super(currency);

        this._numberCode = currency.numberCode;
        this._letterCode = currency.letterCode;
        this._fraction = currency.fraction;
        this._unitName = currency.unitName;
        this._subUnitName = currency.subUnitName;
        this._symbol = currency.symbol;
        this._symbolPosition = currency.symbolPosition;
        this._enabled = currency.enabled;
        this._defaultCurrency = currency.defaultCurrency;
        this._id = currency.id;
    }

    get numberCode(): string {
        return this._numberCode;
    }

    get letterCode(): string {
        return this._letterCode;
    }

    get fraction(): number {
        return this._fraction;
    }

    get unitName(): string {
        return this._unitName;
    }

    get subUnitName(): string {
        return this._subUnitName;
    }

    get symbol(): string {
        return this._symbol;
    }

    get symbolPosition(): number {
        return this._symbolPosition;
    }

    get enabled(): boolean {
        return this._enabled;
    }

    get defaultCurrency(): boolean {
        return this._defaultCurrency;
    }

    get id(): number {
        return this._id;
    }

    public getName(): string {
        return `${this._letterCode} (${this._numberCode}) - ${this._enabled ? 'enabled' : 'disabled'}`
    }
}
