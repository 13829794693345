import {CardVerificationMethod} from "@/store/models/CardVerificationMethod";
import {ConfigurationFormModel} from "@/store/models/forms/ConfigurationFormModel";
import {BaseRequestModel} from "@/store/models/request/BaseRequestModel";
import {ConfigurationStoredModel} from "@/store/models/stored/ConfigurationStoredModel";

export class ConfigurationRequestModel extends BaseRequestModel {
    private tc: string;
    private sca: boolean;
    private tdol: string;
    private ddol: string;
    private name: string;
    private status: number;
    private tcForRefund: string;
    private countryCode: string;
    private aids: Array<number>;
    private chipAllowed: boolean;
    private tcAdditional: string;
    private mandatoryPin: boolean;
    private limits: Array<number>;
    private tpmcForRefund: string;
    private aidKeys: Array<number>;
    private id: number | undefined;
    private mandatoryChip: boolean;
    private contactlessType: string;
    private currencyNumberCode: string;
    private currencyId: number | undefined;
    private expiredCardAllowed: boolean;
    private allowedRanges: Array<number>;
    private defaultConfiguration: boolean;
    private terminalTransactionQualifiers: string;
    private expiredCardAllowedRanges: Array<number>;
    private trustedPlatformModuleCapabilities: string;
    private enhancedContactlessDeviceCapabilities: string;
    private terminalTransactionQualifiersForRefund: string;
    private enhancedContactlessDeviceCapabilitiesForRefund: string;
    private cardVerificationMethod: CardVerificationMethod | undefined;

    constructor(configuration: ConfigurationFormModel) {
        super(configuration);

        this.name = configuration.name;
        this.status = configuration.active ? ConfigurationStoredModel.ACTIVE : ConfigurationStoredModel.BLOCKED;
        this.tc = configuration.tc;
        this.tcForRefund = configuration.tcForRefund;
        this.countryCode = configuration.countryCode;
        this.currencyNumberCode = configuration.currencyNumberCode;
        this.currencyId = configuration.currencyId;
        this.defaultConfiguration = configuration.defaultConfiguration;
        this.sca = configuration.sca;
        this.tdol = configuration.tdol;
        this.ddol = configuration.ddol;
        this.chipAllowed = configuration.chipAllowed;
        this.tcAdditional = configuration.tcAdditional;
        this.mandatoryPin = configuration.mandatoryPin;
        this.tpmcForRefund = configuration.tpmcForRefund;
        this.mandatoryChip = configuration.mandatoryChip;
        this.contactlessType = configuration.contactlessType;
        this.expiredCardAllowed = configuration.expiredCardAllowed;
        this.terminalTransactionQualifiers = configuration.terminalTransactionQualifiers;
        this.trustedPlatformModuleCapabilities = configuration.trustedPlatformModuleCapabilities;
        this.enhancedContactlessDeviceCapabilities = configuration.enhancedContactlessDeviceCapabilities;
        this.cardVerificationMethod = configuration.cardVerificationMethod;
        this.terminalTransactionQualifiersForRefund = configuration.terminalTransactionQualifiersForRefund;

        this.enhancedContactlessDeviceCapabilitiesForRefund =
            configuration.enhancedContactlessDeviceCapabilitiesForRefund;

        this.aids = configuration.aids;
        this.aidKeys = configuration.aidKeys;
        this.limits = configuration.limits;
        this.allowedRanges = configuration.allowedRanges;
        this.expiredCardAllowedRanges = configuration.expiredCardAllowedRanges;
        this.id = configuration.id;
    }
}
