export const user = [
    '560 560',
    `
  <g transform="translate(0.000000,560.000000) scale(0.100000,-0.100000)" stroke="none">
<path d="M2660 5589 c-195 -12 -490 -55 -540 -79 -8 -4 -40 -13 -70 -19 -85
-20 -306 -100 -409 -150 -19 -9 -39 -15 -43 -14 -4 2 -8 -2 -8 -8 0 -5 -4 -7
-10 -4 -5 3 -10 1 -10 -6 0 -7 -3 -9 -7 -6 -3 4 -15 0 -25 -10 -10 -9 -18 -13
-18 -9 0 4 -6 2 -12 -4 -19 -15 -131 -81 -143 -83 -5 -1 -12 -5 -15 -8 -3 -3
-54 -39 -114 -79 -60 -41 -117 -80 -125 -87 -9 -7 -34 -29 -57 -48 -23 -19
-45 -31 -49 -27 -5 4 -6 3 -3 -3 2 -5 -13 -26 -36 -45 -22 -19 -36 -28 -31
-20 5 8 -43 -37 -106 -100 -63 -63 -113 -118 -111 -122 2 -5 -1 -8 -6 -8 -5 0
-23 -19 -40 -42 -17 -24 -41 -54 -54 -68 -23 -25 -29 -33 -94 -127 -20 -29
-40 -53 -44 -53 -4 0 -11 -11 -14 -24 -3 -14 -12 -31 -20 -38 -7 -8 -38 -57
-67 -109 -29 -52 -59 -100 -66 -107 -7 -8 -11 -18 -8 -23 4 -5 1 -9 -5 -9 -6
0 -8 -4 -5 -10 3 -5 1 -10 -4 -10 -6 0 -10 -3 -9 -7 1 -5 -6 -25 -15 -47 -9
-21 -17 -42 -17 -47 0 -5 -4 -9 -10 -9 -5 0 -10 -10 -10 -23 0 -12 -6 -32 -14
-43 -24 -34 -85 -220 -132 -402 -63 -247 -92 -651 -64 -918 36 -353 125 -663
277 -969 81 -161 220 -385 288 -462 17 -19 30 -38 29 -41 0 -4 10 -15 23 -25
13 -10 23 -22 23 -27 0 -5 12 -21 28 -37 15 -16 48 -51 73 -78 70 -76 257
-248 284 -263 14 -7 25 -17 25 -22 0 -6 4 -10 10 -10 5 0 18 -8 28 -17 31 -30
72 -63 77 -63 8 0 88 -52 100 -64 6 -6 28 -20 50 -31 22 -11 47 -25 55 -31 8
-7 56 -33 105 -59 50 -26 93 -52 96 -57 3 -5 8 -9 10 -8 8 3 190 -69 207 -83
7 -5 20 -11 30 -12 9 -1 30 -7 46 -13 16 -7 36 -14 45 -15 9 -1 21 -6 27 -10
25 -15 301 -79 422 -97 299 -45 693 -41 917 10 265 60 378 92 478 134 20 9 37
14 37 12 0 -3 22 6 49 19 27 14 52 25 56 25 16 0 263 128 258 134 -4 3 -1 6 5
6 7 0 48 22 91 49 43 28 80 49 82 48 2 -1 3 0 2 2 -2 2 25 22 59 45 33 23 67
46 75 51 8 6 36 29 61 52 25 23 50 39 56 36 5 -3 6 -1 2 5 -4 7 -3 12 1 12 5
0 19 10 33 23 14 13 33 26 43 30 9 3 17 14 17 23 0 10 2 15 5 12 6 -6 165 149
165 160 0 5 18 24 40 45 22 20 45 48 52 61 6 14 15 23 19 20 5 -3 9 1 9 8 0 8
11 27 25 43 36 43 130 177 138 198 3 9 11 17 17 17 6 0 8 5 5 10 -3 6 -1 10 4
10 6 0 11 5 11 10 0 6 16 36 35 66 19 30 35 58 35 62 0 4 24 54 53 112 89 178
135 301 191 513 77 291 107 682 76 972 -11 106 -44 306 -60 365 -43 160 -78
270 -111 354 -21 54 -39 104 -39 112 0 8 -4 14 -8 14 -4 0 -16 25 -27 55 -10
30 -22 55 -26 55 -8 0 -24 41 -20 49 2 2 -2 7 -7 10 -5 3 -27 40 -48 81 -21
41 -41 77 -45 80 -8 7 -63 94 -61 98 1 1 -6 10 -15 20 -28 29 -53 62 -53 68 0
6 -48 71 -68 92 -7 8 -25 30 -40 50 -15 21 -35 43 -44 50 -10 7 -18 17 -18 22
0 7 -74 81 -205 205 -66 62 -169 148 -227 189 -38 26 -68 52 -68 57 0 6 -4 8
-9 4 -5 -3 -12 -1 -16 5 -3 6 -23 19 -43 29 -20 11 -46 28 -57 38 -11 10 -60
39 -110 63 -49 25 -99 53 -109 63 -11 9 -26 17 -33 17 -8 0 -46 15 -86 33 -40
19 -106 46 -147 61 -41 15 -84 31 -95 36 -11 5 -29 11 -40 13 -11 3 -51 15
-90 26 -173 52 -380 87 -570 96 -66 3 -136 7 -155 9 -19 1 -96 -1 -170 -5z
m394 -1123 c369 -95 647 -392 726 -776 37 -177 16 -395 -54 -565 -198 -485
-750 -740 -1239 -574 -144 49 -320 160 -413 260 -110 118 -200 280 -241 437
-36 136 -39 354 -5 486 99 391 401 676 798 751 117 22 302 14 428 -19z m51
-2290 c195 -35 345 -82 510 -160 387 -184 712 -509 902 -901 l43 -90 -72 -68
c-40 -37 -122 -105 -183 -151 -353 -265 -730 -419 -1190 -487 -120 -18 -501
-18 -625 -1 -457 65 -835 220 -1193 488 -67 50 -153 120 -190 155 l-68 64 65
127 c292 576 840 959 1486 1038 107 13 408 5 515 -14z"/>
</g>
`,
]
