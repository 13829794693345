import lscache from "lscache";
import {AxiosResponse} from "axios";
import router from "@/router";
import { SessionState } from "@/store/modules/session";
import { State } from "@/store";
import { ActionContext } from "vuex";

export class Utils {
    public static isSuperAdmin() {
        return lscache.get('sessionUser') && !lscache.get('sessionUser').financialInstituteId;
    }
    
    public static authorizeUser(context: ActionContext<SessionState, State>, response: AxiosResponse) {
        const authorizedUser = response.data;
        lscache.set('isAuth', true);
        lscache.set('filter', {});
        lscache.set('sessionUser', authorizedUser);
        context.dispatch('settings/list', authorizedUser.financialInstituteId, {root: true});
        context.commit('sidebar/updateSidebarVisible', false, {root: true});
        router.push({path: 'dashboard'});
    }
}
