import {CurrencyFormModel} from "@/store/models/forms/CurrencyFormModel";
import {BaseRequestModel} from "@/store/models/request/BaseRequestModel";

export class CurrencyRequestModel extends BaseRequestModel {
    private id: number | undefined;
    private numberCode: string;
    private letterCode: string;
    private fraction: number;
    private unitName: string;
    private subUnitName: string;
    private symbol: string;
    private symbolPosition: number;
    private enabled: boolean;
    private defaultCurrency: boolean;

    constructor(currencyForm: CurrencyFormModel) {
        super(currencyForm);

        this.id = currencyForm.id;
        this.numberCode = currencyForm.numberCode;
        this.letterCode = currencyForm.letterCode;
        this.fraction = currencyForm.fraction;
        this.unitName = currencyForm.unitName;
        this.subUnitName = currencyForm.subUnitName;
        this.symbol = currencyForm.symbol;
        this.symbolPosition = currencyForm.symbolPosition;
        this.enabled = currencyForm.enabled;
        this.defaultCurrency = currencyForm.defaultCurrency;
    }
}
