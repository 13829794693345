import {AidKeyFormModel} from "@/store/models/forms/AidKeyFormModel";
import {BaseRequestModel} from "@/store/models/request/BaseRequestModel";

export class AidKeyRequestModel extends BaseRequestModel {
    private id: number | undefined;
    private rid: string;
    private hash: string;
    private comment: string;
    private keyIndex: string;
    private exponent: string;
    private publicKey: string;

    constructor(aidKey: AidKeyFormModel) {
        super(aidKey);

        this.id = aidKey.id;
        this.rid = aidKey.rid;
        this.hash = aidKey.hash;
        this.comment = aidKey.comment;
        this.keyIndex = aidKey.keyIndex;
        this.exponent = aidKey.exponent;
        this.publicKey = aidKey.publicKey;
    }
}
