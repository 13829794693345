import {CardVerificationMethod} from "@/store/models/CardVerificationMethod";
import {AidKeyStoredModel} from "@/store/models/stored/AidKeyStoredModel";
import {ConfigurationLimitStoredModel} from "@/store/models/stored/ConfigurationLimitStoredModel";
import {ConfigurationRangeStoredModel} from "@/store/models/stored/ConfigurationRangeStoredModel";
import {AidStoredModel} from "@/store/models/stored/AidStoredModel";
import {BaseStoredModel} from "@/store/models/stored/BaseStoredModel";

export class ConfigurationStoredModel extends BaseStoredModel {
    public static ACTIVE = 0;
    public static BLOCKED = 2;

    private readonly _tc: string;
    private readonly _id: number;
    private readonly _sca: boolean;
    private readonly _tdol: string;
    private readonly _ddol: string;
    private readonly _name: string;
    private readonly _status: number;
    private readonly _tcForRefund: string;
    private readonly _countryCode: string;
    private readonly _chipAllowed: boolean;
    private readonly _tcAdditional: string;
    private readonly _mandatoryPin: boolean;
    private readonly _tpmcForRefund: string;
    private readonly _mandatoryChip: boolean;
    private readonly _contactlessType: string;
    private readonly _currencyLetterCode: string;
    private readonly _currencyNumberCode: string;
    private readonly _currencyId: number | undefined;
    private readonly _expiredCardAllowed: boolean;
    private readonly _aids: Array<AidStoredModel>;
    private readonly _defaultConfiguration: boolean;
    private readonly _aidKeys: Array<AidKeyStoredModel>;
    private readonly _terminalTransactionQualifiers: string;
    private readonly _trustedPlatformModuleCapabilities: string;
    private readonly _limits: Array<ConfigurationLimitStoredModel>;
    private readonly _enhancedContactlessDeviceCapabilities: string;
    private readonly _cardVerificationMethod: CardVerificationMethod;
    private readonly _terminalTransactionQualifiersForRefund: string;
    private readonly _allowedRanges: Array<ConfigurationRangeStoredModel>;
    private readonly _enhancedContactlessDeviceCapabilitiesForRefund: string;
    private readonly _expiredCardAllowedRanges: Array<ConfigurationRangeStoredModel>;

    constructor(configuration: ConfigurationStoredModel) {
        super(configuration);

        this._name = configuration.name;
        this._status = configuration.status;
        this._tc = configuration.tc;
        this._sca = configuration.sca;
        this._tdol = configuration.tdol;
        this._ddol = configuration.ddol;
        this._tcForRefund = configuration.tcForRefund;
        this._countryCode = configuration.countryCode;
        this._currencyNumberCode = configuration.currencyNumberCode;
        this._currencyId = configuration.currencyId;
        this._defaultConfiguration = configuration.defaultConfiguration;
        this._chipAllowed = configuration.chipAllowed;
        this._tcAdditional = configuration.tcAdditional;
        this._mandatoryPin = configuration.mandatoryPin;
        this._tpmcForRefund = configuration.tpmcForRefund;
        this._mandatoryChip = configuration.mandatoryChip;
        this._contactlessType = configuration.contactlessType;
        this._expiredCardAllowed = configuration.expiredCardAllowed;
        this._terminalTransactionQualifiers = configuration.terminalTransactionQualifiers;
        this._trustedPlatformModuleCapabilities = configuration.trustedPlatformModuleCapabilities;
        this._enhancedContactlessDeviceCapabilities = configuration.enhancedContactlessDeviceCapabilities;
        this._cardVerificationMethod = configuration.cardVerificationMethod;
        this._terminalTransactionQualifiersForRefund = configuration.terminalTransactionQualifiersForRefund;

        this._enhancedContactlessDeviceCapabilitiesForRefund =
            configuration.enhancedContactlessDeviceCapabilitiesForRefund;

        this._aids = configuration.aids ? configuration.aids.map((aid) => new AidStoredModel(aid)) : [];

        this._aidKeys = configuration.aidKeys
            ? configuration.aidKeys.map((aidKey) => new AidKeyStoredModel(aidKey))
            : [];

        this._limits = configuration.limits
            ? configuration.limits.map((limit) => new ConfigurationLimitStoredModel(limit))
            : [];

        this._allowedRanges = configuration.allowedRanges
            ? configuration.allowedRanges.map((range) => new ConfigurationRangeStoredModel(range))
            : [];

        this._expiredCardAllowedRanges = configuration.expiredCardAllowedRanges
            ? configuration.expiredCardAllowedRanges.map((range) => new ConfigurationRangeStoredModel(range))
            : [];

        this._id = configuration.id;
        this._currencyLetterCode = configuration.currencyLetterCode;
    }

    get currencyLetterCode(): string {
        return this._currencyLetterCode;
    }

    get name(): string {
        return this._name;
    }

    get status(): number {
        return this._status;
    }

    get tc(): string {
        return this._tc;
    }

    get tcForRefund(): string {
        return this._tcForRefund;
    }

    get countryCode(): string {
        return this._countryCode;
    }

    get currencyNumberCode(): string {
        return this._currencyNumberCode;
    }

    get currencyId(): number | undefined {
        return this._currencyId;
    }

    get defaultConfiguration(): boolean {
        return this._defaultConfiguration;
    }

    get sca(): boolean {
        return this._sca;
    }

    get tdol(): string {
        return this._tdol;
    }

    get ddol(): string {
        return this._ddol;
    }

    get chipAllowed(): boolean {
        return this._chipAllowed;
    }

    get tcAdditional(): string {
        return this._tcAdditional;
    }

    get mandatoryPin(): boolean {
        return this._mandatoryPin;
    }

    get tpmcForRefund(): string {
        return this._tpmcForRefund;
    }

    get mandatoryChip(): boolean {
        return this._mandatoryChip;
    }

    get contactlessType(): string {
        return this._contactlessType;
    }

    get expiredCardAllowed(): boolean {
        return this._expiredCardAllowed;
    }

    get terminalTransactionQualifiers(): string {
        return this._terminalTransactionQualifiers;
    }

    get trustedPlatformModuleCapabilities(): string {
        return this._trustedPlatformModuleCapabilities;
    }

    get enhancedContactlessDeviceCapabilities(): string {
        return this._enhancedContactlessDeviceCapabilities;
    }

    get cardVerificationMethod(): CardVerificationMethod {
        return this._cardVerificationMethod;
    }

    get terminalTransactionQualifiersForRefund(): string {
        return this._terminalTransactionQualifiersForRefund;
    }

    get enhancedContactlessDeviceCapabilitiesForRefund(): string {
        return this._enhancedContactlessDeviceCapabilitiesForRefund;
    }

    get aids(): Array<AidStoredModel> {
        return this._aids;
    }

    get aidKeys(): Array<AidKeyStoredModel> {
        return this._aidKeys;
    }

    get limits(): Array<ConfigurationLimitStoredModel> {
        return this._limits;
    }

    get allowedRanges(): Array<ConfigurationRangeStoredModel> {
        return this._allowedRanges;
    }

    get expiredCardAllowedRanges(): Array<ConfigurationRangeStoredModel> {
        return this._expiredCardAllowedRanges;
    }

    get id(): number {
        return this._id;
    }

    public getName(): string {
        return this._name;
    }
}
