
import {defineComponent} from 'vue';
import router from '@/router';
import {Router} from "@/store/models/Router";

export default defineComponent({
  name: 'AppBreadcrumb',

  computed: {
    breadcrumbs() {
      let path = '';

      const pathList = router.currentRoute.value.fullPath.split('/')
          .map((pathItem) => {
            if (/[0-9]+/.test(pathItem)) {
              path += '/:id';
            } else if (!Router.pageList.includes(pathItem) && !path.includes(Router.catalogMask) || !pathItem) {
              path += `/:${Router.catalogMask}?`;
            } else if (Router.pageList.includes(pathItem)) {
              path += `/${pathItem}`;
            }

            return !path.includes(Router.catalogMask) ? `/:${Router.catalogMask}?${path}` : path;
          })

      const routeList = router.getRoutes()
          .filter(routeItem => pathList
              .find(pathItem => routeItem.path === pathItem
                  || routeItem.path === `${pathItem}?`
                  || routeItem.path === `${pathItem}/:id?`)
          )
          .reverse();

      return routeList.map((route, routeIndex) => {
        return {
          active: routeIndex === routeList.length - 1,
          name: route.name,
          path: `${route.path.replace(`/:${Router.catalogMask}?/`, '/')}`,
        };
      });
    }
  },
})
