import {BaseStoredModel} from "@/store/models/stored/BaseStoredModel";
import TransactionInstrumentStoredModel from "@/store/models/stored/transaction/TransactionInstrumentStoredModel";
import TransactionProcessingStoredModel from "@/store/models/stored/transaction/TransactionProcessingStoredModel";
import {CurrencyStoredModel} from "@/store/models/stored/CurrencyStoredModel";
import TransactionCardDataStoredModel from "@/store/models/stored/transaction/TransactionCardDataStoredModel";
import TransactionTerminalStoredModel from "@/store/models/stored/transaction/TransactionTerminalStoredModel";
import {formattedAmountWithCurrencySymbol} from "@/logic/ConvertCurrency";
import {MerchantStoredModel} from "@/store/models/stored/MerchantStoredModel";

export default class TransactionStoredModel extends BaseStoredModel {

    readonly guid: number;
    readonly id: number;
    readonly transactionDate: number;
    readonly operationName: string;
    readonly status: string;
    readonly reconciliationStatus: number;
    readonly paymentInstrument: TransactionInstrumentStoredModel;
    readonly paymentInstrumentName: string;
    readonly amount: number;
    readonly currency: CurrencyStoredModel;
    readonly cardData: TransactionCardDataStoredModel;
    readonly paymentSystem: string;
    readonly paymentMethod: string;
    readonly cardMask: string;
    readonly cardHolderName: string;
    readonly processing: TransactionProcessingStoredModel;
    readonly rrn: string;
    readonly authCode: string;
    readonly responseCode: string;
    readonly terminalExternalId: string;
    readonly merchantExternalId: string;
    readonly merchant: MerchantStoredModel;
    readonly terminal: TransactionTerminalStoredModel;
    readonly formattedAmount: string;

    constructor(transaction: TransactionStoredModel) {
        super(transaction);

        this.guid = transaction.guid;
        this.id = transaction.id;
        this.transactionDate = transaction.transactionDate;
        this.operationName = transaction.operationName;
        this.status = transaction.status;
        this.reconciliationStatus = transaction.reconciliationStatus;
        this.paymentInstrument = transaction.paymentInstrument;
        this.paymentInstrumentName = transaction.paymentInstrument?.name
            ? transaction.paymentInstrument?.name : transaction.paymentInstrument?.type;
        this.amount = transaction.amount;
        this.currency = transaction.currency;
        this.cardData = transaction.cardData;
        this.paymentSystem = transaction.cardData?.paymentSystem;
        this.cardHolderName = transaction.cardData?.cardHolderName;
        this.paymentMethod = transaction.cardData?.cardMethod;
        this.cardMask = transaction.cardData?.cardMask;
        this.processing = transaction.processing;
        this.rrn = transaction.processing?.rrn;
        this.authCode = transaction.processing?.authCode;
        this.responseCode = transaction.processing?.responseCode;
        this.terminal = transaction.terminal;
        this.terminalExternalId = transaction.terminal?.externalId;
        this.formattedAmount = formattedAmountWithCurrencySymbol(transaction.amount, transaction.currency)
        this.merchant = transaction.merchant;
        this.terminalExternalId = transaction.terminalExternalId;
        this.merchantExternalId = transaction.merchantExternalId;
    }
}
