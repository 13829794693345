import {Table} from "@/logic/table/Table";
import {HeaderCell} from "@/logic/table/HeaderCell";
import {SearchConditionMultiple} from "filter-data/lib/lib/types";
import {SearchCondition} from "@/logic/table/SearchCondition";
import {SearchType} from "filter-data";
import {TableFilter} from "@/logic/table/TableFilter";
import {Utils} from "@/logic/Utils";
import {PaymentInstrumentStoredModel} from "@/store/models/stored/PaymentInstrumentStoredModel";

export class PaymentInstrumentTable extends Table<PaymentInstrumentStoredModel> {
    filterConfigurationList(): Array<SearchConditionMultiple> {
        return [
            new SearchCondition(
                ['id', 'type', 'name', 'enabled', 'available', 'defaultAccess', 'financialInstitute'],
                SearchType.LK,
                this.filterOptions.searchValue
            )
        ];
    }

    get headers(): Array<HeaderCell> {
        return HeaderCell.changeSortTypeInList(
            TableFilter.getFilterObject('paymentInstrument'),
            [
                new HeaderCell('Id', 'id', HeaderCell.DESC),
                new HeaderCell('Type', 'type'),
                new HeaderCell('Name', 'name'),
                new HeaderCell('Enabled', 'enabled'),
                new HeaderCell('Available', 'available'),
                new HeaderCell('Default access', 'defaultAccess'),
                new HeaderCell(
                    'Financial institute',
                    'financialInstitute',
                    HeaderCell.SORTABLE,
                    false,
                    true,
                    '200px',
                    () => Utils.isSuperAdmin()
                ),
                new HeaderCell('', 'actions', HeaderCell.UNSORTABLE)
            ]
        );
    }
}
