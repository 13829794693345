import {BaseRequestModel} from "@/store/models/request/BaseRequestModel";
import {PaymentInstrumentFormModel} from "@/store/models/forms/PaymentInstrumentFormModel";

export class PaymentInstrumentRequestModel extends BaseRequestModel {
    private id: number | undefined;
    private enabled: boolean;
    private available: boolean;
    private description: string;
    private defaultAccess: boolean;


    constructor(paymentInstrumentFormModel: PaymentInstrumentFormModel) {
        super(paymentInstrumentFormModel);
        
        this.id = paymentInstrumentFormModel.id;
        this.enabled = paymentInstrumentFormModel.enabled;
        this.available = paymentInstrumentFormModel.available;
        this.description = paymentInstrumentFormModel.description;
        this.defaultAccess = paymentInstrumentFormModel.defaultAccess;
    }
}
