export class FinancialInstituteStoredModel {
    private readonly _id: number;
    private readonly _name: string;
    private readonly _hostType: string;
    private readonly _hostPort: number;
    private readonly _macEnabled: boolean;
    private readonly _hostAddress: string;
    private readonly _realAddress: string;
    private readonly _legalAddress: string;
    private readonly _keysUpdatingScheme: string;
    private readonly _encryptionEnabled: boolean;
    private readonly _responseCheckingEnabled: boolean;
    private readonly _reconciliationAdviceEnabled: boolean;
    private readonly _connectionMode: string;
    private readonly _creationDate: string;
    private readonly _creator: string;
    private readonly _macBinMode: boolean;
    private readonly _keysScheme: number;
    private readonly _reversalSendAllEmvTags: boolean;

    constructor(financialInstitute: FinancialInstituteStoredModel) {
        this._name = financialInstitute.name;
        this._hostType = financialInstitute.hostType;
        this._hostPort = financialInstitute.hostPort;
        this._macEnabled = financialInstitute.macEnabled;
        this._legalAddress = financialInstitute.legalAddress;
        this._encryptionEnabled = financialInstitute.encryptionEnabled;
        this._hostAddress = financialInstitute.hostAddress;
        this._realAddress = financialInstitute.realAddress;
        this._keysUpdatingScheme = financialInstitute.keysUpdatingScheme;
        this._responseCheckingEnabled = financialInstitute.responseCheckingEnabled;
        this._reconciliationAdviceEnabled = financialInstitute.reconciliationAdviceEnabled;
        this._connectionMode = financialInstitute.connectionMode;
        this._id = financialInstitute.id;
        this._creationDate = financialInstitute.creationDate;
        this._creator = financialInstitute.creator;
        this._macBinMode = financialInstitute.macBinMode;
        this._keysScheme = financialInstitute.keysScheme;
        this._reversalSendAllEmvTags = financialInstitute.reversalSendAllEmvTags;
    }

    get name(): string {
        return this._name;
    }

    get hostType(): string {
        return this._hostType;
    }

    get hostPort(): number {
        return this._hostPort;
    }

    get macEnabled(): boolean {
        return this._macEnabled;
    }

    get legalAddress(): string {
        return this._legalAddress;
    }

    get encryptionEnabled(): boolean {
        return this._encryptionEnabled;
    }

    get hostAddress(): string {
        return this._hostAddress;
    }

    get realAddress(): string {
        return this._realAddress;
    }

    get keysUpdatingScheme(): string {
        return this._keysUpdatingScheme;
    }

    get responseCheckingEnabled(): boolean {
        return this._responseCheckingEnabled;
    }

    get reconciliationAdviceEnabled(): boolean {
        return this._reconciliationAdviceEnabled;
    }

    get id(): number {
        return this._id;
    }

    get creationDate(): string {
        return this._creationDate;
    }

    get creator(): string {
        return this._creator;
    }

    get host(): string {
        return !this._hostPort ? `${this._hostAddress}` : `${this._hostAddress}:${this._hostPort}`
    }

    get macBinMode(): boolean {
        return this._macBinMode;
    }

    get keysScheme(): number {
        return this._keysScheme;
    }

    get keysConfirmation(): boolean {
        return this._keysUpdatingScheme !== null && this._keysUpdatingScheme.toLowerCase() === 'all';
    }

    get connectionMode(): string {
        return this._connectionMode;
    }

    get reversalSendAllEmvTags(): boolean {
        return this._reversalSendAllEmvTags;
    }
}
