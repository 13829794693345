import axios from 'axios';
import lscache from "lscache";
import router from "@/router";
import {ServerException} from "@/store/models/ServerException";
import {Router} from "@/store/models/Router";

const httpHandler = axios.create({
    withCredentials: true,
});

httpHandler.interceptors.response.use(
    (response) => response,
    (error) => {
    const exception: ServerException = error.response ? error.response.data : error.message;

    if (exception.status === 403) {
        lscache.remove('isAuth');
        router.push('/login');
    }

    return Promise.reject(error);
});

httpHandler.interceptors.request.use(function (config) {
    const path = window.location.pathname.split('/').find(route => route) || '';

    if (config.url && !config.url.includes(path) && !Router.pageList.includes(path)) {
        config.url = `/${path}${config.url}`;
    }

    return config;
});

httpHandler.defaults.timeout = 30000;

export default httpHandler;
