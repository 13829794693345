
import {CContainer} from '@coreui/vue';
import AppFooter from '@/components/AppFooter.vue';
import AppHeader from '@/components/AppHeader.vue';
import AppSidebar from '@/components/AppSidebar.vue';
import {defineComponent} from "vue";
import AlertComponent from "@/components/AlertComponent.vue";

export default defineComponent({
  name: 'DefaultLayout',

  components: {
    AlertComponent,
    AppFooter,
    AppHeader,
    AppSidebar,
    CContainer,
  },
})
