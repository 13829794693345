import {ActionContext} from "vuex";
import {State} from "@/store";
import {ConfigurationLimitStoredModel} from "@/store/models/stored/ConfigurationLimitStoredModel";
import axios from "@/http-common";
import {AxiosError, AxiosResponse} from "axios";
import {ConfigurationLimitFormModel} from "@/store/models/forms/ConfigurationLimitFormModel";
import {ConfigurationLimitRequestModel} from "@/store/models/request/ConfigurationLimitRequestModel";
import {TableFilter} from "@/logic/table/TableFilter";
import {ConfigurationLimitTable} from "@/logic/table/ConfigurationLimitTable";
import router from "@/router";
import {SelectOption} from "@/logic/select/SelectOption";

export class ConfigurationLimitState {
    constructor(
        private _configurationLimitList: Array<ConfigurationLimitStoredModel> = [],

        private _filteredConfigurationLimitList: ConfigurationLimitTable = new ConfigurationLimitTable(
            [],
            TableFilter.getFilterObject('configurationLimit')
        ),

        private _configurationLimitListIsLoading: boolean = true
    ) {
    }

    public get configurationLimitList(): Array<ConfigurationLimitStoredModel> {
        return this._configurationLimitList;
    }

    public set configurationLimitList(configurationLimitList: Array<ConfigurationLimitStoredModel>) {
        this._configurationLimitList = configurationLimitList;
    }

    public get filteredConfigurationLimitList(): ConfigurationLimitTable {
        return this._filteredConfigurationLimitList;
    }

    public set filteredConfigurationLimitList(configurationLimitList: ConfigurationLimitTable) {
        this._filteredConfigurationLimitList = configurationLimitList;
    }

    public get configurationLimitListIsLoading(): boolean {
        return this._configurationLimitListIsLoading;
    }

    public set configurationLimitListIsLoading(loadingStatus: boolean) {
        this._configurationLimitListIsLoading = loadingStatus;
    }
}

type Context = ActionContext<ConfigurationLimitState, State>;

export default {
    namespaced: true,

    state: new ConfigurationLimitState(),

    mutations: {
        list(state: ConfigurationLimitState, configurationLimitList: Array<ConfigurationLimitStoredModel>): void {
            state.configurationLimitList = configurationLimitList
                .map((configurationLimit: ConfigurationLimitStoredModel) =>
                    new ConfigurationLimitStoredModel(configurationLimit))
                .sort((configurationLimitA, configurationLimitB) =>
                    configurationLimitA.id > configurationLimitB.id ? -1 : 1
                );
        },

        filteredList(state: ConfigurationLimitState): void {
            state.filteredConfigurationLimitList = new ConfigurationLimitTable(
                state.configurationLimitList,
                TableFilter.getFilterObject('configurationLimit')
            );
        },

        filterOptions(state: ConfigurationLimitState, filterOptions: TableFilter): void {
            state.filteredConfigurationLimitList.filterOptions = new TableFilter(filterOptions);
            TableFilter.setFilterObject('configurationLimit', filterOptions);
        },

        add(state: ConfigurationLimitState, configurationLimit: ConfigurationLimitStoredModel): void {
            state.configurationLimitList.push(configurationLimit);
        },

        update(state: ConfigurationLimitState, updateConfigurationLimit: ConfigurationLimitStoredModel): void {
            state.configurationLimitList = state.configurationLimitList
                .map(configurationLimit =>
                    configurationLimit.id === updateConfigurationLimit.id
                        ? updateConfigurationLimit
                        : configurationLimit
                );

            state.filteredConfigurationLimitList.dataList = state.configurationLimitList;
        },

        delete(state: ConfigurationLimitState, deletedId: number): void {
            state.configurationLimitList = state.configurationLimitList
                .filter(configurationLimit => configurationLimit.id !== deletedId);

            state.filteredConfigurationLimitList.dataList = state.configurationLimitList;
        }
    },

    actions: {
        list(context: Context): Promise<void> {
            return axios.get('/api/configuration-limit/list')
                .then((response: AxiosResponse) => {
                    context.commit('list', response.data);
                    context.commit('filteredList', TableFilter.getFilterObject('configurationLimit'));
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}))
                .finally(() => context.state.configurationLimitListIsLoading = false);
        },

        getById(context: Context, id: number): Promise<ConfigurationLimitStoredModel> {
            return axios.get(`/api/configuration-limit/${id}`)
                .then((response: AxiosResponse) => new ConfigurationLimitStoredModel(response.data))
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        },

        add(context: Context, configurationLimitForm: ConfigurationLimitFormModel): Promise<void> {
            return axios.post('/api/configuration-limit/', new ConfigurationLimitRequestModel(configurationLimitForm))
                .then((response: AxiosResponse) => {
                    context.commit('add', new ConfigurationLimitStoredModel(response.data));
                    context.dispatch('alert/success', 'Limit created', {root: true});
                    router.push({ path: `/limit/list` });
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        },

        update(context: Context, configurationLimitForm: ConfigurationLimitFormModel): Promise<void> {
            return axios.put('/api/configuration-limit/', new ConfigurationLimitRequestModel(configurationLimitForm))
                .then((response: AxiosResponse) => {
                    context.commit('update', new ConfigurationLimitStoredModel(response.data));
                    context.dispatch('alert/success', 'Limit updated', {root: true});
                    router.push({ path: `/limit/list` });
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        },

        delete(context: Context, id: number): Promise<void> {
            return axios.delete(`/api/configuration-limit/${id}`)
                .then(() => {
                    context.commit('delete', id);
                    context.dispatch('alert/success', 'Limit deleted', {root: true});
                    router.push('/limit/list');
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        }
    },

    getters: {
        list: (state: ConfigurationLimitState): Array<ConfigurationLimitStoredModel> => {
            return state.configurationLimitList
                .sort((configurationLimitA, configurationLimitB) =>
                    configurationLimitA.id > configurationLimitB.id ? 1 : -1
                );
        },

        byId: (state: ConfigurationLimitState) => (id: number): ConfigurationLimitStoredModel | undefined => {
            return state.configurationLimitList
                .find((configurationLimit: ConfigurationLimitStoredModel) => id === configurationLimit.id);
        },

        filteredList: (state: ConfigurationLimitState): ConfigurationLimitTable => {
            return state.filteredConfigurationLimitList;
        },

        configurationLimitListIsLoading: (state: ConfigurationLimitState): boolean => {
            return state.configurationLimitListIsLoading;
        },

        selectOptionList: (state: ConfigurationLimitState): Array<SelectOption<number>> => {
            return state.configurationLimitList.map((configurationLimit: ConfigurationLimitStoredModel) =>
                new SelectOption<number>(
                    new ConfigurationLimitStoredModel(configurationLimit).getSelectName(),
                    configurationLimit.id
                )
            )
        }
    },
}
