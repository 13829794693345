import {ActionContext} from "vuex";
import {State} from "@/store";
import {AidKeyStoredModel} from "@/store/models/stored/AidKeyStoredModel";
import axios from "@/http-common";
import {AxiosError, AxiosResponse} from "axios";
import {AidKeyFormModel} from "@/store/models/forms/AidKeyFormModel";
import {AidKeyRequestModel} from "@/store/models/request/AidKeyRequestModel";
import {TableFilter} from "@/logic/table/TableFilter";
import {AidKeyTable} from "@/logic/table/AidKeyTable";
import router from "@/router";
import {SelectOption} from "@/logic/select/SelectOption";

export class AidKeyState {
    constructor(
        private _aidKeyList: Array<AidKeyStoredModel> = [],
        private _filteredAidKeyList: AidKeyTable = new AidKeyTable([], TableFilter.getFilterObject('aidKey')),
        private _aidKeyListIsLoading: boolean = true
    ) {
    }

    public get aidKeyList(): Array<AidKeyStoredModel> {
        return this._aidKeyList;
    }

    public set aidKeyList(aidKeyList: Array<AidKeyStoredModel>) {
        this._aidKeyList = aidKeyList;
    }

    public get filteredAidKeyList(): AidKeyTable {
        return this._filteredAidKeyList;
    }

    public set filteredAidKeyList(aidKeyList: AidKeyTable) {
        this._filteredAidKeyList = aidKeyList;
    }

    public get aidKeyListIsLoading(): boolean {
        return this._aidKeyListIsLoading;
    }

    public set aidKeyListIsLoading(loadingStatus: boolean) {
        this._aidKeyListIsLoading = loadingStatus;
    }
}

type Context = ActionContext<AidKeyState, State>;

export default {
    namespaced: true,

    state: new AidKeyState(),

    mutations: {
        list(state: AidKeyState, aidKeyList: Array<AidKeyStoredModel>): void {
            state.aidKeyList = aidKeyList
                .map((aidKey: AidKeyStoredModel) => new AidKeyStoredModel(aidKey))
                .sort((aidKeyA, aidKeyB) => aidKeyA.id > aidKeyB.id ? -1 : 1);
        },

        filteredList(state: AidKeyState): void {
            state.filteredAidKeyList = new AidKeyTable(state.aidKeyList, TableFilter.getFilterObject('aidKey'));
        },

        filterOptions(state: AidKeyState, filterOptions: TableFilter): void {
            state.filteredAidKeyList.filterOptions = new TableFilter(filterOptions);
            TableFilter.setFilterObject('aidKey', filterOptions);
        },

        add(state: AidKeyState, aidKey: AidKeyStoredModel): void {
            state.aidKeyList.push(aidKey);
        },

        update(state: AidKeyState, updateAidKey: AidKeyStoredModel): void {
            state.aidKeyList = state.aidKeyList.map(aidKey => aidKey.id === updateAidKey.id ? updateAidKey : aidKey);
            state.filteredAidKeyList.dataList = state.aidKeyList;
        },

        delete(state: AidKeyState, deletedId: number): void {
            state.aidKeyList = state.aidKeyList.filter(aidKey => aidKey.id !== deletedId);
            state.filteredAidKeyList.dataList = state.aidKeyList;
        }
    },

    actions: {
        list(context: Context): Promise<void> {
            return axios.get('/api/aid-key/list')
                .then((response: AxiosResponse) => {
                    context.commit('list', response.data);
                    context.commit('filteredList', TableFilter.getFilterObject('aidKey'));
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}))
                .finally(() => context.state.aidKeyListIsLoading = false);
        },

        getById(context: Context, id: number): Promise<AidKeyStoredModel> {
            return axios.get(`/api/aid-key/${id}`)
                .then((response: AxiosResponse) => new AidKeyStoredModel(response.data))
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        },

        add(context: Context, aidKeyForm: AidKeyFormModel): Promise<void> {
            return axios.post('/api/aid-key/', new AidKeyRequestModel(aidKeyForm))
                .then((response: AxiosResponse) => {
                    context.commit('add', new AidKeyStoredModel(response.data));
                    context.dispatch('alert/success', 'AID key created', {root: true});
                    router.push({ path: `/aid-key/list` });
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        },

        update(context: Context, aidKeyForm: AidKeyFormModel): Promise<void> {
            return axios.put('/api/aid-key/', new AidKeyRequestModel(aidKeyForm))
                .then((response: AxiosResponse) => {
                    context.commit('update', new AidKeyStoredModel(response.data));
                    context.dispatch('alert/success', 'AID key updated', {root: true});
                    router.push({ path: `/aid-key/list` });
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        },

        delete(context: Context, id: number): Promise<void> {
            return axios.delete(`/api/aid-key/${id}`)
                .then(() => {
                    context.commit('delete', id);
                    context.dispatch('alert/success', 'AID key deleted', {root: true});
                    router.push('/aid-key/list')
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        }
    },

    getters: {
        list: (state: AidKeyState): Array<AidKeyStoredModel> => {
            return state.aidKeyList
                .sort((aidKeyA, aidKeyB) => aidKeyA.id > aidKeyB.id ? 1 : -1);
        },

        byId: (state: AidKeyState) => (id: number): AidKeyStoredModel | undefined => {
            return state.aidKeyList.find((aidKey: AidKeyStoredModel) => id === aidKey.id);
        },

        filteredList: (state: AidKeyState): AidKeyTable => {
            return state.filteredAidKeyList;
        },

        aidKeyListIsLoading: (state: AidKeyState): boolean => {
            return state.aidKeyListIsLoading;
        },

        selectOptionList: (state: AidKeyState): Array<SelectOption<number>> => {
            return state.aidKeyList.map((aidKey: AidKeyStoredModel) =>
                new SelectOption<number>(new AidKeyStoredModel(aidKey).getSelectName(), aidKey.id)
            )
        },

        selectedAidKeyList: (state: AidKeyState) => (selectedList: Array<SelectOption<number>>) => {
            return state.aidKeyList
                .filter((aidKey: AidKeyStoredModel) =>
                    selectedList.some((option: SelectOption<number>) => aidKey.id === option.value))
        }
    },
}
