import {BaseStoredModel} from "./BaseStoredModel";
import {SettingTypes} from "@/logic/constants/TmpDictionary";

export class SettingStoredModel extends BaseStoredModel {

    private readonly _id: number;
    private readonly _name: string;
    private readonly _value: string | number | boolean;
    private readonly _type: string;
    private readonly _format: string;
    private readonly _group: string;
    private readonly _supportedValues: Array<string>;

    constructor(setting: SettingStoredModel) {
        super(setting);

        this._id = setting.id;
        this._name = setting.name;
        this._value = setting.type === SettingTypes.BOOLEAN ? setting.value === "true" : setting.value;
        this._type = setting.type;
        this._format = setting.format;
        this._group = setting.group;
        this._supportedValues = setting.supportedValues;
    }

    get id(): number {
        return this._id;
    }

    get name(): string {
        return this._name;
    }

    get value(): string | number | boolean {
        return this._value;
    }

    get type(): string {
        return this._type;
    }

    get format(): string {
        return this._format;
    }

    get group(): string {
        return this._group;
    }

    get supportedValues(): Array<string> {
        return this._supportedValues;
    }
}
