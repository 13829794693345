import {Alert} from "@/store/models/Alert";
import {ActionContext} from "vuex";
import {State} from "@/store";
import {AxiosError} from "axios";
import {ServerException} from "@/store/models/ServerException";

export class AlertState {
    private _alertList: Array<Alert> = [];

    public set alertList(alertList: Array<Alert>) {
        this._alertList = alertList;
    }

    public get alertList(): Array<Alert> {
        return this._alertList;
    }
}

type Context = ActionContext<AlertState, State>;

export default {
    namespaced: true,

    state: new AlertState(),

    mutations: {
        add(state: AlertState, alert: Alert): void {
            state.alertList.push(alert);

            if (state.alertList.length > 5) {
                state.alertList.shift();
            }
        },

        remove(state: AlertState, id: number): void {
            state.alertList = state.alertList.filter((alert) => alert.id !== id);
        }
    },

    actions: {
        success(context: Context, message: string): void {
            context.commit('add', new Alert(Alert.SUCCESS, message));
        },

        error(context: Context, error: AxiosError<ServerException>): void {
            const exception: string = error.response ? error.response.data.message : error.message;
            context.commit('add', new Alert(Alert.ERROR, exception || 'Server error'));
        }
    },

    getters: {
        list: (state: AlertState): Array<Alert> => {
            return state.alertList;
        }
    },
}
