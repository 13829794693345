import {AidFormModel} from "@/store/models/forms/AidFormModel";
import {BaseRequestModel} from "@/store/models/request/BaseRequestModel";

export class AidRequestModel extends BaseRequestModel {

    private rid: string;
    private tav: string;
    private lav: string;
    private selectionFlags: string;
    private priorityIndex: string;

    private id: number | undefined;
    private tc: string | undefined;
    private pix: string | undefined;
    private type: number | undefined;
    private tacDenial: string | undefined;
    private tacOnline: string | undefined;
    private tacDefault: string | undefined;
    private tcForRefund: string | undefined;
    private terminalTransactionQualifiers: string | undefined;
    private trustedPlatformModuleCapabilities: string | undefined;
    private enhancedContactlessReaderCapabilities: string | undefined;
    private terminalTransactionQualifiersForRefund: string | undefined;
    private trustedPlatformModuleCapabilitiesForRefund: string | undefined;
    private enhancedContactlessReaderCapabilitiesForRefund: string | undefined;

    constructor(aid: AidFormModel) {
        super(aid);

        this.id = aid.id;
        this.tc = aid.tc;
        this.rid = aid.rid;
        this.tav = aid.tav;
        this.lav = aid.lav;
        this.pix = aid.pix;
        this.type = aid.type;
        this.tacDenial = aid.tacDenial;
        this.tacOnline = aid.tacOnline;
        this.tacDefault = aid.tacDefault;
        this.tcForRefund = aid.tcForRefund;
        this.priorityIndex = aid.priorityIndex;
        this.selectionFlags = aid.selectionFlags;
        this.terminalTransactionQualifiers = aid.terminalTransactionQualifiers;
        this.terminalTransactionQualifiersForRefund = aid.terminalTransactionQualifiersForRefund;
        this.enhancedContactlessReaderCapabilities = aid.enhancedContactlessReaderCapabilities;
        this.enhancedContactlessReaderCapabilitiesForRefund = aid.enhancedContactlessReaderCapabilitiesForRefund;
        this.trustedPlatformModuleCapabilities = aid.trustedPlatformModuleCapabilities;
        this.trustedPlatformModuleCapabilitiesForRefund = aid.trustedPlatformModuleCapabilitiesForRefund;
    }
}
