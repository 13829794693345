import {ActionContext} from "vuex";
import {State} from "@/store";
import {FinancialInstituteStoredModel} from "@/store/models/stored/FinancialInstituteStoredModel";
import axios from "@/http-common";
import {AxiosError, AxiosResponse} from "axios";
import {FinancialInstituteFormModel} from "@/store/models/forms/FinancialInstituteFormModel";
import {FinancialInstituteRequestModel} from "@/store/models/request/FinancialInstituteRequestModel";
import {TableFilter} from "@/logic/table/TableFilter";
import router from "@/router";
import {SelectOption} from "@/logic/select/SelectOption";
import {FinancialInstituteTable} from "@/logic/table/FinancialInstituteTable";

export class FinancialInstituteState {
    constructor(
        private _financialInstituteList: Array<FinancialInstituteStoredModel> = [],

        private _filteredFinancialInstituteList: FinancialInstituteTable = new FinancialInstituteTable(
            [],
            TableFilter.getFilterObject('financialInstitute')
        ),

        private _financialInstituteListIsLoading: boolean = true
    ) {
    }

    public get financialInstituteList(): Array<FinancialInstituteStoredModel> {
        return this._financialInstituteList;
    }

    public set financialInstituteList(financialInstituteList: Array<FinancialInstituteStoredModel>) {
        this._financialInstituteList = financialInstituteList;
    }

    public get filteredFinancialInstituteList(): FinancialInstituteTable {
        return this._filteredFinancialInstituteList;
    }

    public set filteredFinancialInstituteList(financialInstituteList: FinancialInstituteTable) {
        this._filteredFinancialInstituteList = financialInstituteList;
    }

    public get financialInstituteListIsLoading(): boolean {
        return this._financialInstituteListIsLoading;
    }

    public set financialInstituteListIsLoading(loadingStatus: boolean) {
        this._financialInstituteListIsLoading = loadingStatus;
    }
}

type Context = ActionContext<FinancialInstituteState, State>;

export default {
    namespaced: true,

    state: new FinancialInstituteState(),

    mutations: {
        list(state: FinancialInstituteState, financialInstituteList: Array<FinancialInstituteStoredModel>): void {
            state.financialInstituteList = financialInstituteList
                .map(financialInstitute => new FinancialInstituteStoredModel(financialInstitute))
                .sort((financialInstituteA, financialInstituteB) =>
                    financialInstituteA.id > financialInstituteB.id ? -1 : 1);
        },

        filteredList(state: FinancialInstituteState): void {
            state.filteredFinancialInstituteList = new FinancialInstituteTable(
                state.financialInstituteList,
                TableFilter.getFilterObject('financialInstitute')
            );
        },

        filterOptions(state: FinancialInstituteState, filterOptions: TableFilter): void {
            state.filteredFinancialInstituteList.filterOptions = new TableFilter(filterOptions);
            TableFilter.setFilterObject('financialInstitute', filterOptions);
        },

        add(state: FinancialInstituteState, financialInstitute: FinancialInstituteStoredModel): void {
            state.financialInstituteList.push(new FinancialInstituteStoredModel(financialInstitute));
        },

        update(state: FinancialInstituteState, updateFinancialInstitute: FinancialInstituteStoredModel): void {
            state.financialInstituteList = state.financialInstituteList
                .map(financialInstitute => financialInstitute.id === updateFinancialInstitute.id
                    ? new FinancialInstituteStoredModel(updateFinancialInstitute)
                    : financialInstitute
                );

            state.filteredFinancialInstituteList.dataList = state.financialInstituteList;
        },

        delete(state: FinancialInstituteState, deletedId: number): void {
            state.financialInstituteList = state.financialInstituteList
                .filter(financialInstitute => financialInstitute.id !== deletedId);

            state.filteredFinancialInstituteList.dataList = state.financialInstituteList;
        }
    },

    actions: {
        list(context: Context): Promise<void> {
            return axios.get('/api/financial-institute/list')
                .then((response: AxiosResponse) => {
                    context.commit('list', response.data);
                    context.commit('filteredList', TableFilter.getFilterObject('financialInstitute'));
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}))
                .finally(() => context.state.financialInstituteListIsLoading = false);
        },

        getById(context: Context, id: number): Promise<FinancialInstituteStoredModel> {
            return axios.get(`/api/financial-institute/${id}`)
                .then((response: AxiosResponse) => response.data)
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        },

        add(context: Context, financialInstituteForm: FinancialInstituteFormModel): Promise<void> {
            return axios.post('/api/financial-institute/', new FinancialInstituteRequestModel(financialInstituteForm))
                .then((response: AxiosResponse) => {
                    context.commit('add', response.data);
                    context.dispatch('alert/success', 'Financial institute created', {root: true});
                    router.push({path: `/financial-institute/list`});
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        },

        update(context: Context, financialInstituteForm: FinancialInstituteFormModel): Promise<void> {
            return axios.put('/api/financial-institute/', new FinancialInstituteRequestModel(financialInstituteForm))
                .then((response: AxiosResponse) => {
                    context.commit('update', response.data);
                    context.dispatch('alert/success', 'Financial institute updated', {root: true});
                    router.push({path: `/financial-institute/list`});
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        },

        delete(context: Context, id: number): Promise<void> {
            return axios.delete(`/api/financial-institute/${id}`)
                .then(() => {
                    context.commit('delete', id);
                    context.dispatch('alert/success', 'Financial institute deleted', {root: true});
                    router.push('/financial-institute/list');
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        }
    },

    getters: {
        list: (state: FinancialInstituteState): Array<FinancialInstituteStoredModel> => {
            return state.financialInstituteList
                .sort(
                    (financialInstituteA, financialInstituteB) =>
                        financialInstituteA.id > financialInstituteB.id ? 1 : -1
                );
        },

        byId: (state: FinancialInstituteState) => (id: number): FinancialInstituteStoredModel | undefined => {
            return state.financialInstituteList
                .find((financialInstitute: FinancialInstituteStoredModel) => id === financialInstitute.id);
        },

        filteredList: (state: FinancialInstituteState): FinancialInstituteTable => {
            return state.filteredFinancialInstituteList;
        },

        financialInstituteListIsLoading: (state: FinancialInstituteState): boolean => {
            return state.financialInstituteListIsLoading;
        },

        selectOptionList: (state: FinancialInstituteState) => {
            return state.financialInstituteList
                .map((financialInstitute: FinancialInstituteStoredModel) =>
                    new SelectOption<number>(financialInstitute.name, financialInstitute.id))
        },

        settingSelectOptionList: (state: FinancialInstituteState) => {
            const optionList = state.financialInstituteList
                .map((financialInstitute: FinancialInstituteStoredModel) =>
                    new SelectOption<number>(financialInstitute.name, financialInstitute.id))

            optionList.unshift(new SelectOption<number>('All financial institutes', 0));

            return optionList;
        }
    },
}
