export class Alert {
    public static SUCCESS = 'success';
    public static ERROR = 'danger';

    private _isDeleteAfterTimeout = false;

    constructor(
        private readonly _type: string,
        private readonly _message: string,
        private readonly _displayTime: number = 5,
        private readonly _id: number = Date.now(),
    ) {}

    get type(): string {
        return this._type;
    }

    get message(): string {
        return this._message;
    }

    get displayTime(): number {
        return this._displayTime;
    }

    get id(): number {
        return this._id;
    }

    get isDeleteAfterTimeout(): boolean {
        return this._isDeleteAfterTimeout;
    }

    set isDeleteAfterTimeout(value: boolean) {
        this._isDeleteAfterTimeout = value;
    }
}
