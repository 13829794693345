import {ConfigurationRangeFormModel} from "@/store/models/forms/ConfigurationRangeFormModel";
import {BaseRequestModel} from "@/store/models/request/BaseRequestModel";

export class ConfigurationRangeRequestModel extends BaseRequestModel {
    private end: string;
    private start: string;
    private id: number | undefined;

    constructor(configurationRange: ConfigurationRangeFormModel) {
        super(configurationRange);

        this.id = configurationRange.id;
        this.end = configurationRange.end;
        this.start = configurationRange.start;
    }
}
