import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CImage = _resolveComponent("CImage")!
  const _component_CSidebarBrand = _resolveComponent("CSidebarBrand")!
  const _component_AppSidebarNav = _resolveComponent("AppSidebarNav")!
  const _component_CSidebar = _resolveComponent("CSidebar")!

  return (_openBlock(), _createBlock(_component_CSidebar, {
    position: "fixed",
    unfoldable: _ctx.sidebarUnfoldable,
    visible: _ctx.sidebarVisible
  }, {
    default: _withCtx(() => [
      _createVNode(_component_CSidebarBrand, { style: {"height":"65px"} }, {
        default: _withCtx(() => [
          _createVNode(_component_CImage, {
            src: _ctx.Router.createLink('/sidebar/logo.png'),
            class: "sidebar-brand-full",
            style: {"height":"75%"}
          }, null, 8, ["src"])
        ]),
        _: 1
      }),
      _createVNode(_component_AppSidebarNav)
    ]),
    _: 1
  }, 8, ["unfoldable", "visible"]))
}