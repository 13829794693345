import {Table} from "@/logic/table/Table";
import {HeaderCell} from "@/logic/table/HeaderCell";
import {SearchConditionMultiple} from "filter-data/lib/lib/types";
import {SearchCondition} from "@/logic/table/SearchCondition";
import {SearchType} from "filter-data";
import {TableFilter} from "@/logic/table/TableFilter";
import TransactionStoredModel from "@/store/models/stored/transaction/TransactionStoredModel";

export class TransactionTable extends Table<TransactionStoredModel> {

    get headers(): Array<HeaderCell> {
        return HeaderCell.changeSortTypeInList(
            TableFilter.getFilterObject('transaction'),
            [
                new HeaderCell('Date', 'transactionDate'),
                new HeaderCell('Type', 'operationName'),
                new HeaderCell('Payment method', 'paymentMethod'),
                new HeaderCell('Payment system', 'paymentSystem'),
                new HeaderCell('Payment method', 'cardMask'),
                new HeaderCell('Name system', 'paymentInstrumentName'),
                new HeaderCell('MID', 'merchantExternalId'),
                new HeaderCell('Terminal', 'terminalExternalId'),
                new HeaderCell('Auth code', 'authCode'),
                new HeaderCell('RRN', 'rrn'),
                new HeaderCell('Response code', 'responseCode'),
                new HeaderCell('Amount', 'formattedAmount'),
                new HeaderCell('', 'actions', HeaderCell.UNSORTABLE)
            ]
        );
    }

    filterConfigurationList(): Array<SearchConditionMultiple> {
        return [
            new SearchCondition(
                [
                    'id',
                    'transactionDate',
                    'operationName',
                    'reconciliationStatus',
                    'paymentSystem',
                    'amount',
                    'terminalExternalId',
                    'rrn',
                    'paymentInstrumentName'
                ],
                SearchType.LK,
                this.filterOptions.searchValue
            )
        ];
    }
}
