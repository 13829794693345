import {ActionContext} from "vuex";
import {State} from "@/store";
import UserStoredModel from "@/store/models/stored/UserStoredModel";
import axios from "@/http-common";
import {AxiosError, AxiosResponse} from "axios";
import {UserFormModel} from "@/store/models/forms/UserFormModel";
import {UserRequestModel} from "@/store/models/request/UserRequestModel";
import {TableFilter} from "@/logic/table/TableFilter";
import {UserTable} from "@/logic/table/UserTable";
import router from "@/router";

export class UserState {
    constructor(
        private _userList: Array<UserStoredModel> = [],
        private _filteredUserList: UserTable = new UserTable([], TableFilter.getFilterObject('user')),
        private _userListIsLoading: boolean = true
    ) {
    }

    public get userList(): Array<UserStoredModel> {
        return this._userList;
    }

    public set userList(userList: Array<UserStoredModel>) {
        this._userList = userList;
    }

    public get filteredUserList(): UserTable {
        return this._filteredUserList;
    }

    public set filteredUserList(userList: UserTable) {
        this._filteredUserList = userList;
    }

    public get userListIsLoading(): boolean {
        return this._userListIsLoading;
    }

    public set userListIsLoading(loadingStatus: boolean) {
        this._userListIsLoading = loadingStatus;
    }
}

type Context = ActionContext<UserState, State>;

export default {
    namespaced: true,

    state: new UserState(),

    mutations: {
        list(state: UserState, userList: Array<UserStoredModel>): void {
            state.userList = userList
                .map((user: UserStoredModel) => new UserStoredModel(user))
                .sort((userA, userB) => userA.id > userB.id ? -1 : 1);
        },

        filteredList(state: UserState): void {
            state.filteredUserList = new UserTable(state.userList, TableFilter.getFilterObject('user'));
        },

        filterOptions(state: UserState, filterOptions: TableFilter): void {
            state.filteredUserList.filterOptions = new TableFilter(filterOptions);
            TableFilter.setFilterObject('user', filterOptions);
        },

        add(state: UserState, user: UserStoredModel): void {
            state.userList.push(user);
        },

        update(state: UserState, updateUser: UserStoredModel): void {
            state.userList = state.userList.map(user => user.id === updateUser.id ? updateUser : user);
            state.filteredUserList.dataList = state.userList;
        },

        delete(state: UserState, deletedId: number): void {
            state.userList = state.userList.filter(user => user.id !== deletedId);
            state.filteredUserList.dataList = state.userList;
        }
    },

    actions: {
        list(context: Context): Promise<void> {
            return axios.get('/api/user/list')
                .then((response: AxiosResponse) => {
                    context.commit('list', response.data);
                    context.commit('filteredList', TableFilter.getFilterObject('user'));
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}))
				.finally(() => context.state.userListIsLoading = false);
        },

        getById(context: Context, id: number): Promise<UserStoredModel> {
            return axios.get(`/api/user/${id}`)
                .then((response: AxiosResponse) => new UserStoredModel(response.data))
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        },

        add(context: Context, userForm: UserFormModel): Promise<void> {
            return axios.post('/api/user/', new UserRequestModel(userForm))
                .then((response: AxiosResponse) => {
                    context.commit('add', new UserStoredModel(response.data));
                    context.dispatch('alert/success', 'User created', {root: true});
                    router.push('/user/list');
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        },

        update(context: Context, userForm: UserFormModel): Promise<void> {
            return axios.put('/api/user/', new UserRequestModel(userForm))
                .then((response: AxiosResponse) => {
                    context.commit('update', new UserStoredModel(response.data));
                    context.dispatch('alert/success', 'User updated', {root: true});
                    router.push('/user/list');
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        },

        changeUserStatus(context: Context, userForm: UserFormModel): Promise<void> {
            userForm.status = userForm.status === UserStoredModel.ACTIVE
                ? UserStoredModel.BLOCKED
                : UserStoredModel.ACTIVE;

            return context.dispatch('update', userForm);
        },

        delete(context: Context, id: number): Promise<void> {
            return axios.delete(`/api/user/${id}`)
                .then(() => {
                    context.commit('delete', id);
                    context.dispatch('alert/success', 'User deleted', {root: true});
                    router.push('/user/list');
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        }
    },

    getters: {
        list: (state: UserState): Array<UserStoredModel> => {
            return state.userList
                .sort((userA, userB) => userA.id > userB.id ? 1 : -1);
        },

        byId: (state: UserState) => (id: number): UserStoredModel | undefined => {
            return state.userList.find((user: UserStoredModel) => id === user.id);
        },

        filteredList: (state: UserState): UserTable => {
            return state.filteredUserList;
        },

        userListIsLoading: (state: UserState): boolean => {
            return state.userListIsLoading;
        }
    },
}
