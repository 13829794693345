export class Router {
    public static readonly pageList = [
        'dashboard',
        'user',
        'terminal-type',
        'device',
        'terminal',
        'transaction',
        'bin-range',
        'currency',
        'aid',
        'aid-key',
        'aid-limit',
        'card-range',
        'configuration-range',
        'limit',
        'configuration-limit',
        'configuration',
        'auto-reconciliation-group',
        'merchant',
        'forgot-password',
        'change-password',
        'not-found',
        'login',
        'list',
        'add',
        'show',
        'edit',
        'financial-institute',
        'payment-instrument',
        'operation',
        'settings',
        'general',
        'permission'
    ]

    public static readonly catalogMask = 'subCatalog'

    public static createLink(inputPath: string): string {
        const path = window.location.pathname.split('/').find(route => route) || '';

        if (!inputPath.includes(path) && !Router.pageList.includes(path)) {
            return `/${path}${inputPath}`;
        }

        return inputPath;
    }
}
