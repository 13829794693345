
import AppBreadcrumb from '@/components/AppBreadcrumb.vue';
import UserMenu from '@/components/UserMenu.vue';
import {defineComponent} from "vue";

export default defineComponent({
  name: 'AppHeader',

  components: {
    AppBreadcrumb,
    UserMenu,
  },
})
