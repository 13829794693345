import {Table} from "@/logic/table/Table";
import {ConfigurationLimitStoredModel} from "@/store/models/stored/ConfigurationLimitStoredModel";
import {SearchConditionMultiple} from "filter-data/lib/lib/types";
import {HeaderCell} from "@/logic/table/HeaderCell";
import {SearchCondition} from "@/logic/table/SearchCondition";
import {SearchType} from "filter-data";
import {TableFilter} from "@/logic/table/TableFilter";
import {Utils} from "@/logic/Utils";

export class ConfigurationLimitTable extends Table<ConfigurationLimitStoredModel> {
    filterConfigurationList(): Array<SearchConditionMultiple> {
        return [
            new SearchCondition(
                [
                    'id',
                    'currencyCode',
                    'contactlessLimit',
                    'transaction',
                    'contactlessFloor',
                    'terminalTransactionLimit',
                    'odcvmTransactionLimit',
                    'financialInstitute'
                ],
                SearchType.LK,
                this.filterOptions.searchValue
            )
        ];
    }

    get headers(): Array<HeaderCell> {
        return HeaderCell.changeSortTypeInList(
            TableFilter.getFilterObject('configurationLimit'),
            [
                new HeaderCell('Id', 'id', HeaderCell.DESC),
                new HeaderCell('Currency', 'currencyCode'),
                new HeaderCell('Contactless transaction', 'contactlessLimit', HeaderCell.SORTABLE, true),
                new HeaderCell('CVM required', 'cvmLimit'),
                new HeaderCell('Contactless floor', 'contactlessFloor', HeaderCell.SORTABLE, true),
                new HeaderCell('Terminal transaction', 'terminalTransactionLimit', HeaderCell.SORTABLE, true),
                new HeaderCell('ODCVM transaction', 'odcvmTransactionLimit', HeaderCell.SORTABLE, true, false),
                new HeaderCell(
                    'Financial institute',
                    'financialInstitute',
                    HeaderCell.SORTABLE,
                    false,
                    true,
                    '200px',
                    () => Utils.isSuperAdmin()
                ),
                new HeaderCell('', 'actions', HeaderCell.UNSORTABLE),
            ]
        );
    }

}
