import {BaseStoredModel} from "@/store/models/stored/BaseStoredModel";

export class AidKeyStoredModel extends BaseStoredModel {
    private readonly _id: number;
    private readonly _rid: string;
    private readonly _hash: string;
    private readonly _comment: string;
    private readonly _keyIndex: string;
    private readonly _exponent: string;
    private readonly _publicKey: string;
    private _isDuplicate: boolean;

    constructor(aidKey: AidKeyStoredModel) {
        super(aidKey);

        this._id = aidKey.id;
        this._rid = aidKey.rid;
        this._hash = aidKey.hash;
        this._comment = aidKey.comment;
        this._keyIndex = aidKey.keyIndex;
        this._exponent = aidKey.exponent;
        this._publicKey = aidKey.publicKey;
        this._isDuplicate = false;
    }

    get id(): number {
        return this._id;
    }

    get rid(): string {
        return this._rid;
    }

    get hash(): string {
        return this._hash;
    }

    get comment(): string {
        return this._comment;
    }

    get keyIndex(): string {
        return this._keyIndex;
    }

    get exponent(): string {
        return this._exponent;
    }

    get publicKey(): string {
        return this._publicKey;
    }

    get isDuplicate(): boolean {
        return this._isDuplicate;
    }

    set isDuplicate(value: boolean) {
        this._isDuplicate = value;
    }

    public getName(): string {
        return `(${this._id}) ${this._rid}_${this._keyIndex}`;
    }
}
