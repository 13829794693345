import {TransactionByPeriod} from "@/store/models/statistic/TransactionByPeriod";
import {BasePeriodModel} from "@/store/models/statistic/BasePeriodModel";

export class TransactionStatistic extends BasePeriodModel {
    readonly transactionsByPeriod: Array<TransactionByPeriod>;

    constructor(statistic?: TransactionStatistic) {
        super(statistic?.startPeriod ? statistic.startPeriod : "", statistic?.endPeriod ? statistic.endPeriod : "");
        this.transactionsByPeriod = statistic?.transactionsByPeriod ? statistic.transactionsByPeriod : [];
    }
}
