import {ActionContext} from "vuex";
import {State} from "@/store";
import axios from "@/http-common";
import {AxiosError, AxiosResponse} from "axios";
import {BinRangeStoredModel} from "@/store/models/stored/BinRangeStoredModel";
import {BinRangeRequestModel} from "@/store/models/request/BinRangeRequestModel";
import {BinRangeFormModel} from "@/store/models/forms/BinRangeFormModel";
import {BinRangeTable} from "@/logic/table/BinRangeTable";
import {TableFilter} from "@/logic/table/TableFilter";
import router from "@/router";

export class BinRangeState {
    constructor(
        private _binRangeList: Array<BinRangeStoredModel> = [],
        private _filteredBinRangeList: BinRangeTable = new BinRangeTable([], TableFilter.getFilterObject('binRange')),
        private _binRangeListIsLoading: boolean = true
    ) {}

    get filteredBinRangeList(): BinRangeTable {
        return this._filteredBinRangeList;
    }

    set filteredBinRangeList(binRangeList: BinRangeTable) {
        this._filteredBinRangeList = binRangeList;
    }

    get binRangeListIsLoading(): boolean {
        return this._binRangeListIsLoading;
    }

    set binRangeListIsLoading(loadingStatus: boolean) {
        this._binRangeListIsLoading = loadingStatus;
    }

    public get binRangeList(): Array<BinRangeStoredModel> {
        return this._binRangeList;
    }

    public set binRangeList(binRangeList: Array<BinRangeStoredModel>) {
        this._binRangeList = binRangeList;
    }
}

type Context = ActionContext<BinRangeState, State>;

export default {
    namespaced: true,

    state: new BinRangeState(),

    mutations: {
        list(state: BinRangeState, binRangeList: Array<BinRangeStoredModel>): void {
            state.binRangeList = binRangeList
                .map((binRange: BinRangeStoredModel) => new BinRangeStoredModel(binRange))
                .sort((binRangeA, binRangeB) => binRangeA.id > binRangeB.id ? -1 : 1);
        },

        filteredList(state: BinRangeState): void {
            state.filteredBinRangeList = new BinRangeTable(state.binRangeList, TableFilter.getFilterObject('binRange'));
        },

        filterOptions(state: BinRangeState, filterOptions: TableFilter): void {
            state.filteredBinRangeList.filterOptions = new TableFilter(filterOptions);
            TableFilter.setFilterObject('binRange', filterOptions);
        },

        add(state: BinRangeState, binRange: BinRangeStoredModel): void {
            state.binRangeList.push(binRange);
        },

        update(state: BinRangeState, updateBinRange: BinRangeStoredModel): void {
            state.binRangeList = state.binRangeList
                .map(binRange => binRange.id === updateBinRange.id ? updateBinRange : binRange);

            state.filteredBinRangeList.dataList = state.binRangeList;
        },

        delete(state: BinRangeState, deletedId: number): void {
            state.binRangeList = state.binRangeList.filter(binRange => binRange.id !== deletedId);
            state.filteredBinRangeList.dataList = state.binRangeList;
        }
    },

    actions: {
        list(context: Context): Promise<void> {
            return axios.get('/api/bin-range/list')
                .then((response: AxiosResponse) => {
                    context.commit('list', response.data);
                    context.commit('filteredList', TableFilter.getFilterObject('binRange'));
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}))
                .finally(() => context.state.binRangeListIsLoading = false);
        },

        getById(context: Context, id: number): Promise<BinRangeStoredModel> {
            return axios.get(`/api/bin-range/${id}`)
                .then((response: AxiosResponse) => new BinRangeStoredModel(response.data))
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        },

        add(context: Context, binRangeForm: BinRangeFormModel): Promise<void> {
            return axios.post('/api/bin-range/', new BinRangeRequestModel(binRangeForm))
                .then((response: AxiosResponse) => {
                    context.commit('add', new BinRangeStoredModel(response.data));
                    context.dispatch('alert/success', 'Bin range created', {root: true});
                    router.push({ path: `/bin-range/list` });
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        },

        update(context: Context, binRangeForm: BinRangeFormModel): Promise<void> {
            return axios.put('/api/bin-range/', new BinRangeRequestModel(binRangeForm))
                .then((response: AxiosResponse) => {
                    context.commit('update', new BinRangeStoredModel(response.data));
                    context.dispatch('alert/success', 'Bin range updated', {root: true});
                    router.push({ path: `/bin-range/list` });
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        },

        delete(context: Context, id: number): Promise<void> {
            return axios.delete(`/api/bin-range/${id}`)
                .then(() => {
                    context.commit('delete', id);
                    context.dispatch('alert/success', 'Bin range deleted', {root: true});
                })
                .catch((error: AxiosError) => context.dispatch('alert/error', error, {root: true}));
        },
    },

    getters: {
        list: (state: BinRangeState): Array<BinRangeStoredModel> => {
            return state.binRangeList;
        },

        byId: (state: BinRangeState) => (id: number): BinRangeStoredModel | undefined => {
            return state.binRangeList.find((binRange: BinRangeStoredModel) => id === binRange.id);
        },

        filteredList: (state: BinRangeState): BinRangeTable => {
            return state.filteredBinRangeList;
        },

        binRangeListIsLoading: (state: BinRangeState): boolean => {
            return state.binRangeListIsLoading;
        }
    },
}
