import {AidLimitFormModel} from "@/store/models/forms/AidLimitFormModel";
import {BaseRequestModel} from "@/store/models/request/BaseRequestModel";

export class AidLimitRequestModel extends BaseRequestModel {
    private aidId: number | undefined;
    private cvmLimit: number | undefined;
    private id: number | undefined;
    private contactlessFloor: number | undefined;
    private contactlessLimit: number | undefined;
    private terminalFloorLimit: number | undefined;
    private currencyNumberCode: string;
    private currencyLetterCode: string;
    private currencyId: number | undefined;
    private cardProfileDefault: boolean;
    private odcvmTransactionLimit: number | undefined;

    constructor(aidLimit: AidLimitFormModel) {
        super(aidLimit);

        this.aidId = aidLimit.aidId;
        this.contactlessLimit = aidLimit.contactlessLimit;
        this.cvmLimit = aidLimit.cvmLimit;
        this.contactlessFloor = aidLimit.contactlessFloor;
        this.terminalFloorLimit = aidLimit.terminalFloorLimit;
        this.odcvmTransactionLimit = aidLimit.odcvmTransactionLimit;
        this.currencyNumberCode = aidLimit.currencyNumberCode;
        this.cardProfileDefault = aidLimit.cardProfileDefault;
        this.id = aidLimit.id;
        this.currencyLetterCode = aidLimit.currencyLetterCode;
        this.currencyId = aidLimit.currencyId;
    }
}
