import {Table} from "@/logic/table/Table";
import {BinRangeStoredModel} from "@/store/models/stored/BinRangeStoredModel";
import {SearchConditionMultiple} from "filter-data/lib/lib/types";
import {HeaderCell} from "@/logic/table/HeaderCell";
import {SearchCondition} from "@/logic/table/SearchCondition";
import {SearchType} from "filter-data";
import {TableFilter} from "@/logic/table/TableFilter";
import {Utils} from "@/logic/Utils";

export class BinRangeTable extends Table<BinRangeStoredModel> {
    filterConfigurationList(): Array<SearchConditionMultiple> {
        return [
            new SearchCondition(
                ['id', 'paymentSystem', 'start', 'end', 'financialInstitute'],
                SearchType.LK,
                this.filterOptions.searchValue
            )
        ];
    }

    get headers(): Array<HeaderCell> {
        return HeaderCell.changeSortTypeInList(
            TableFilter.getFilterObject('binRange'),
            [
                new HeaderCell('Id', 'id', HeaderCell.DESC),
                new HeaderCell('Payment system', 'paymentSystem'),
                new HeaderCell('Start', 'start'),
                new HeaderCell('End', 'end'),
                new HeaderCell(
                    'Financial institute',
                    'financialInstitute',
                    HeaderCell.SORTABLE,
                    false,
                    true,
                    '200px',
                    () => Utils.isSuperAdmin()
                ),
                new HeaderCell('', 'actions', HeaderCell.UNSORTABLE)
            ]
        );
    }

}
