import TerminalPermissionFormModel from "@/store/models/forms/TerminalPermissionFormModel";

export default class TerminalPermissionRequestModel {

    private terminalId: number;
    private operationId: number;
    private paymentInstrumentId: number;
    private allowed: boolean;

    constructor(terminalPermissionForm: TerminalPermissionFormModel) {
        this.terminalId = terminalPermissionForm.terminalId;
        this.operationId = terminalPermissionForm.operationId;
        this.paymentInstrumentId = terminalPermissionForm.paymentInstrumentId;
        this.allowed = terminalPermissionForm.allowed;
    }
}
